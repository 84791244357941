
<section class="m-5 p-3">
    <div style="max-width: 600px; margin:auto;">
        <h1>{{'users.title' | translate}}</h1>
        <button mat-button 
                class="orange-button my-3" 
                id="btn-create" 
                (click)="openDialogCreateUser();" 
                [disabled]="!usersUserRights_create"
        >
            <mat-icon>add_circle</mat-icon> {{'add_new' | translate}}
        </button>
    </div>
    <ul class="list-group mt-3" style="max-width: 600px; margin:auto;">
        <li *ngFor="let user of usersData" class="list-group-item">{{user.displayName}}</li>
      </ul>
</section>