
export interface IOperator{
    name:string,
    translation:string,
    value:string
}
export interface IType{
    name:string,
    type:string
}
export class Filter {

    constructor(
        public type: string,
        public filterKey: string,
        public filterOperator: IOperator,
        public filterType:IType,
        public filterDisplayName:string,
        public filterValue?:string,
        public filterDescription?:string
    ) {
    }

    filterByKey(record: any): boolean {
        if (!Object.keys(record).includes(this.filterKey)) return false
        return record[`${this.filterKey}`] == this.filterValue
    }

    filterByOperator(record: any): boolean {
        if (!Object.keys(record).includes(this.filterKey)) return false

        if(this.filterOperator && this.filterOperator.value){
            const operator = this.filterOperator.value
            let value:any = record[`${this.filterKey}`]
            let compareValue:any = this.filterValue

            if(this.type=='date'){
                compareValue = this.isDate(this.filterValue)
                value = this.isDate(value)
                if(operator == '>' || operator == '>=' || operator == '<' || operator == '<='){
                    const a = new Date(record[`${this.filterKey}`]).setHours(0,0,0)
                    value = new Date(a).getTime() 
                    compareValue = new Date(this.filterValue).getTime()
                }
            }
            if(this.type=='time'){
                value = this.isTime(value)
            }

            if(this.type=='dateTime'){
                // compareValue = this.isDate(this.filterValue) + ', '+ this.isTime(this.filterValue)
                // value = this.isDate(value) + ', ' + this.isTime(value)
                // if(operator=='>' || operator == '>=' || operator == '<' || operator == '<='){
                    const a = new Date(value).setSeconds(0)
                    value = new Date(a).getTime() 
                    compareValue = new Date(compareValue).getTime()

                if(operator == '=' || operator == '!='){
                    value = new Date(a).toLocaleString('de-DE')//, { timeZone: 'UTC' }//{ timeZone: 'Europe/Berlin' }
                    compareValue = new Date(compareValue).toLocaleString('de-DE')//, { timeZone: 'UTC' }//{ timeZone: 'Europe/Berlin' }
                }
            }

            switch (operator) {
                case "=":
                    return value == compareValue
                case "!=":
                    return value != compareValue
                case ">":
                    return value > compareValue
                case ">=":
                    return value >= compareValue
                case "<":
                    return value < compareValue
                case "<=":
                    return value <= compareValue
                case "contains":
                    return value.includes(compareValue)
                case "!contains":
                    return !value.includes(compareValue)
                case "in":
                    return compareValue.includes(value)
                case "!in":
                    return !compareValue.includes(value)
                case "startsWith":
                    return value.startsWith(compareValue)
                case "!startsWith":
                    return !value.startsWith(compareValue)
                case "exists":
                    return value != undefined
                case "!exists":
                    return value == undefined
                default:
                    throw new Error(`Unknown operator ${operator}`)
            }
        }else
        return false
    }

    isDateTime(element){
        let dateValue
        dateValue = element
    
        if(element){
          if(element['_nanoseconds'] || element['_seconds']){
            const NS_TO_MS_MULTIPLIER = 1/1000000
            const SEC_TO_MS_MULTIPLIER = 1000
            const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
            const date = new Date(timestampInMilliseconds)
            element= date
            // dateValue = date.toJSON()
            dateValue = date.toUTCString()
          }
    
          if(element['nanoseconds'] || element['seconds']){
            const NS_TO_MS_MULTIPLIER = 1/1000000
            const SEC_TO_MS_MULTIPLIER = 1000
            const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
            const date = new Date(timestampInMilliseconds)
            element= date
            // dateValue = date.toJSON()
            dateValue = date.toUTCString()
          }

          try{
            const newdate = new Date(element)
            if(newdate)
                dateValue = newdate.toUTCString()
                // dateValue = newdate.toJSON()
          }catch(errro){}
        }
       return dateValue
      }
    
      isDate(element){
        let dateValue
        dateValue = element
    
        if(element){
          if(element['_nanoseconds'] || element['_seconds']){
            const NS_TO_MS_MULTIPLIER = 1/1000000
            const SEC_TO_MS_MULTIPLIER = 1000
            const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
            const date = new Date(timestampInMilliseconds)
            element= date
            dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
          }
    
          if(element['nanoseconds'] || element['seconds']){
            const NS_TO_MS_MULTIPLIER = 1/1000000
            const SEC_TO_MS_MULTIPLIER = 1000
            const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
            const date = new Date(timestampInMilliseconds)
            element= date
            dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
          }

          try{
            const newdate = new Date(element)
            if(newdate)
                dateValue = newdate
                dateValue = newdate.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
          }catch(errro){}
        }
       return dateValue
      }
    
      isTime(element){
        let dateValue
        dateValue = element
    
        if(element){
          if(element['_nanoseconds'] || element['_seconds']){
            const NS_TO_MS_MULTIPLIER = 1/1000000
            const SEC_TO_MS_MULTIPLIER = 1000
            const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
            const date = new Date(timestampInMilliseconds)
            element= date
            dateValue = date.toLocaleTimeString('de-DE',{ hour: "2-digit", minute: "2-digit" })
          }
    
          if(element['nanoseconds'] || element['seconds']){
            const NS_TO_MS_MULTIPLIER = 1/1000000
            const SEC_TO_MS_MULTIPLIER = 1000
            const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
            const date = new Date(timestampInMilliseconds)
            element= date
            dateValue = date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
          }
        //   if( typeof element === 'object'){
        //     const date = new Date(element)
        //     dateValue = date.toUTCString()//----------
        //   }
        //   if( typeof element === 'string'){
        //     const date = new Date(element)
        //     dateValue = date.toUTCString()//----------
        //   }

          try{
            const newdate = new Date(element)
            if(newdate)
                dateValue = newdate.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
          }catch(errro){}
        }
       return dateValue
      }
}

export class FilterEqual extends Filter {
    
    constructor(
        type:string,
        filterKey: string,
        filterOperator: IOperator,
        filterType:IType,
        filterDisplayName:string,
        filterValue?:string,
        filterDescription?:string,
       ) {
        super(type, filterKey,filterOperator, filterType, filterDisplayName,filterValue,filterDescription  )
    }

    override filterByKey(record: any): boolean {
        if (!Object.keys(record).includes(this.filterKey)) return false
        return record[`${this.filterKey}`] == this.filterValue
    }

}


/**
 * 
 * @param records 
 *  any Objects 
 * @param filter 
 *  List of filters, individual lists represent 
 *  OR filters list combined represent AND
 * @returns 
 *  records or empty array after applying filters 
 */
export const filterObjects = (tridys: any[], filter: Array<Filter[]>) => {
    const filtered = new Set<any>()

    tridys.forEach((tridy) => {
        let and = true
        filter.forEach((orFilter, index) => {
            let or = false
            orFilter.forEach((f, index) => {
                if (f.filterByKey(tridy)) or = true
            })
            if (!or) {
                and = false
                return 
            }
        })
        if (and) filtered.add(tridy)
    })

    return [...filtered.values()]
}


export const filterObjectsOnFilter = (tridys: any[], filter: Filter[]) => {
    const filtered = new Set<any>()

    tridys.forEach((tridy) => {
        let and = true
        filter.forEach((orFilter, index) => {
            let or = false
                if (orFilter.filterByOperator(tridy)) or = true
            
            if (!or) {
                and = false
                return 
            }
        })
        if (and) filtered.add(tridy)
    })

    return [...filtered.values()]
}

export const isDate = (element) => {
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
      }

      try{
        const newdate = new Date(element)
        if(newdate)
            dateValue = newdate
            dateValue = newdate.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
      }catch(errro){}
    }
   return dateValue
  }

  export const isTime = (element) => {
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleTimeString('de-DE',{ hour: "2-digit", minute: "2-digit" })
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
      }

      try{
        const newdate = new Date(element)
        if(newdate)
            dateValue = newdate.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
      }catch(errro){}
    }
   return dateValue
  }



export const isDateTime = (element) => {
let dateValue
dateValue = element

if(element){
    if(element['_nanoseconds'] || element['_seconds']){
    const NS_TO_MS_MULTIPLIER = 1/1000000
    const SEC_TO_MS_MULTIPLIER = 1000
    const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
    const date = new Date(timestampInMilliseconds)
    element= date
    // dateValue = date.toLocaleString('de-DE')
    dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
    dateValue +=', '+ date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })


    }

    if(element['nanoseconds'] || element['seconds']){
    const NS_TO_MS_MULTIPLIER = 1/1000000
    const SEC_TO_MS_MULTIPLIER = 1000
    const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
    const date = new Date(timestampInMilliseconds)
    element= date
    // dateValue = date.toLocaleString('de-DE')
    dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
    dateValue +=', '+ date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
    }

    try{
    const newdate = new Date(element)
    if(newdate)
        // dateValue = newdate.toLocaleString('de-DE')
        dateValue = newdate.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
        dateValue +=', '+ newdate.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
    }catch(errro){}
}
return dateValue
}

export const isDateTimeGetTimeSeconds = (element) => {
    let dateValue
    dateValue = element
    
    if(element){
        if(element['_nanoseconds'] || element['_seconds']){
            const NS_TO_MS_MULTIPLIER = 1/1000000
            const SEC_TO_MS_MULTIPLIER = 1000
            const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
            const date = new Date(timestampInMilliseconds)
            element= date
            // dateValue = date.toLocaleString('de-DE')
            dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
            dateValue +=', '+ date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
        }
    
        if(element['nanoseconds'] || element['seconds']){
            const NS_TO_MS_MULTIPLIER = 1/1000000
            const SEC_TO_MS_MULTIPLIER = 1000
            const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
            const date = new Date(timestampInMilliseconds)
            element= date
            // dateValue = date.toLocaleString('de-DE')
            dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
            dateValue +=', '+ date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
        }
    
        try{
        const newdate = new Date(element)
        if(newdate)
            dateValue = newdate.getTime()
            // dateValue = newdate.toLocaleString('de-DE')
            // dateValue = newdate.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
            // dateValue +=', '+ newdate.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
        }catch(errro){}
    }
    return dateValue
    }