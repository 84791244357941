<h2 mat-dialog-title>{{'workTask.advanced_settings' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" style="max-width:600px;" id="section" >

            <form [formGroup]="workTaskTemplateForm">
                
                <div>
                    <label for="titleKeys">{{'workTask.title_keys' | translate}}</label>  

                    <div class="list-group mt-1 mb-2">
                        <div class="list-group-item" *ngFor="let s of workTaskTemplateForm.value.titleKeys; let index= index;"  >
                            <div class="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                                <span class="w-75 text-break text-wrap" id="{{s}}" (click)="updateTitleKey(s, index);" style="cursor:pointer;"> {{s}}</span>
                                <!-- <input matInput id="{{s}}" type="text" class="form-control rounded" value="{{s}}" (click)="updateTitleKey(s, index);" readonly style="cursor:pointer;"> -->
                                <div (click)="deleteTitleKey(s, index);" >
                                    <button mat-icon-button [disabled]="!tasktemplateUserRights_update">
                                        <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                            delete
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <button type="button" mat-button style="width:100%; color: var(--warmOrange);box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);" (click)="addTitleKey();" [disabled]="!tasktemplateUserRights_update">{{'workTask.tk_add' | translate}}</button>
        
                    <p class="text-muted pt-3" style="font-size: 14px;">{{'workTask.tk_description' | translate}}</p>
                </div>
        
                <div >
                    <label for="filter">{{'workTask.filter' | translate}}</label>   

                    <div class="list-group mt-1 mb-2">
                        <div class="list-group-item" *ngFor="let s of workTaskTemplateForm.value.predicates; let index= index;">
                            <div class="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                                <!-- <input matInput id="{{s}}" type="text" class="form-control rounded" value="{{s}}" (click)="updatePredicate(s, index);" readonly style="cursor:pointer;"> -->
                                <span id="{{s}}" class="w-75 text-wrap text-break" (click)="updatePredicate(s, index);" style="cursor:pointer;">{{s}}</span> 
                                <div (click)="deletePredicate(s, index);">
                                    <button mat-icon-button [disabled]="!tasktemplateUserRights_update">
                                        <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                            delete
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <button type="button" [disabled]="!tasktemplateUserRights_update" mat-button style="width:100%; color: var(--warmOrange);box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);" (click)="addPredicate();">{{'workTask.f_add' | translate}}</button>
                    <p class="text-muted pt-3" style="font-size: 14px;">{{'workTask.f_description' | translate}}</p>
                </div> 
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onDone();" [disabled]="!tasktemplateUserRights_update">{{'apply' | translate}}</button>
</mat-dialog-actions>
