<section class="container">
    <div class="row mt-4">

        <div class="mt-3">
            <h1 class="mb-1">{{'topNavbar.reports' | translate}}</h1>
            <p style="font-family:'MyFont-Light';">{{'headlines.subReports' | translate}}</p>
        </div>

        <div class="col-12">
            <section>    
                <div [matTooltip]="!reportsUserRights_create ? ('home.tooltip' | translate) : null"  class="mt-1 filter-section d-flex flex-row flex-wrap  align-items-center">
                    <div class="d-flex flex-row flex-wrap">
                        <button mat-button class="orange-button" id="btn-create" (click)="addReport();" [disabled]="!reportsUserRights_create" >
                            <mat-icon>add_circle</mat-icon> {{'add_new' | translate}}
                        </button>
                        <div class="ps-3">
                           <button mat-button class="orange-button" id="btn-import" (click)="fileInput.click()">
                                <mat-icon>publish</mat-icon> {{'pass.import' | translate}}
                            </button>
                            <input type="file" id="myfile-pick-title" name="myfile-pick-title" style="display: none;"  accept=".zip, .nvpt"  (change)="readURL($event);" #fileInput>
                        </div>
                    </div>

                    <mat-form-field class="pt-3 mb-0 mt-0 white-input-bg">
                        <mat-label>{{'search_title' | translate}}</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
                        <mat-icon matPrefix>search</mat-icon>
                    </mat-form-field>

                </div>
            </section>
            
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-12">
            <div *ngIf="reportsData.length === 0" class="p-2 empty-list" >{{'tridys.empty_title' | translate}}</div>
            <div class="shadow-sm mat-elevation-z8 pb-4" id="table-container">
                <table mat-table [dataSource]="dataSource" matSort>
            
                <ng-container matColumnDef="displayName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'displayName' | translate}} </th>
                    <td mat-cell *matCellDef="let element" (click)="selectReport(element);"> 
                        <span *ngIf="element.displayName" class="text-wrap text-break">{{element.displayName}}</span> </td>
                </ng-container>
            
                <ng-container matColumnDef="creationDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'creation_date' | translate}} </th>
                    <td mat-cell *matCellDef="let element" (click)="selectReport(element);"> 
                        <span *ngIf="element.creationDate" class="text-wrap text-break">{{element.creationDate.toDate() | date: 'dd.MM.yyyy, HH:mm'}}</span> 
                    </td>
                </ng-container>
            
                <ng-container matColumnDef="timeStamp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'last_update' | translate}} </th>
                    <td mat-cell *matCellDef="let element" (click)="selectReport(element);"> 
                        <span *ngIf="element.timeStamp" class="text-wrap text-break">{{element.timeStamp.toDate() | date: 'dd.MM.yyyy, HH:mm'}} </span>        
                    </td>
                </ng-container>

                <ng-container matColumnDef="export">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element"> 
            
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="editReport(element);" ><!--[disabled]="!reportsUserRights_create"-->
                                <mat-icon class="material-symbols-outlined">edit</mat-icon> {{'edit' | translate}}
                            </button>

                            <button mat-menu-item (click)="exportThisReport(element);">
                                <mat-icon class="material-symbols-outlined"> download</mat-icon>
                                {{'reports.export_file' | translate}}
                            </button> 

                            <button mat-menu-item [matMenuTriggerFor]="exportReport">
                                <mat-icon class="material-symbols-outlined">
                                    download
                                </mat-icon>
                                {{'reports.export_report' | translate}}
                            </button>

                            <mat-menu #exportReport="matMenu">
                                <button mat-menu-item (click)="exportThisReportTo(element, 'csv');">{{'reports.csv' | translate}} </button>
                                <button mat-menu-item (click)="exportThisReportTo(element, 'xls');">{{'reports.xls' | translate}} </button>
                            </mat-menu>

                            <button mat-menu-item (click)="openDuplicateReportDialog(element)" [disabled]="!reportsUserRights_create"><!--[disabled]="!reportsUserRights_create"-->
                                <mat-icon>content_copy</mat-icon> {{'duplicate' | translate}}
                            </button>
                            <button mat-menu-item (click)="deleteThisReport(element)" [disabled]="!reportsUserRights_create">
                                <mat-icon class="material-symbols-outlined delete">delete</mat-icon> {{'delete' | translate}}
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break border-bottom" ></tr>
                <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break border-bottom" (click)="selectReport(row);"></tr> -->
                <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break border-bottom" routerLink="{{row.id}}" (click)="selectReport(row.id);"></tr> -->
                </table>

                <!-- <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell p-2" colspan="4">{{'no_matching_data' | translate}} "{{input.value}}"</td> -->
            
                <mat-paginator [pageSizeOptions]="paginatorSize"
                            showFirstLastButtons
                            aria-label="Select page of reports" class="pb-2">
                </mat-paginator>
            </div>
        </div>
    </div>
</section>



  

