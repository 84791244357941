<h2 *ngIf="type=='add_field' || type=='edit_field'" mat-dialog-title>{{'reports.add_column' | translate }}</h2>
<h2 *ngIf="type=='add_filter' || type=='edit_filter'" mat-dialog-title>{{'reports.add_filter' | translate }}</h2>
<h2 *ngIf="type=='add_fixedFilter' || type=='edit_fixedFilter'" mat-dialog-title>{{'add' | translate }}</h2>

<mat-dialog-content style="max-width: 400px;">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >

            <form [formGroup]="addForm">
                <!-- <input type="text" class="form-control" formControlName="value"> -->
                <div *ngIf="type=='add_filter' || type=='edit_filter'">

                    <mat-form-field appearance="outline" class="w-100 ">
                        <!-- <mat-label>{{'name' | translate}}*</mat-label>
                        <input matInput type="text" class="form-control" placeholder="{{'name' | translate}}" formControlName="name"> -->
                        <mat-label>{{'displayName' | translate}}</mat-label>
                        <input matInput type="text" class="form-control" placeholder="{{'displayName' | translate}}" formControlName="displayName" required>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'reports.type' | translate}}</mat-label>
                        <mat-select formControlName="type">
                            <mat-option *ngFor="let type of typeOptions; let index=index" value="{{type.type}}" >{{type.translation | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 ">
                        <mat-label>{{'reports.key' | translate}}</mat-label>
                        <input matInput type="text" class="form-control" placeholder=" {{'reports.key' | translate}}" formControlName="key" required>
                    </mat-form-field>
            
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'reports.operator' | translate}}</mat-label>
                        <mat-select formControlName="operator" required>
                            <mat-option *ngFor="let operator of operatorOptions; let index=index" value="{{operator.value}}" >{{operator.translation | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="outline" class="w-100 ">
                        <mat-label>{{'contentTypes.description' | translate}}</mat-label>
                        <input matInput type="text" class="form-control" placeholder="{{'contentTypes.description' | translate}}" formControlName="description" required>
                    </mat-form-field>

                </div>
        
                <div *ngIf="type=='add_field' || type=='edit_field'">

                    <mat-form-field appearance="outline" class="w-100 ">
                        <mat-label>{{'displayName' | translate}}</mat-label>
                        <input matInput type="text" class="form-control" placeholder=" {{'displayName' | translate}}" formControlName="displayName" required>
                    </mat-form-field>
        
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'reports.type' | translate}}</mat-label>
                        <mat-select formControlName="type" required>
                            <mat-option *ngFor="let type of typeOptions; let index=index" value="{{type.type}}" >{{type.translation | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="outline" class="w-100 ">
                        <mat-label>{{'reports.key' | translate}}</mat-label>
                        <input matInput type="text" class="form-control" placeholder=" {{'reports.key' | translate}}" formControlName="key" required>
                    </mat-form-field>
            
                </div>
                
                <div *ngIf="type=='add_fixedFilter' || type=='edit_fixedFilter'">
                    <mat-form-field appearance="outline" class="w-100 ">
                        <mat-label>{{'displayName' | translate}}</mat-label>
                        <input matInput type="text" class="form-control" placeholder="{{'displayName' | translate}}" formControlName="displayName" required>
                    </mat-form-field>
        
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'reports.type' | translate}}</mat-label>
                        <mat-select formControlName="type" (selectionChange)="onTypeChange($event.value)" required>
                            <mat-option *ngFor="let type of typeOptions; let index=index" [value]="type.type" >{{type.translation | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100 ">
                        <mat-label>{{'reports.key' | translate}}</mat-label>
                        <input matInput type="text" class="form-control" formControlName="key" required>
                    </mat-form-field>
            
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'reports.operator' | translate}}</mat-label>
                        <mat-select formControlName="operator" required>
                            <mat-option *ngFor="let operator of operatorOptionsFixedValue; let index=index" value="{{operator.value}}" >{{operator.translation | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <!-- <mat-form-field appearance="outline" class="w-100 ">
                        <mat-label>{{'description' | translate}}*</mat-label>
                        <input matInput type="text" class="form-control" placeholder=" {{'description' | translate}}" formControlName="description">
                    </mat-form-field> -->
        
                    <mat-form-field appearance="outline" class="w-100" *ngIf="addForm.value.type!='date' ">
                        <mat-label>{{'reports.value' | translate}}</mat-label>
                        <input matInput *ngIf="addForm.value.type=='string' " type="text" formControlName="value">
                        <input matInput *ngIf="addForm.value.type=='media' " type="text" formControlName="value">
                        <input matInput *ngIf="addForm.value.type=='number' " type="number" formControlName="value">
                        <input matInput *ngIf="addForm.value.type=='decimal' " type="number" formControlName="value" step="0.1">
                        <input matInput *ngIf="addForm.value.type=='time' " type="time" formControlName="value">
                        <input matInput *ngIf="!addForm.value.type " type="text" formControlName="value">
                        <input matInput *ngIf="addForm.value.type=='dateTime' " type="datetime-local" formControlName="value" >
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="addForm.value.type=='date' " class="w-100"> 
                        <mat-label> {{'reports.value' | translate}}</mat-label>
                        <input matInput #input [matDatepicker]="picker" formControlName="value" placeholder="{{'dd.mm.yyyy' | translate}}">
                        <mat-hint>{{'dd.mm.yyyy' | translate}}</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                    </mat-form-field>
                </div>
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button"
    (click)="onSave();">{{'save' | translate}}</button>
</mat-dialog-actions>
