import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { deepCopy, makeid, openMapDirection, removeUndefined, removeUndefinedValuesFromObject, supportUrlStructure } from '../../../../../backend/utils/object';
import { TranslateService } from '@ngx-translate/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { validColorValidator } from 'ngx-colors';
import { LanguageEditorDialogComponent } from 'src/app/language-editor-dialog/language-editor-dialog.component';
import { UploadService } from 'src/app/service/upload.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: 'app-annotated-map-annotation-dialog',
  templateUrl: './annotated-map-annotation-dialog.component.html',
  styleUrls: ['./annotated-map-annotation-dialog.component.css']
})
export class AnnotatedMapAnnotationDialogComponent {

  annotationData:any
  newAnnotationForm!:FormGroup
  allAnnotationsMap:any = []
  colorPalette:any  =[]
  center: google.maps.LatLngLiteral = {lat: 44, lng: 12};
  zoom = 4;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  actualPolygonOptions:any 

  invalid_pinBackgroundColor = false 
  invalid_pinForegroundColor = false 
  invalid_lineColor = false
  invalid_fillColor = false
  invalid_strokeColor = false
  linkURL:any
  reg = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegexDocContext = /^context?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  translationForm!:FormGroup
  passTranslations:any
  passLanguagesList:any
  annotatedMapEditData:any
  annotationColumns:any
  oldTranslations:any
  linkUrlTransform:any
  newDocument:any
  passId:any
  widgetNumber:any
  annotationId:any
  prev_infowindow:any

  pairsPath ={}
  polylineOptions = {
    path: [],
    strokeColor: '#32a1d0',
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };

  polygonOptions = {
    paths: [],
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: true
  }
  //map-annotation-dialog
  map:google.maps.Map
  BADGE:any

  constructor(public dialogRef: MatDialogRef<AnnotatedMapAnnotationDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any[],
              private fb: FormBuilder,
              private commentService: CommentsService,
              private translate:TranslateService,
              private uploadService: UploadService,
              private settingsService: SettingsService){
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if(this.data['encodedContent'])
      this.actualPolygonOptions = this.data['encodedContent']
    
    this.passId = this.data['passId']
    this.widgetNumber= this.data['widgetNumber']

    if(this.data['columns'])
      this.annotationColumns = this.data['columns']

    if(this.data['index'])
      this.BADGE = this.data['index']

    if(this.data['annotationMapData'])
      this.annotatedMapEditData = this.data['annotationMapData']

    if(this.data['languages'])
    this.passLanguagesList = this.data['languages']

    if(this.data['translations']){
      this.passTranslations = this.data['translations']
      this.oldTranslations = deepCopy(this.data['translations'])
    }else{
      this.passTranslations = {}
      this.oldTranslations = {}
    }

    this.newAnnotationForm = this.fb.group({
      name:[],
      lat:[, Validators.required],
      lng:[, Validators.required],
      directions:[],
      badge:[],
      coordinate:[],
      id:[],
      subtitle:[],
      title:[],
      linkURL:[,Validators.pattern(this.reg)],

      encodedContent:  [],
        fillColor : [validColorValidator()],
        lineCap: [],
        lineWidth: [],
        strokeColor:  [validColorValidator()],
        type: [] ,

        pinColor:[, validColorValidator()],
        lineColor:[, validColorValidator()],
        pinBackgroundColor: [, validColorValidator()],
        pinForegroundColor: [, validColorValidator()]
    })

    this.newAnnotationForm.get('linkURL').valueChanges.subscribe(value => {
      // this.supportLinkUrl(value)
      this.supportUrlStructureCMPT(value)
      let url;
  
      try {
        url = new URL(value)
        if(url.protocol === "http:" || url.protocol === "https:"){
          this.newAnnotationForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.reg)]);
        }
        if(url.protocol === "pass:" ){
          this.newAnnotationForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegexDoc)]);
        }
        if(url.protocol === "context:" ){
          this.newAnnotationForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegexDocContext)]);
        }
      } catch (error) {}
    })

    if(this.data.values){
      this.annotationData=this.data.values
      if(this.annotationData.id)
        this.annotationId = this.annotationData.id

      const {name, lat, lng, badge, coordinate, subtitle, title ,linkURL, encodedContent,directions } = this.annotationData
      let {strokeColor,fillColor, lineCap, lineWidth,id, type, pinColor, lineColor , pinForegroundColor, pinBackgroundColor } = this.annotationData

      if(!type)
        type='point'

      if(this.actualPolygonOptions){
        if(!strokeColor)
        strokeColor = this.actualPolygonOptions.strokeColor

        if(!fillColor)
        fillColor = this.actualPolygonOptions.fillColor

        if(!lineWidth)
        lineWidth = this.actualPolygonOptions.lineWidth
      }
      if(!id){
        id = makeid(5)
        this.annotationId = id
      }

      if(!pinBackgroundColor && pinColor)
      pinBackgroundColor = pinColor 

      this.supportUrlStructureCMPT(linkURL)
      this.loadMarkers()
      setTimeout(() => {
        this.loadMap(id)
      }, 500); 
    this.newAnnotationForm.patchValue({
        name:name ? name : undefined,
        lat:lat ? lat : undefined,
        lng:lng ? lng : undefined,
  
        badge:badge ? badge : undefined,
        coordinate:coordinate ? coordinate : undefined,
        id:id ? id : undefined,
        subtitle:subtitle ? subtitle : undefined,
        title:title ? title :  undefined,
        linkURL: linkURL ? linkURL : undefined,

        directions: directions ? directions : undefined,
        encodedContent: encodedContent ? encodedContent : undefined,
        fillColor: fillColor ? fillColor : undefined,
        lineCap: lineCap ? lineCap : undefined,
        lineWidth: lineWidth ? lineWidth : undefined,
        strokeColor: strokeColor ? strokeColor : undefined,
        type: type ? type : undefined,

        pinColor:  undefined,
        lineColor: lineColor ? lineColor : undefined,

        pinBackgroundColor: pinBackgroundColor ? pinBackgroundColor : undefined,
        pinForegroundColor: pinForegroundColor ? pinForegroundColor : undefined,
      })

      if(type=='point'){
       this.newAnnotationForm.patchValue({
        pinBackgroundColor: pinBackgroundColor ? pinBackgroundColor : '#FF5E38',
        pinForegroundColor: pinForegroundColor ? pinForegroundColor : '#000000',
       })
      }
      
    }else{
      let badge = undefined
      let id = undefined
      if(this.data['index'])
        badge = this.data['index']

        if(this.data['id']){
          id = this.data['id']
          this.annotationId = id
        }
      
      this.newAnnotationForm.patchValue({
        name: undefined,
        lat: undefined,
        lng: undefined,
  
        badge: badge,
        coordinate: [],
        id: id ? id : undefined,
        subtitle: undefined,
        title: undefined,
        linkURL: undefined,
        directions: undefined,
        encodedContent:  undefined,
        fillColor : undefined,
        lineCap: undefined,
        lineWidth: undefined,
        strokeColor:  undefined,
        type: "point",

        // pinColor: "#ff0000",
        pinBackgroundColor: '#FF5E38',
        pinForegroundColor: '#000000',
        lineColor: undefined
      })
    }

    this.newAnnotationForm.valueChanges.subscribe( (value:any) => {
    if(value.lat && value.lng && value.type=='point'){
      this.loadMarkersAfterChanges()
      this.loadMap(value.id)
      this.checkValidColors(value.type)
    }else{
      this.loadMarkersAfterChanges()
      this.loadMap(value.id)
      this.checkValidColors(value.type)
    }
    })


    this.initializePaletteColors()

    this.translationForm = this.fb.group({translations:[]})
    this.translationForm.patchValue({
      translations: this.passTranslations ? this.passTranslations : undefined
    })
  }

  onLanguageEditor(){
    let alltranslationsWidget = {}
    let translationsWidget =[]

    if(this.translationForm.value.translations){
      translationsWidget = Object.keys(this.translationForm.value.translations) 
    }

    if(this.passLanguagesList){
      translationsWidget=this.passLanguagesList
    }

    // if(this.translationForm.value.translations){
      // translationsWidget = Object.keys(this.translationForm.value.translations) 
    if(translationsWidget.length!=0){
      translationsWidget.forEach(lang => {
        if(this.translationForm.value.translations){
          if(this.translationForm.value.translations[lang]){
            let entries = Object.entries(this.translationForm.value.translations[lang]);
            entries.forEach( ent => {
              if(ent[0].includes(this.annotatedMapEditData.id+'_'+this.annotationId) ){
                const key = lang+ "." + ent[0]
                alltranslationsWidget[key] = ent[1]
              }
            })
          }
        }
      })
    }
    // else{
    //   translationsWidget = []
    // }

    let columns = ['title', 'subtitle']
   
    let dialogRef = this.dialog.open(LanguageEditorDialogComponent, {
      data:{
        translations: this.translationForm.value.translations,
        fields: this.annotationData,
        columns: this.annotationColumns,
        values: this.newAnnotationForm.value,
        type: "annotations",//annotatedMap.
        valuesTranslated: alltranslationsWidget,
        languages: this.passLanguagesList,
        annotationMapData: this.annotatedMapEditData
      },
    });
    let newtranslations 

    if(this.translationForm.value.translations){
      newtranslations = this.translationForm.value.translations
    }else{
      newtranslations = {}
    }

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        const data = result[0]
        removeUndefined(data)
        const trans = Object.entries(data)
        trans.forEach( value => {
          const key = value[0]
          const keyName = key.split(".")
          const lang = keyName[0]
          const id = keyName[1]
          const field = keyName[2] 
          const tranlationField = id +"." + field 

          if(value[1]){
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = value[1]
            }else{
              newtranslations[lang]= {}
              newtranslations[lang][tranlationField] = value[1]
            }
          }else{
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = undefined

              //to delete old value
              if(this.oldTranslations[lang] && this.oldTranslations[lang][tranlationField])
                newtranslations[lang][tranlationField] = ''

            }else{
              newtranslations[lang]= {}
              newtranslations[lang][tranlationField] = undefined
            }
          }
        })
        this.translationForm.patchValue({translations: newtranslations})
      }
    })
  }

  readDocument(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB size limit
  
      // Define allowed file types
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/webp',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'text/csv',
        'text/vcard',
        'application/rtf'
      ];
  
      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        const message = this.translate.instant("SnackBarConstants.INVALID_FILE_FORMAT");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      // Validate file size
      if (file.size > maxSizeInBytes) {
        const message = this.translate.instant("SnackBarConstants.FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      // Proceed with reading and uploading the file
      const reader = new FileReader();
      this.newDocument = file;
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        if (e.target) {
          const urlNew = e.target.result as string;
  
          try {
            const uploadURL = await this.uploadService.uploadDocument(this.passId, this.newDocument, this.widgetNumber);
  
            if (uploadURL) {
              this.newAnnotationForm.get("linkURL")?.setValidators([Validators.required, Validators.pattern(this.urlRegexDoc)]);
              this.linkUrlTransform = uploadURL;
              this.newAnnotationForm.patchValue({ linkURL: this.linkUrlTransform });
              // this.supportLinkUrl(uploadURL);
              this.supportUrlStructureCMPT(uploadURL)
            }
          }catch(error){
            console.log(e)
          }
        }
      };
    }
  } 
  

  initializePaletteColors(){
    var colors=[]
    const warmOrange= getComputedStyle(document.documentElement).getPropertyValue('--warmOrange')
    const darkblue= getComputedStyle(document.documentElement).getPropertyValue('--darkBlue')
    const pureWhite= getComputedStyle(document.documentElement).getPropertyValue('--pureWhite')
    const salbei= getComputedStyle(document.documentElement).getPropertyValue('--salbei')
    colors.push(warmOrange)
    colors.push(darkblue)
    colors.push(pureWhite)
    colors.push(salbei)
    this.colorPalette=colors
  }

  onSaveEdit(){
    if(this.newAnnotationForm.value.type){
      if(this.newAnnotationForm.value.type=='point'){
        if(this.newAnnotationForm.value.lat && this.newAnnotationForm.value.lng){
          const coordinate = []
          const lat: number = +this.newAnnotationForm.value.lat
          const lng: number = +this.newAnnotationForm.value.lng
          coordinate.push(lat)
          coordinate.push(lng)
          this.newAnnotationForm.patchValue({coordinate: coordinate, lat:lat, lng:lng})
          const data = removeUndefinedValuesFromObject(this.newAnnotationForm.value)
    
          try{
            this.commentService.progressSpin.emit(true)
            this.dialogRef.close([true, data]);
            this.commentService.progressSpin.emit(false)
          }catch(error){
            console.log(error)
          }
        }else{
          const message = this.translate.instant("SnackBarConstants.MAP_LAT_LNG_EMPTY")
          this.commentService.addSnackBar.emit(message)
          this.commentService.progressSpin.emit(false)
        }
      }

      if(this.newAnnotationForm.value.type=='polygon'){
          const data = removeUndefinedValuesFromObject(this.newAnnotationForm.value)
          try{
            this.commentService.progressSpin.emit(true)
            this.dialogRef.close([true, data]);
            this.commentService.progressSpin.emit(false)
          }catch(error){
            console.log(error)
          }
      }

      if(this.newAnnotationForm.value.type=='line'){
          const data = removeUndefinedValuesFromObject(this.newAnnotationForm.value)
          try{
            this.commentService.progressSpin.emit(true)
            this.dialogRef.close([true, data]);
            this.commentService.progressSpin.emit(false)
          }catch(error){
            console.log(error)
          }
      }


    }else{
      if(this.newAnnotationForm.value.lat && this.newAnnotationForm.value.lng){
        const coordinate = []
        const lat: number = +this.newAnnotationForm.value.lat
        const lng: number = +this.newAnnotationForm.value.lng
        coordinate.push(lat)
        coordinate.push(lng)
        this.newAnnotationForm.patchValue({coordinate: coordinate, lat:lat, lng:lng})
        const data = removeUndefinedValuesFromObject(this.newAnnotationForm.value)
  
        try{
          this.commentService.progressSpin.emit(true)
          this.dialogRef.close([true, data]);
          this.commentService.progressSpin.emit(false)
        }catch(error){
          console.log(error)
        }
      }else{
        const message = this.translate.instant("SnackBarConstants.MAP_LAT_LNG_EMPTY")
        this.commentService.addSnackBar.emit(message)
        this.commentService.progressSpin.emit(false)
      }
    }
  }

  loadMarkers(){
    if(this.newAnnotationForm.value.type){
        if(this.newAnnotationForm.value.type=="point"){
          if(this.newAnnotationForm.value.coordinate){
            const lats = this.newAnnotationForm.value
            lats['lat'] = parseFloat(this.newAnnotationForm.value.coordinate[0])
            lats['lng'] = parseFloat(this.newAnnotationForm.value.coordinate[1])

            if(this.newAnnotationForm.value.name){
              lats['title']=this.newAnnotationForm.value.name
            }
            this.markerPositions.push(lats);
            this.center=lats
          }else{
            const lats = this.newAnnotationForm.value
            lats['lat'] = parseFloat(this.newAnnotationForm.value.lat)
            lats['lng'] = parseFloat(this.newAnnotationForm.value.lng)
              
            if(this.newAnnotationForm.value.name){
              lats['title']=this.newAnnotationForm.value.name
            }
            this.markerPositions.push(lats);
            this.center=lats
          }
        }
        
        if(this.newAnnotationForm.value.type=="polygon"){
          const newcenter =  Math.round( this.actualPolygonOptions.paths.length / 2)
          const center: google.maps.LatLngLiteral = {lat: this.actualPolygonOptions.paths[newcenter].lat , lng: this.actualPolygonOptions.paths[newcenter].lng}
          this.center = center
          this.zoom = 10
          this.markerPositions.push(this.newAnnotationForm.value)
        }


        if(this.newAnnotationForm.value.type=="line"){
          const newcenter =  Math.round( this.actualPolygonOptions.path.length / 2)
          const center: google.maps.LatLngLiteral = {lat: this.actualPolygonOptions.path[newcenter].lat , lng: this.actualPolygonOptions.path[newcenter].lng}
          this.center = center
          this.zoom = 10
          this.markerPositions.push(this.newAnnotationForm.value)
        }
    }else{
      if(this.newAnnotationForm.value.coordinate){
        const lats = this.newAnnotationForm.value
        lats['lat'] = parseFloat(this.newAnnotationForm.value.coordinate[0])
        lats['lng'] = parseFloat(this.newAnnotationForm.value.coordinate[1])

        if(this.newAnnotationForm.value.name){
          lats['title']=this.newAnnotationForm.value.name
        }
        this.markerPositions.push(lats);
        this.center=lats
      }else{
        const lats = this.newAnnotationForm.value
        lats['lat'] = parseFloat(this.newAnnotationForm.value.lat)
        lats['lng'] = parseFloat(this.newAnnotationForm.value.lng)
          
        if(this.newAnnotationForm.value.name){
          lats['title']=this.newAnnotationForm.value.name
        }
        this.markerPositions.push(lats);
        this.center=lats
      }
    }
  }

  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow, markerPositions) {
    if(this.prev_infowindow)
    this.prev_infowindow.close()

    this.prev_infowindow = infoWindow
    infoWindow.open(marker);
  }

  loadMarkersAfterChanges(){
    this.markerPositions=[]
    if(this.newAnnotationForm.value.type){
        if(this.newAnnotationForm.value.type=="point"){
          if(this.newAnnotationForm.value.lat && this.newAnnotationForm.value.lng){
            const lats = this.newAnnotationForm.value
            lats['lat'] = parseFloat(this.newAnnotationForm.value.lat)
            lats['lng'] = parseFloat(this.newAnnotationForm.value.lng)
  
            if(this.newAnnotationForm.value.name){
              lats['title']=this.newAnnotationForm.value.name
            }
            this.markerPositions.push(lats);
            this.center=lats
          }
        }

        if(this.newAnnotationForm.value.type=="line"){
          const newcenter =  Math.round( this.actualPolygonOptions.path.length / 2)
          const center: google.maps.LatLngLiteral = {lat: this.actualPolygonOptions.path[newcenter].lat , lng: this.actualPolygonOptions.path[newcenter].lng}
          this.center = center
          this.zoom = 10
          this.markerPositions.push(this.newAnnotationForm.value)
          this.actualPolygonOptions.strokeColor = this.newAnnotationForm.value.strokeColor
          this.actualPolygonOptions.strokeWeight = this.newAnnotationForm.value.lineWidth
        }


        if(this.newAnnotationForm.value.type=="polygon"){
          const newcenter =  Math.round( this.actualPolygonOptions.paths.length / 2)
          const center: google.maps.LatLngLiteral = {lat: this.actualPolygonOptions.paths[newcenter].lat , lng: this.actualPolygonOptions.paths[newcenter].lng}
          this.center = center
          this.zoom = 10
          this.markerPositions.push(this.newAnnotationForm.value)
          this.actualPolygonOptions.fillColor = this.newAnnotationForm.value.fillColor
          this.actualPolygonOptions.strokeColor = this.newAnnotationForm.value.strokeColor
          this.actualPolygonOptions.strokeWeight = this.newAnnotationForm.value.lineWidth
        }
    }
  }

  // supportLinkUrl(linkurl){
  //   this.linkURL = linkurl
  //   if(linkurl){
  //     try{
  //       const imgUrl = new URL(linkurl)
  //       let hostname = environment.urlHostName
        
  //       if(imgUrl.protocol=="pass:" ){
  //         const parts = linkurl.match(/pass:\/\/(.+)\/(.+)/)        
  //         if(parts){
  //           const passTemplateID = parts[1]
  //           const path = parts[2]
  //           const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
  //           this.linkURL=firebaseUrl
  //         }else{
  //           const newparts = linkurl.match(/pass:\/\/(.+)/)
  //           if(newparts){
  //             let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
  //             newparts.forEach( (part,index)=> {
  //               if(index>0){
  //                 firebaseUrl += '/' + part 
  //               }
  //             })
  //             this.linkURL=firebaseUrl
  //           }
  //         }
  //       } 

  //       if(imgUrl.protocol =="tridy:"){
  //         const parts = linkurl.match(/tridy:\/\/(.+)\/(.+)/)
  //         let path =""
  //         parts.forEach((parte, index) =>{
  //           if(index>0 && index<parts.length){
  //             path += "/" + parte
  //           }
  //         })
  //         const firebaseUrl = `https://${hostname}/v1/pass${path}`

  //         this.linkURL=firebaseUrl
  //       }

  //       if(imgUrl.protocol == "context:"){
  //         const parts = linkurl.match(/context:\/\/(.+)\/(.+)/)
  //         let path =""
  //         parts.forEach((parte, index) =>{
  //             if(index>0 && index<parts.length){
  //                 path += "/" + parte
  //             }
  //         })
  //         const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}/${path}`
  //         this.linkURL=firebaseUrl
  //       } 
  //     }catch(erro){
  //     }
  //   }
  // }

  supportUrlStructureCMPT(linkurl){
    this.linkURL = linkurl
    let url = supportUrlStructure(linkurl, this.passId, this.settingsService.contextId$)
    if(url)
      this.linkURL = url

  }

  openMapDirection(markerPosition){
    return openMapDirection(markerPosition)
  }


  loadMap(i){
    try{
      var mapProp= {
        center:new google.maps.LatLng(51.508742,-0.120850),
        zoom:5,
      };
      const id = "map-annotation-dialog-"+i
      this.map = new google.maps.Map(document.getElementById(id));
      this.initMap(i)
    }catch(e){
      console.log(e)
    }
  }

  async initMap(i) {
    const id = 'map-annotation-dialog-'+i
    
    // // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    let position = { lat: 46.83310649280406,lng : 2.9504472409971418 };
    let mapWidgetId = this.annotationData?.id ?? this.annotationId

    let mapTypeId="roadmap" 
    // if(this.annotatedMapEditData && this.annotatedMapEditData.style && this.annotatedMapEditData.style=='satellite'){
    //     mapTypeId = this.annotatedMapEditData.style   
    // } 
    this.map = new Map(document.getElementById(id), {
        zoom: 4,
        center: position,
        mapId: "dev",
        mapTypeId: mapTypeId,
        disableDefaultUI: true
    });

    const directionTranslation = this.translate.instant('pass.map.open_direction')

    if(this.annotationData ){
      const annotation = this.newAnnotationForm.value
      const annotationId = annotation.id
      const index = this.BADGE

      if(annotation.type){
        if(annotation.type=="point"){
          let pos = annotation
          if(annotation.coordinate){
            pos['lat']=annotation.coordinate[0]
            pos['lng']=annotation.coordinate[1]
          }

          if(!annotation.badge)
            pos['badge']=(index +1).toString()
          
          if(pos.name)
            pos['title']=pos.name

          let contentString = "this is an example"
          contentString ='<div class="d-flex flex-column">'
          if(pos.title)
            contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
          if(pos.subtitle)
            contentString +='<span>'+pos.subtitle+' </span>'
          if(pos.linkURL){
            contentString +='<a href="'+pos.linkURL+'" target="_blank" style="color:var(--warmOrange);">'
              contentString +='<span class="material-symbols-outlined">link</span>'
            contentString +='</a>'
          }
          if(!pos.title && !pos.linkURL && !pos.subtitle)
            contentString +='<span>...</span>'

            if(pos['directions']){
              const directions = this.openMapDirection(annotation)
              const directionTranslation = this.translate.instant('pass.map.open_direction')
              contentString += '<a href="'+directions+'" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'
            }

          contentString +=' </div> ' 
          
          let iconImg = document.createElement('div');
          let pinColor = '#FF5E38'

          if(annotation.pinBackgroundColor)
            pinColor = annotation.pinBackgroundColor
          else if(annotation.pinColor)
            pinColor = annotation.pinColor

          let textColor = 'black'

          if(annotation.pinForegroundColor )
              textColor = annotation.pinForegroundColor

          const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
              <title>${annotation.badge ?? index}</title>
              <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                  <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                  <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                </g>
          </svg>`

          iconImg.innerHTML = svg
          let pinData = {glyph: pos.badge}
          
            pinData['background']=pinColor
            pinData['borderColor'] = annotation.pinForegroundColor
            pinData['glyphColor']= textColor

          const pin = new PinElement(pinData);

          const imgPin = new google.maps.marker.AdvancedMarkerElement({
            map:this.map,
            content: iconImg,
            position: pos,
            title: 'annotation_______',
          });
          const infowindow = new google.maps.InfoWindow({
            content: contentString,
            ariaLabel: index+'',
          });
          // const marker = new AdvancedMarkerElement({
          //   map: this.map,
          //   position: pos,
          //   title:"annotation-"+index,
          //   content:pin.element
          // });

          // marker.addListener("click", () => {
          //   infowindow.open({
          //     anchor: marker,
          //     map:this.map,
          //   });
          // });

          imgPin.addListener("click", () => {
              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });
          this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
        }

        if(annotation.type=="polygon"){
          if(annotation.encodedContent){
            const encodedContent = annotation['encodedContent'].split(",")
            let pairs
              pairs = encodedContent.reduce(function(result, value, indexV, array) {
              if (indexV % 2 === 0){
                const splited = array.slice(indexV, indexV + 2)
                var lat: number = +splited[0];
                var lng: number = +splited[1];
                const value = { lat: lat, lng: lng }
                result.push(value);
              }
              return result;
            }, []);

            this.pairsPath[annotationId] = pairs
            this.actualPolygonOptions[mapWidgetId]={}
            this.actualPolygonOptions[mapWidgetId][index] = this.polygonOptions
            this.actualPolygonOptions[mapWidgetId][index].paths = pairs

            if(annotation.fillColor)
              this.actualPolygonOptions[mapWidgetId][index].fillColor = annotation.fillColor

            if(annotation.strokeColor)
              this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

            if(annotation.lineWidth)
              this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth

            if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].paths && this.actualPolygonOptions[mapWidgetId][index].paths[0]){
              this.center=this.actualPolygonOptions[mapWidgetId][index].paths[0]
              this.zoom = 9
              const newcenter = new google.maps.LatLng(this.center)
              this.map.setCenter(newcenter);
              this.map.setZoom(this.zoom)
            }
            const polygon = new google.maps.Polygon(this.actualPolygonOptions[mapWidgetId][index])
            polygon.setMap(this.map);
          }
        }

        if(annotation.type=="line"){
          if(annotation.encodedContent){
            const encodedContent = annotation['encodedContent'].split(",")
            let pairs
              pairs = encodedContent.reduce(function(result, value, indexV, array) {
              if (indexV % 2 === 0){
                const splited = array.slice(indexV, indexV + 2)
                var lat: number = +splited[0];
                var lng: number = +splited[1];
                const value = { lat: lat, lng: lng }
                result.push(value);
              }
              return result;
            }, []);

            this.pairsPath[annotationId] = pairs
            this.actualPolygonOptions[mapWidgetId]={}
            this.actualPolygonOptions[mapWidgetId][index] = this.polylineOptions
            this.actualPolygonOptions[mapWidgetId][index].path = pairs

            if(annotation.strokeColor)
              this.actualPolygonOptions[mapWidgetId][index].strokeColor = annotation.strokeColor

            if(annotation.lineWidth)
              this.actualPolygonOptions[mapWidgetId][index].strokeWeight = annotation.lineWidth
              
            if(this.actualPolygonOptions[mapWidgetId][index] && this.actualPolygonOptions[mapWidgetId][index].path && this.actualPolygonOptions[mapWidgetId][index].path[0]){
              this.center= this.actualPolygonOptions[mapWidgetId][index].path[0]
              this.zoom = 11
              const newcenter = new google.maps.LatLng(this.center)
              this.map.setCenter(newcenter);
              this.map.setZoom(this.zoom)
            }

            const line = new google.maps.Polyline(this.actualPolygonOptions[mapWidgetId][index]);
            line.setMap(this.map);
          }
        }

      }else{
        if(annotation.lat || annotation.lng || annotation.coordinate){
          let pos = annotation
          if(annotation.coordinate){
            pos['lat']=annotation.coordinate[0]
            pos['lng']=annotation.coordinate[1]
          }
  
          if(!annotation.badge)
            pos['badge']=(index +1).toString()
          
          if(pos.name)
            pos['title']=pos.name
  
          let contentString = "this is an example"
          contentString ='<div class="d-flex flex-column">'
          if(pos.title)
            contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
          if(pos.subtitle)
            contentString +='<span>'+pos.subtitle+' </span>'
          if(pos.linkURL){
            contentString +='<a href="'+pos.linkURL+'" target="_blank" style="color:var(--warmOrange);">'
            contentString +='<span class="material-symbols-outlined">link</span>'
            contentString +='</a>'
          }
          if(!pos.title && !pos.linkURL && !pos.subtitle)
            contentString +='<span>...</span>'
          
            if(pos['directions']){
              const directions = this.openMapDirection(annotation)
              const directionTranslation = this.translate.instant('pass.map.open_direction')
              contentString += '<a href="'+directions+'" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'
            }          
          contentString +=' </div> '      
            
          let iconImg = document.createElement('div');
            let pinColor = '#FF5E38'

            if(annotation.pinBackgroundColor)
              pinColor = annotation.pinBackgroundColor
            else if(annotation.pinColor)
              pinColor = annotation.pinColor

            let textColor = 'black'

            if(annotation.pinForegroundColor )
                textColor = annotation.pinForegroundColor

            const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
                <title>${annotation.badge ?? index}</title>
                <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                    <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                    <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                  </g>
            </svg>`

            iconImg.innerHTML = svg

            let pinData = {glyph: pos.badge}
              pinData['background']=pinColor
              pinData['borderColor'] = annotation.pinForegroundColor
              pinData['glyphColor']= textColor

            const pin = new PinElement(pinData);
            const imgPin = new google.maps.marker.AdvancedMarkerElement({
              map:this.map,
              content: iconImg,
              position: pos,
              title: 'annotation_______',
            });
  
          const infowindow = new google.maps.InfoWindow({
            content: contentString,
            ariaLabel: index+'',
          });
          imgPin.addListener("click", () => {
            infowindow.open({
              anchor: imgPin,
              map:this.map,
            });
          });

          this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
        }
      }  
    }else{

      const annotation = this.newAnnotationForm.value
      const annotationId = annotation.id
      const index = this.BADGE
      if(annotation.type=="point"){
        if(annotation.lat && annotation.lng){
          let pos = annotation
          pos['lat'] = parseFloat(this.newAnnotationForm.value.lat)
          pos['lng'] = parseFloat(this.newAnnotationForm.value.lng)
  
          if(!annotation.badge)
            pos['badge']=(index +1).toString()
          
          if(pos.name)
            pos['title']=pos.name
  
          let contentString = "this is an example"
          contentString ='<div class="d-flex flex-column">'
          if(pos.title)
            contentString +='<p style="font-size: 16px;">'+ pos.title+'</p>'
          if(pos.subtitle)
            contentString +='<span>'+pos.subtitle+' </span>'
          if(pos.linkURL){
            contentString +='<a href="'+pos.linkURL+'" target="_blank" style="color:var(--warmOrange);">'
              contentString +='<span class="material-symbols-outlined">link</span>'
            contentString +='</a>'
          }
          if(!pos.title && !pos.linkURL && !pos.subtitle)
            contentString +='<span>...</span>'
  
          if(pos['directions']){
            const directions = this.openMapDirection(annotation)
            const directionTranslation = this.translate.instant('pass.map.open_direction')
            contentString += '<a href="'+directions+'" style="flex-grow: 1;padding-left: 5px;text-decoration:none; "> <button mat-button class="secondary-gray-button w-100" style="border:none; display:flex; align-items:center;"><span class="material-symbols-outlined">open_in_new</span>'+directionTranslation+'</button></a>'
          }
          contentString +=' </div> ' 
          
          let iconImg = document.createElement('div');
          let pinColor = '#FF5E38'
  
          if(annotation.pinBackgroundColor)
            pinColor = annotation.pinBackgroundColor
          else if(annotation.pinColor)
            pinColor = annotation.pinColor
  
          let textColor = 'black'
  
          if(annotation.pinForegroundColor )
              textColor = annotation.pinForegroundColor
  
          const svg = `<svg width="36px" height="42px" viewBox="0 0 146 169" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
              <title>${annotation.badge ?? index}</title>
              <g id="Page-1" stroke="${textColor}" stroke-width="2" fill="none" fill-rule="evenodd" >
                  <path d="M73,0 C113.316787,0 146,32.6832133 146,73 C146,105.660995 124.550767,133.312254 94.9700872,142.635991 L73,169 L51.0309086,142.636305 C21.449714,133.312875 0,105.661362 0,73 C0,32.6832133 32.6832133,0 73,0 Z" id="Combined-Shape" fill="${pinColor}"></path>
                  <text text-anchor="middle" x="50%" y="50%" font-size="70" fill="${textColor}">${pos.badge ?? index}</text>
                </g>
          </svg>`
  
          iconImg.innerHTML = svg
          let pinData = {glyph: pos.badge}
            pinData['background']=pinColor
            pinData['borderColor'] = annotation.pinForegroundColor
            pinData['glyphColor']= textColor
  
          const pin = new PinElement(pinData);
  
          const imgPin = new google.maps.marker.AdvancedMarkerElement({
            map:this.map,
            content: iconImg,
            position: pos,
            title: 'annotation_______',
          });
          const infowindow = new google.maps.InfoWindow({
            content: contentString,
            ariaLabel: index+'',
          });
          // const marker = new AdvancedMarkerElement({
          //   map: this.map,
          //   position: pos,
          //   title:"annotation-"+index,
          //   content:pin.element
          // });
          imgPin.addListener("click", () => {
              infowindow.open({
                anchor: imgPin,
                map:this.map,
              });
            });
          this.map.setCenter(new google.maps.LatLng(pos['lat'], pos['lng']));
        }
      }

    }
  }

  checkValidColors(type){
    if(type=='point'){
      //-------pinBackgroundColor
      if(this.newAnnotationForm.controls['pinBackgroundColor'].hasError('invalidColor')){
        this.invalid_pinBackgroundColor=true
      }else{
        this.invalid_pinBackgroundColor = false
      }

      //-------pinForegroundColor
      if(this.newAnnotationForm.controls['pinForegroundColor'].hasError('invalidColor')){
        this.invalid_pinForegroundColor=true
      }else{
        this.invalid_pinForegroundColor = false
      }
    }

    if(type=='polygon'){
      //-------strokeColor
      if(this.newAnnotationForm.controls['strokeColor'].hasError('invalidColor')){
        this.invalid_strokeColor=true
      }else{
        this.invalid_strokeColor = false
      }

      //-------fillColor
      if(this.newAnnotationForm.controls['fillColor'].hasError('invalidColor')){
        this.invalid_fillColor=true
      }else{
        this.invalid_fillColor = false
      }
    }

    if(type=='line'){
      //-------strokeColor
      if(this.newAnnotationForm.controls['strokeColor'].hasError('invalidColor')){
        this.invalid_strokeColor=true
      }else{
        this.invalid_strokeColor = false
      }
    }
  }
}
