export const defaultWidgets={
    billboardImage:{
        "imageSize":[0,0],
        "type": "billboardImage",
        "id": "4Wjhpp",
    },

    billboardVideo:{
        "type": "billboardVideo",
        "id": "4BQmwf",
        "url": ""
    },
    buttons:{
        "buttons":[
            {
                "backgroundColor": "#FF5E38",
                "borderColor": "#FF5E38",
                "customize":false,
                "foregroundColor": "#fefefe",
                "id": "89BA1A1F-2FD2-4ABB-9CFB-8F48809015A0",
                "title": "Button",
                "url": ""
            }
        ],
        "buttonsStyle":{
            "backgroundColor": "#FF5E38",
            "borderColor": "#FF5E38",
            "borderWidth": 0,
            "foregroundColor": "#fefefe"
        },
        "cornerRadius": 10,
        "id":"",
        "type":"buttons"

    },
    productRegistration:{
        "type":"productRegistration",
        "id":"",
        "name":"",
        "unregistered":{
            "text":"",
            "showImage":false,
        },
        "registered":{
            "text":"",
            "showImage":true,
            "textAlign":"left"
        },
        "image":{
            "url": "",
            "description": ""
        },
        "style":{
            "backgroundColor":"#FF5E38",
            "foregroundColor":"#FEFEFE",
            "borderColor":"#FF5E38",
            "borderWidth": 0
        }
    },
    text:{
        "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        "title": "",
        "id": "4iVB3b",
        "type": "text",
        "imagePosition": "left"
    },
    image:{
        "type": "image",
        "url": "",
        "id": "4mKSfU"
    },
    annotatedMap:{
        "type": "annotatedMap",
        "style": "satellite",
        "annotations": [],
        "id": "MdjbS",
        "layout": "singleMap"
    },
    callToAction:{
        "imageUrl": "",
        "type": "callToAction",
        "socialNetworks": [],
        "hasLogin": true,
        "id": "29n4Cw"
    },
    products:{
        "layout": "vertical",
        "type": "products",
        "id": "4YbqrV"
    },
    shopify:{ 
        "type": "shopify",
        "id": "1FWkSL"
    },
    spacer:{
        "divider":false,
        "id":"",
        "type":"spacer"
    },
    video:{
        "url": "",
        "type": "video",
        "id": "1dPjkf",
        "title": ""
    },
    splashVideo:{
        "id": "3V8XNS",
        "type": "splashVideo",
        "url": ""
    },
    imagesHorizontal:{
        "images": [
        ],
        "id": "35y3tS",
        "type": "imagesHorizontal"
    },
    imageBlocks:{
        "id": "RlPdM",
        "layout": "threeLayout1",
        "type": "imageBlocks",
        "images": []
    },
    "3dmodel":{
        "id": "EJl0E",
        "url": "",
        "type": "3dmodel"
    },
    instagram:{
        "id": "MPXxx",
        "type": "instagram"
    },
    images:{
        "id":"ppp",
        "type":"images",
        "layout":"horizontal",
        "images":[]
    },
    list:{
        "type": "list",
        "style":"definition",
        "id":"",
        "items": [
            {
            "title": "Title",
            "value": "Value",
            },
            {
            "title": "Title 2",
            "value": "Value with ${placeholder}"
            }
        ]
    },
    form:{
        "type":"form",
        "sections":[],
        "successMessage":"Form Submitted Successfully",
        "id":"",
        "title":"",
        "name":""
    }

}