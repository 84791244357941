<h2 mat-dialog-title>{{'pass.widgets.productRegistration' | translate}}</h2>

<!-- <div *ngIf="defaultLanguage" style=" position: absolute;top: 25px;right: 10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div>
 -->
<mat-dialog-content>
    <div class="pt-2 pb-3" style="width:600px;">
        <form [formGroup]="registerProductForm">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'pass.name' | translate}}</mat-label>
                <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
            </mat-form-field>

            <div class="form-control p-3 mb-4 d-flex justify-content-between w-100">
                <label for="showRegisteredPreview">{{'pass.showRegistered' | translate}}</label>
                <mat-slide-toggle class="ms-5 example-margin" formControlName="registeredView"></mat-slide-toggle>  
            </div>

            <mat-accordion multi>
                    <mat-expansion-panel class="mt-1 mb-1" expanded>
                    
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="material-symbols-outlined">image</mat-icon> <span class="px-2">{{'pass.widgets.img' | translate}}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        
                        <div class="px-2 py-3">
                            <div *ngIf="urlData" class="mt-2">
                                <div class="d-flex justify-content-stretch">
        
                                    <div class="custom-form-field" [ngStyle]="{'background-image': 'url(' + urlData + ')'}"><!-- 
                                        <img [src]="registerProductForm.value.imageUrl" (error)="onImageError()" style="display: none;"> -->
                                      </div>
                                      <div class="form-fields">
                                        <div class="d-flex align-items-center">
                                          <mat-form-field appearance="outline" class="flex-grow-1">
                                            <mat-label>{{ 'pass.url' | translate }}</mat-label>
                                            <input type="text" formControlName="imageUrl" matInput (change)="checkNewUrl()">
                                          </mat-form-field>
                                          <button mat-icon-button class="delete-button" style="margin-top:-20px;" (click)="deleteImg(url)" matTooltip="Delete Image">
                                            <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                          </button>
                                        </div>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{ 'pass.image_description' | translate }}</mat-label>
                                            <input type="text" formControlName="imageDescription" matInput>
                                          </mat-form-field>
                                      </div>
                                </div>
                            </div>
                            <div *ngIf="!urlData" appDragAndDrop (files)="filesDropped($event)">
                                <mat-card class="upload-section text-center justify-content-center">
                                    <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
                                        <div class="py-2 mb-4">
                                            <mat-icon class="material-symbols-outlined">add_photo_alternate</mat-icon>
                                            <h2>{{'pass.fileUploadMessage' | translate}}
                                                <button type="button" class="labelImage" (click)="triggerFileInput()">
                                                    <strong style="font-family: 'MyFont-Bold', sans-serif;">{{'pass.browse' | translate}}</strong>
                                                </button>
                                                {{'pass.or' | translate}}
                                                <button type="button" class="labelImage" (click)="typeURL()"><strong style="font-family: 'MyFont-Bold', sans-serif;">{{'enterURL' | translate}}</strong></button>
                                                <input #fileInput type="file" id="myfile" name="myfile" style="display: none;" accept=".jpg, .jpeg, .png, .webp" (change)="readURL($event)">
                                            </h2>    
                                        </div>
                                        <p class="py-2 mt-4">{{'pass.supportedFiles' | translate}}: .jpg, .jpeg, .png, .webp</p>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                        
                    
                    </mat-expansion-panel>

                    <mat-expansion-panel class="mt-1 mb-1" expanded>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="material-symbols-outlined">notes</mat-icon> <span class="px-2">{{'pass.widgets.content' | translate}}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
    
                        <mat-tab-group class="py-3">
                            <mat-tab label="{{'pass.registered' | translate}}">
                                <div class="p-2">
                                    <!-- //// CONTENT FORM //// -->
                                    <div class="mt-4">
                                        <mat-form-field appearance="outline" class="w-100 mb-1">
                                            <mat-label>{{'pass.widgets.text' | translate}}</mat-label>
                                            <textarea matInput
                                                cdkTextareaAutosize
                                                matInput formControlName="registeredText"
                                                cdkAutosizeMinRows="1"
                                                cdkAutosizeMaxRows="5" class="form-control" id="text"></textarea>  
                                        </mat-form-field>
                        
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'pass.textAlignment' | translate}}</mat-label>
                                            <mat-select formControlName="registeredTextAlign" class="input-group">
                                                <mat-option value="right"><mat-icon>format_align_right</mat-icon> {{'pass.right' | translate}}</mat-option>
                                                <mat-option value="left"><mat-icon>format_align_left</mat-icon> {{'pass.left' | translate}}</mat-option>
                                                <mat-option value="center"><mat-icon>format_align_center</mat-icon> {{'pass.center' | translate}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
    
                                        <div class="form-control p-3 mb-4 d-flex justify-content-between w-100">
                                            <label for="registeredShowImage">{{'pass.showImage' | translate}}</label>
                                            <mat-slide-toggle class="ms-5 example-margin" formControlName="registeredShowImage"></mat-slide-toggle>  
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="{{'pass.unregistered' | translate}}">
                                <div class="p-2">
                                    <div class=" mt-4">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{'pass.text' | translate}}</mat-label>
                                            <input formControlName="unregisteredText" type="text" matInput>
                                        </mat-form-field>
                                        <div class="form-control p-3 mb-4 d-flex justify-content-between w-100">
                                            <label for="unregisteredShowImage">{{'pass.showImage' | translate}}</label>
                                            <mat-slide-toggle class="ms-5 example-margin" formControlName="unregisteredShowImage"></mat-slide-toggle>  
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>                        
                        </mat-tab-group>
    
                    </mat-expansion-panel>

                    <mat-expansion-panel class="mt-1 mb-1" expanded>
                    
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="material-symbols-outlined">palette</mat-icon> <span class="px-2">{{'pass.editStyle' | translate}}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                            <mat-list class="my-2">
                                <mat-list-item role="listitem">
                                    <div class="flex-between-center">
                                        <div>{{'pass.background' | translate}}</div>
                                        <div class="flex-between-center">
                                            <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;">
                                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" formControlName="backgroundColor" style="margin-left:10px;"></ngx-colors>
                                                <span *ngIf="invalid_backgroundColor" id="invalid_accent" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                            </label>
                                        </div>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item role="listitem">
                                    <div class="flex-between-center">
                                        <div>{{'pass.foregroundColor' | translate}}</div>
                                        <div class="flex-between-center">                         
                                            <div class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;">
                                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" formControlName="foregroundColor" style="margin-left:10px;"></ngx-colors>
                                                <span *ngIf="invalid_foregroundColor" id="invalid_subtitle" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item role="listitem">
                                    <div class="flex-between-center">
                                        <div>{{'pass.productRegistration.borderColor' | translate}}</div>
                                        <div class="flex-between-center">
                                            <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;">
                                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" formControlName="borderColor" style="margin-left:10px;"></ngx-colors>
                                                <span *ngIf="invalid_borderColor" id="invalid_title" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                            </label>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </mat-list>
    
                            <mat-form-field appearance="outline" class="w-100 mt-2">
                                <mat-label>{{'pass.productRegistration.borderWidth' | translate}}</mat-label>
                                <input formControlName="borderWidth" type="number" matInput min="0" placeholder="{{'pass.productRegistration.borderWidth' | translate}}">
                                <span matSuffix class="p-3"> px</span>
                            </mat-form-field>

                    </mat-expansion-panel>



            </mat-accordion>          

        </form>

    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onSaveEdit();" >{{'apply' | translate}}</button>
</mat-dialog-actions>