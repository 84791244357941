import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-task-template-import-field-edit-dialog',
  templateUrl: './task-template-import-field-edit-dialog.component.html',
  styleUrls: ['./task-template-import-field-edit-dialog.component.css']
})
export class TaskTemplateImportFieldEditDialogComponent {
  editItemForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<TaskTemplateImportFieldEditDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editItemForm = this.fb.group({
      key: [data.key],
      tridyPayloadKey: [data.tridyPayloadKey]
    });
  }

  onSave(): void {
    if (this.editItemForm.valid) {
      this.dialogRef.close(this.editItemForm.value);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
