import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';
import { deepCopy } from '../../../../../../backend/utils/object';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';

@Component({
  selector: 'app-list-edit',
  templateUrl: './list-edit.component.html',
  styleUrls: ['./list-edit.component.css']
})
export class ListEditComponent {
  listEdiForm!:FormGroup
  newItemForm!:FormGroup
  listData:any
  oldData:any
  widgetNumber:any
  passId:any
  panelOpenState = false
  languagesList = languagesList
  defaultLanguage:string
  
  constructor(public dialogRef: MatDialogRef<ListEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private commentService: CommentsService,
    private translate:TranslateService
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage

    this.listData = this.data.values
    this.oldData = deepCopy(this.listData)
    this.passId = this.data['passId']
    this.widgetNumber= this.data['widget']

    this.listEdiForm = this.fb.group({
      type:[],
      items:[],
      id:[],
      style:[],
      name:[]
    })

    const { type, items, id, style,name} = this.listData

    this.listEdiForm.patchValue({
      name: name ? name : undefined,
      type:type ? type : undefined,
      items: items ? items : undefined,
      id: id ? id : undefined,
      style:style ? style : "definition"
    })

    this.newItemForm = this.fb.group({
      title:[],
      value:[]
    })

  }

  addItem(){
    this.commentService.progressSpin.emit(true)
    const newItem = {
      title: this.newItemForm.value.title,
      value: this.newItemForm.value.value,
      id: this.makeBtnId(30)
    }

    const allItems = deepCopy(this.listEdiForm.value.items)
    allItems.push(newItem)
    this.listEdiForm.patchValue({items: allItems})

    this.newItemForm.patchValue({
      title:undefined,
      value:undefined
    })
    this.commentService.progressSpin.emit(false)

  }

  makeBtnId(length) {
    let result = '';
    const characters = '--ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  deleteItem(item, index){
    this.commentService.progressSpin.emit(true)
    const allItems = deepCopy(this.listEdiForm.value.items)
    allItems.splice(index,1)
    this.listEdiForm.patchValue({items: allItems})
    this.commentService.progressSpin.emit(false)
  }

  dialogCancel(){
    this.commentService.progressSpin.emit(true)
    this.dialogRef.close([this.oldData, false])
    this.commentService.progressSpin.emit(false)

  }

  dialogSave(){
    this.commentService.progressSpin.emit(true)
    this.dialogRef.close([this.listEdiForm.value, true])
    this.commentService.progressSpin.emit(false)

  }

}
