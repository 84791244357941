import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { validColorValidator } from 'ngx-colors';
import { UploadService } from '../service/upload.service';
import { CommentsService } from '../service/comments.service';
import { deepCopy, removeUndefined, removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { FileHandle } from '../directive/drag-and-drop.directive';
import { languagesList } from '../../../../backend/src/languagesList.dto';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { TemplatesService } from '../service/templates.service';
import { StorageService } from '../service/storage.service';
import { SettingsService } from '../service/settings.service';

@Component({
  selector: 'app-pass-templates-theme-edit',
  templateUrl: './pass-templates-theme-edit.component.html',
  styleUrls: ['./pass-templates-theme-edit.component.css']
})

export class PassTemplatesThemeEditComponent implements OnInit {

  contextCdData:any
  uploadedCDFonts: { name: string, url: string }[] = [];
  uploadedPassFonts: { name: string, url: string }[] = [];
  emptyFonts:boolean = false

  passData:any
  passEditForm!:FormGroup
  themeEditData:any
  themeEditForm!:FormGroup
  lightColorsForm!:FormGroup
  darkColorsForm!:FormGroup
  fontsForm!:FormGroup
  lightColorsData:any
  allEmpty = false
  darkColorsData:any
  fontsData:any
  oldData:any
  rgba:string="rgba"
  accent:any
  showSpinner:boolean=true
  fontType:any
  urlFonts:any={}
  panelOpenState=true
  invalid_accent=false
  invalid_background=false
  invalid_title=false
  invalid_subtitle=false
  invalid_primary=false
  invalid_secondary=false

  invalid_accentDark=false
  invalid_backgroundDark=false
  invalid_titleDark=false
  invalid_subtitleDark=false
  invalid_primaryDark=false
  invalid_secondaryDark=false

  fontFields = [
    { label: 'title', controlName: 'title' },
    { label: 'subtitle', controlName: 'subtitle' },
    { label: 'plain', controlName: 'plain' },
    { label: 'bold', controlName: 'bold' },
    { label: 'italic', controlName: 'italic' }
  ];
  
  colorPalette:any=[]
  defaultColorsValuesHex:any={
    'accent':'#FF5E38',
    'background':'#ffffff',
    'title':'#000000',
    'subtitle':'#3C3C4399',
    'primary':'#000000',
    'secondary':'#3C3C4399'
  }
  defaultColorsValues:any={
    'accent':'rgb(255, 94, 56)',
    'background':'rgb(255, 255, 255)',
    'title':'rgb(0, 0, 0)',
    'subtitle':'rgb(60, 60, 67, 0.60)',
    'primary':'rgb(0, 0, 0)',
    'secondary':'rgb(60, 60, 67, 0.60)'
  }

  defaultDarkColorsValues:any={
    'accent':'rgb(255, 94, 56)',
    'background':'rgb(0, 0, 0)',//'rgb(255, 255, 255)',
    'title':'rgb(255,255,255)',//'rgb(0, 0, 0)'
    'subtitle':'rgb(129,129,129)',//'rgb(60, 60, 67, 0.60)'
    'primary':'rgb(255,255,255)',//'rgb(0, 0, 0)',
    'secondary':'rgb(129,129,129)'//'rgb(60, 60, 67, 0.60)'
  }


  defaultDarkColorsValuesHex:any={
    'accent':'#FF5E38',//'rgb(255, 94, 56)',
    'background':'#000000',//rgb(0, 0, 0//'rgb(255, 255, 255)',
    'title':'#ffffff',//'rgb(255,255,255)',//'rgb(0, 0, 0)'
    'subtitle':'#818181',//'rgb(129,129,129)',//'rgb(60, 60, 67, 0.60)'
    'primary':'#ffffff',//'rgb(255,255,255)',//'rgb(0, 0, 0)',
    'secondary':'#818181'//'rgb(129,129,129)'//'rgb(60, 60, 67, 0.60)'
  }

  darkAsLight :Subject<boolean> = new Subject<boolean>();//=true
  darkAsLightValue :boolean=false

  contextId:any
  files: FileHandle[] = []
  drop=false
  languagesList = languagesList
  languagesPass:any=[]
  oldLanguages:any
  languages:any

  constructor(public dialogRef: MatDialogRef<PassTemplatesThemeEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uploadService: UploadService,
    private commentsService: CommentsService,
    private storageService: StorageService,
    private settingsService: SettingsService,
    private fb: FormBuilder,
    private translate:TranslateService,
    private templateService: TemplatesService){

      dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.languages = Object.entries(languagesList)
    this.languages.sort( (a,b) => { return a[1].toLowerCase().localeCompare(b[1].toLowerCase());})
    this.initializePaletteColors()
    this.passData = deepCopy(this.data.formData)//deepCopy(this.data[0])
    this.oldData = deepCopy(this.data.oldData)//deepCopy(this.data[0])
    this.contextId=this.data.contextId//this.data[1]

    const defaultCDTheme = this.settingsService.contextAccount$?.design?.theme;
    if (defaultCDTheme) {
      this.contextCdData = defaultCDTheme;
    }    

    if (this.passData.theme) {
      this.themeEditData = this.passData.theme
      // Light Colors
      if (this.passData.theme.lightColors) {
        this.lightColorsData = this.passData.theme.lightColors;
        this.setEmptyToDefaultColors();
      } else {
        this.lightColorsData = {};
        this.setEmptyToDefaultColors();
      }

      // Dark Colors
      if (this.passData.theme.darkColors) {
        this.darkColorsData = this.passData.theme.darkColors;
        this.setEmptyToDefaultColors();
      } else {
        this.darkColorsData = {};
        this.setEmptyToDefaultColors();
      }

      if(!this.passData.theme.lightColors && !this.passData.theme.darkColors){
        // this.templateService.activeDarkAsLight()
        this.templateService.deactiveDarkAsLight()
        this.darkAsLightValue = this.templateService.darkAsLightValue
      }

      if(this.passData.theme.lightColors && this.passData.theme.darkColors){
        this.templateService.deactiveDarkAsLight()
        this.darkAsLightValue = this.templateService.darkAsLightValue
      }

      if(this.passData.theme.lightColors && !this.passData.theme.darkColors){
        this.templateService.deactiveDarkAsLight()
        this.darkAsLightValue = this.templateService.darkAsLightValue
      }
      // Fonts
      if (this.passData.theme.fonts) {
        this.fontsData = this.passData.theme.fonts;
      } else {
        this.fontsData = {
          fonts: {
            title: {
              name: undefined,
              url: undefined,
            },
            subtitle: {
              name: undefined,
              url: undefined,
            },
            plain: {
              name: undefined,
              url: undefined,
            },
            bold: {
              name: undefined,
              url: undefined,
            },
            italic: {
              name: undefined,
              url: undefined,
            },
          },
        };
      }
    } else {
      this.themeEditData = {cornerRadius: undefined, themeEditData: undefined}
      // Light Colors
      this.lightColorsData = this.defaultColorsValues;

      // Dark Colors
      this.darkColorsData = this.defaultDarkColorsValues;
      this.setEmptyToDefaultColors();

      this.templateService.deactiveDarkAsLight()
      // this.templateService.activeDarkAsLight()
      this.darkAsLightValue = this.templateService.darkAsLightValue

      // Fonts
      this.fontsData = {
        fonts: {
          title: {
            name: undefined,
            url: undefined,
          },
          subtitle: {
            name: undefined,
            url: undefined,
          },
          plain: {
            name: undefined,
            url: undefined,
          },
          bold: {
            name: undefined,
            url: undefined,
          },
          italic: {
            name: undefined,
            url: undefined,
          },
        },
      };
    }

    if(this.passData.languages){
      this.languagesPass = deepCopy(this.passData.languages)
      this.oldLanguages = deepCopy(this.passData.languages)
    }else{
      this.languagesPass= []
    }
      
    this.lightColorsForm = this.fb.group({
      accent:[this.lightColorsData.accent,validColorValidator()],
      background:[this.lightColorsData.background,validColorValidator()],
      primary:[this.lightColorsData.primary,validColorValidator()],
      secondary:[this.lightColorsData.secondary,validColorValidator()],
      subtitle:[this.lightColorsData.subtitle,validColorValidator()],
      title:[this.lightColorsData.title,validColorValidator()],
    })

    this.darkColorsForm = this.fb.group({
      accent: [this.darkColorsData.accent, validColorValidator()],
      background: [this.darkColorsData.background, validColorValidator()],
      primary: [this.darkColorsData.primary, validColorValidator()],
      secondary: [this.darkColorsData.secondary, validColorValidator()],
      subtitle: [this.darkColorsData.subtitle, validColorValidator()],
      title: [this.darkColorsData.title, validColorValidator()],
    });

    this.fontsForm = this.fb.group({
      title:[],
      subtitle:[],
      plain:[],
      bold:[],
      italic:[]
    })

    this.loadFonts()

    this.themeEditForm = this.fb.group({
      lightColors: [this.lightColorsForm.value],
      darkColors: [this.darkColorsForm.value],
      fonts:[this.fontsForm.value],
      cornerRadius: [10],
      widgetSpacing: [20]
    });

    if (this.themeEditData) {
      const { cornerRadius, widgetSpacing } = this.themeEditData;
      this.themeEditForm.patchValue({
        cornerRadius: cornerRadius !== undefined ? cornerRadius : 10,
        widgetSpacing: widgetSpacing !== undefined ? widgetSpacing : 20
      });
      if (cornerRadius === 0) {
        this.themeEditForm.get('cornerRadius').setValue(0);
      }
      if (widgetSpacing === 0) {
        this.themeEditForm.get('widgetSpacing').setValue(0);
      }
    }

    this.passEditForm = this.fb.group({
      id:[this.passData.id],
      widgets:[this.passData.widgets],
      contextId:[this.passData.contextId],
      translations:[this.passData.translations],
      theme: {
        'lightColors': this.lightColorsForm.value,
        'darkColors': this.darkColorsForm.value,
      },
      comment:[this.passData.comment],
      name:[this.passData.name],
      contentType:[this.passData.contentType],
      customer:[this.passData.customer],
      tridyId:[this.passData.tridyId],
      languages:[this.passData.languages],
      enableShoppingCart: [this.passData.enableShoppingCart],
      enableCustomerLogin: [this.passData.enableCustomerLogin]
    })


    this.lightColorsForm.valueChanges.subscribe( (vlue:any) => {
      this.checkValidColors()

      if(this.darkAsLightValue){
        this.equalDarkColorsAsLightColors()
      }
    })

    this.darkColorsForm.valueChanges.subscribe((vlue: any) => {
      this.checkValidColors();
    });

    this.checkValidColors()

    this.templateService.darkAsLight.subscribe(value => {
      this.darkAsLightValue = value
      if(value){
        this.equalDarkColorsAsLightColors()
      }
    })

  }

  async loadFonts() {
    // Load both CD and Pass fonts
    await this.loadCDFonts();
    await this.loadPassFonts();
    // After loading fonts, update the form with preselected values
    this.preselectFonts();
    this.showSpinner = false;
}

preselectFonts() {
    // Check if passData has a theme and fonts object
    if (this.passData && this.passData.theme && this.passData.theme.fonts) {
        const themeFonts = this.passData.theme.fonts;

        // Extract the font values from the theme fonts
        const titleFont = this.findFontByName(themeFonts.title?.name);
        const subtitleFont = this.findFontByName(themeFonts.subtitle?.name);
        const plainFont = this.findFontByName(themeFonts.plain?.name);
        const boldFont = this.findFontByName(themeFonts.bold?.name);
        const italicFont = this.findFontByName(themeFonts.italic?.name);

        this.fontsForm.patchValue({
            title: titleFont,
            subtitle: subtitleFont,
            plain: plainFont,
            bold: boldFont,
            italic: italicFont
        });
    }
}

findFontByName(fontName: string) {
    const allFonts = [...this.uploadedCDFonts, ...this.uploadedPassFonts];
    return allFonts.find(font => font.name === fontName) || null;
}
async loadCDFonts() {
  try {
    const fonts = await this.storageService.getCdFonts(this.contextId);
    this.uploadedCDFonts = fonts.map(font => ({
      name: font.name,
      url: `context://fonts/${font.filename}`
    }));
    this.emptyFonts = this.uploadedCDFonts.length === 0;
  } catch (error) {
    console.error('Error loading CD fonts', error);
  }
}

async loadPassFonts() {
  try {
    const fonts = await this.storageService.getPassFonts(this.passData.id);
    this.uploadedPassFonts = fonts.map(font => ({
      name: font.name,
      url: `pass://${font.filename}`
    }));
    this.emptyFonts = this.uploadedPassFonts.length === 0;
  } catch (error) {
    console.error('Error loading Pass fonts', error);
  }
}

  isPassFontSelected(controlName: string): boolean {
    const selectedFont = this.fontsForm.get(controlName)?.value;
    return this.uploadedPassFonts.some(font => font === selectedFont);
  }

  initializePaletteColors(){
    var colors=[]
    const warmOrange= getComputedStyle(document.documentElement).getPropertyValue('--warmOrange')
    const darkblue= getComputedStyle(document.documentElement).getPropertyValue('--darkBlue')
    const pureWhite= getComputedStyle(document.documentElement).getPropertyValue('--pureWhite')
    const salbei= getComputedStyle(document.documentElement).getPropertyValue('--salbei')
    colors.push(warmOrange)
    colors.push(darkblue)
    colors.push(pureWhite)
    colors.push(salbei)
    this.colorPalette=colors
  }

  setEmptyToDefaultColors(){

    // light
    if(this.lightColorsData){
      if(!this.lightColorsData.accent)
        this.lightColorsData.accent=this.defaultColorsValues.accent
      
      if(!this.lightColorsData.background)
        this.lightColorsData.background=this.defaultColorsValues.background

      if(!this.lightColorsData.title)
        this.lightColorsData.title=this.defaultColorsValues.title
      
      if(!this.lightColorsData.subtitle)
        this.lightColorsData.subtitle=this.defaultColorsValues.subtitle

      if(!this.lightColorsData.primary)
        this.lightColorsData.primary=this.defaultColorsValues.primary
      
      if(!this.lightColorsData.secondary)
        this.lightColorsData.secondary=this.defaultColorsValues.secondary
      
    }
    // dark

    if(this.darkColorsData){
      if(!this.darkColorsData.accent)
        this.darkColorsData.accent=this.defaultDarkColorsValues.accent
      
      if(!this.darkColorsData.background)
        this.darkColorsData.background=this.defaultDarkColorsValues.background

      if(!this.darkColorsData.title)
        this.darkColorsData.title=this.defaultDarkColorsValues.title
      
      if(!this.darkColorsData.subtitle)
        this.darkColorsData.subtitle=this.defaultDarkColorsValues.subtitle

      if(!this.darkColorsData.primary)
        this.darkColorsData.primary=this.defaultDarkColorsValues.primary
      
      if(!this.darkColorsData.secondary)
        this.darkColorsData.secondary=this.defaultDarkColorsValues.secondary
      
    }
  }

  updateColors(){

    // light
    document.documentElement.style.setProperty('--accentLight',this.lightColorsForm.value.accent)
    document.documentElement.style.setProperty('--backgroundLight',this.lightColorsForm.value.background)
    document.documentElement.style.setProperty('--titleLight',this.lightColorsForm.value.title)
    document.documentElement.style.setProperty('--subtitleLight',this.lightColorsForm.value.subtitle)
    document.documentElement.style.setProperty('--primaryLight',this.lightColorsForm.value.primary)
    document.documentElement.style.setProperty('--secondaryLight',this.lightColorsForm.value.secondary)

    // dark
    document.documentElement.style.setProperty('--accentDark',this.darkColorsForm.value.accent)
    document.documentElement.style.setProperty('--backgroundDark',this.darkColorsForm.value.background)
    document.documentElement.style.setProperty('--titleDark',this.darkColorsForm.value.title)
    document.documentElement.style.setProperty('--subtitleDark',this.darkColorsForm.value.subtitle)
    document.documentElement.style.setProperty('--primaryDark',this.darkColorsForm.value.primary)
    document.documentElement.style.setProperty('--secondaryDark',this.darkColorsForm.value.secondary)
  }

   // needs refactor

  checkValidColors(){
    //-------accent
    if(this.lightColorsForm.controls['accent'].hasError('invalidColor')){
      this.invalid_accent=true
    }else{
      this.invalid_accent = false
    }

    if(this.darkColorsForm.controls['accent'].hasError('invalidColor')){
      this.invalid_accentDark=true
    }else{
      this.invalid_accentDark=false
    }

    //-----background
    if(this.lightColorsForm.controls['background'].hasError('invalidColor')){
      this.invalid_background=true
    }else{
      this.invalid_background=false
    }

    if(this.darkColorsForm.controls['background'].hasError('invalidColor')){
      this.invalid_backgroundDark=true
    }else{
      this.invalid_backgroundDark=false
    }

    //-------title
    if(this.lightColorsForm.controls['title'].hasError('invalidColor')){
      this.invalid_title=true
    }else{
      this.invalid_title = false
    }
    
    if(this.darkColorsForm.controls['title'].hasError('invalidColor')){
      this.invalid_titleDark=true
    }else{
      this.invalid_titleDark=false
    }

    //-----subtitle
    if(this.lightColorsForm.controls['subtitle'].hasError('invalidColor')){
      this.invalid_subtitle=true
    }else{
      this.invalid_subtitle = false
    }

    if(this.darkColorsForm.controls['subtitle'].hasError('invalidColor')){
      this.invalid_subtitleDark=true
    }else{
      this.invalid_subtitleDark=false
    }

    //------primary
    if(this.lightColorsForm.controls['primary'].hasError('invalidColor')){
      this.invalid_primary=true
    }else{
      this.invalid_primary = false
    }
    
    if(this.darkColorsForm.controls['primary'].hasError('invalidColor')){
      this.invalid_primaryDark=true
    }else{
      this.invalid_primaryDark=false
    }

    //-----secondary
    if(this.lightColorsForm.controls['secondary'].hasError('invalidColor')){
      this.invalid_secondary=true
    }else{
      this.invalid_secondary=false
    }
    if(this.darkColorsForm.controls['secondary'].hasError('invalidColor')){
      this.invalid_secondaryDark=true
    }else{
      this.invalid_secondaryDark=false
    }
  }


  sameDarkAsLightDefaultValues(darkColors){
    let allAsDefaultLight = true
    if(darkColors){
      const properties = ['accent', 'background', 'title', 'subtitle', 'primary', 'secondary'];
      properties.forEach(property => {
        if (darkColors[property] != this.defaultColorsValues[property]) // darkColors[property] === this.defaultColorsValuesHex[property] ||
          allAsDefaultLight = false
        if(darkColors[property] && darkColors[property] != this.defaultColorsValues[property] 
            && darkColors[property].toLowerCase().includes("rgb")){
          const clear1= darkColors[property].split("(")
          const clear2 = clear1[1].split(")")
          const values = clear2[0].split(",")
          if(values.length==3){
            const hex = this.RGBToHex(darkColors[property])
            darkColors[property] = hex
          }
          
          if(values.length==4){
            const hex2 = this.RGBAtohex(darkColors[property])
            darkColors[property] = hex2
          }
        }
      });
    }

    return [allAsDefaultLight]
    
  }

  deleteDefaultColors(){
    const lightColors = this.passEditForm.value.theme.lightColors;
    const darkColors = this.passEditForm.value.theme.darkColors;
    const properties = ['accent', 'background', 'title', 'subtitle', 'primary', 'secondary'];

    properties.forEach(property => {
    if (lightColors[property] === this.defaultColorsValues[property]) delete lightColors[property];
    if (darkColors[property] === this.defaultDarkColorsValues[property]) delete darkColors[property];
    });

    if (!Object.keys(lightColors).length) this.passEditForm.removeControl('theme.lightColors');
    if (!Object.keys(darkColors).length) this.passEditForm.removeControl('theme.darkColors');
 
  }

  deleteDefaultColorsLight(){
    const lightColors = this.passEditForm.value.theme.lightColors;
    const properties = ['accent', 'background', 'title', 'subtitle', 'primary', 'secondary'];
    properties.forEach(property => {
      const value = deepCopy(lightColors[property])
      if (lightColors[property] === this.defaultColorsValues[property]) delete lightColors[property];
      if (lightColors[property] === this.defaultColorsValuesHex[property]) delete lightColors[property];

      //change color back to default
      if(this.oldData['theme'] && this.oldData['theme']['lightColors'] && this.oldData['theme']['lightColors'][property]){
        if(this.oldData['theme']['lightColors'][property] != this.defaultColorsValuesHex[property]){
          lightColors[property] = value

          if(lightColors[property] == this.defaultColorsValues[property]){
            lightColors[property] = this.defaultColorsValuesHex[property]
          }
        }
      }
      if(lightColors[property] && lightColors[property] != this.defaultColorsValues[property] 
          && lightColors[property].toLowerCase().includes("rgb")){
        const clear1= lightColors[property].split("(")
        const clear2 = clear1[1].split(")")
        const values = clear2[0].split(",")
        if(values.length==3){
          const hex = this.RGBToHex(lightColors[property])
          lightColors[property] = hex
        }
        
        if(values.length==4){
          const hex2 = this.RGBAtohex(lightColors[property])
          lightColors[property] = hex2
        }
      }
    });

    // if (!Object.keys(this.passEditForm.value.theme.lightColors).length) delete this.passEditForm.value.theme.lightColors;
    if (!Object.keys(this.passEditForm.value.theme.lightColors).length && this.passData.theme && this.passData.theme.lightColors && Object.keys(this.passData.theme.lightColors).length==0) delete this.passEditForm.value.theme.lightColors;

  }

  deleteDefaultColorsDark(){
    const darkColors = this.passEditForm.value.theme.darkColors;
    const properties = ['accent', 'background', 'title', 'subtitle', 'primary', 'secondary'];

    properties.forEach(property => {
      if(darkColors[property]){        
        const value = deepCopy(darkColors[property])
        if (darkColors[property] === this.defaultDarkColorsValues[property]) delete darkColors[property];
        if (darkColors[property] === this.defaultDarkColorsValuesHex[property]) delete darkColors[property];

        //change color back to default
        if(this.oldData['theme'] && this.oldData['theme']['darkColors'] && this.oldData['theme']['darkColors'][property]){
          if(this.oldData['theme']['darkColors'][property] != this.defaultDarkColorsValuesHex[property]){
            darkColors[property] = value

            if(darkColors[property] == this.defaultDarkColorsValues[property]){
              darkColors[property] = this.defaultDarkColorsValuesHex[property]
            }
          }
        }

        if( darkColors[property] && darkColors[property] != this.defaultDarkColorsValues[property] && darkColors[property].toLowerCase().includes("rgb")){
          const clear1= darkColors[property].split("(")
          const clear2 = clear1[1].split(")")
          const values = clear2[0].split(",")

          if(values.length==3){
            const hex = this.RGBToHex(darkColors[property])
            darkColors[property] = hex
          }
          
          if(values.length==4){
            const hex2 = this.RGBAtohex(darkColors[property])
            darkColors[property] = hex2
          }
        }
      }
      
    });

    // if (!Object.keys(this.passEditForm.value.theme.darkColors).length) delete this.passEditForm.value.theme.darkColors;
    if (!Object.keys(this.passEditForm.value.theme.darkColors).length && this.passData.theme && this.passData.theme.darkColors && Object.keys(this.passData.theme.darkColors).length==0) delete this.passEditForm.value.theme.darkColors;

  }

  resetLightColors(){
    const lightColors = { ...deepCopy(this.defaultColorsValuesHex) };
    // Check if contextCdData and its lightColors exist
    if (this.contextCdData?.lightColors) {
      //console.log(this.contextCdData.lightColors);
      const cdLightColors = this.contextCdData.lightColors;
      const properties = ['accent', 'background', 'title', 'subtitle', 'primary', 'secondary'];
      properties.forEach(property => {
        // Use contextCdData value if it exists, otherwise use default
        lightColors[property] = cdLightColors[property] ?? lightColors[property];
      });
    }
    this.lightColorsForm.patchValue(lightColors);
  }

  resetDarkColors(){
    const darkColors = { ...deepCopy(this.defaultDarkColorsValuesHex) };
    if (this.contextCdData?.darkColors) {
      const cdDarkColors = this.contextCdData.darkColors;
      const properties = ['accent', 'background', 'title', 'subtitle', 'primary', 'secondary'];
      properties.forEach(property => {
        darkColors[property] = cdDarkColors[property] ?? darkColors[property];
      });
    }
    this.darkColorsForm.patchValue(darkColors);
  }

  RGBToHex(rgb) {
    let values
    // Choose correct separator
    if(rgb.toLowerCase().includes('rgba')){
      let sep = rgb.indexOf(",") > -1 ? "," : " ";
      // Turn "rgba(r,g,b)" into [r,g,b]
      values = rgb.substr(5).split(")")[0].split(sep);
    }else{
      if(rgb.toLowerCase().includes('rgb')){
        let sep = rgb.indexOf(",") > -1 ? "," : " ";
        // Turn "rgb(r,g,b)" into [r,g,b]
        values = rgb.substr(4).split(")")[0].split(sep);
      }
    }

    let r = (+values[0]).toString(16),
        g = (+values[1]).toString(16),
        b = (+values[2]).toString(16);
  
    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;
  
    return "#" + r + g + b;
  }
  
  RGBAtohex(color){
    let hex
    if(color.toLowerCase().includes('rgba')){
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
      if(rgba){
        hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1) + Math.round(rgba[3] * 255).toString(16)}`;
      }
    }else{
     if(color.toLowerCase().includes('rgb')){
      const rgb = color.replace(/^rgb?\(|\s+|\)$/g, '').split(',');
      if(rgb){
        hex = `#${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1) + Math.round(rgb[3] * 255).toString(16)}`;
      }
     }
    }
    
    return hex
  }

  onCancelEdit(){
    this.dialogRef.close([false,this.oldData])
  }

  onSaveEdit(){
    const fonts = removeUndefined(this.fontsForm.value)
    const lightColors = removeUndefined(this.lightColorsForm.value)
    const darkColors = removeUndefined(this.darkColorsForm.value)

    this.themeEditForm.patchValue({fonts, lightColors, darkColors})
    const theme = removeUndefined( this.themeEditForm.value)

    this.passEditForm.patchValue({theme: theme})
    
    // this.deleteDefaultColors()
    this.deleteDefaultColorsLight()
    this.deleteDefaultColorsDark()
    if (this.passEditForm.value.theme && this.passEditForm.value.theme.fonts && !Object.keys(this.passEditForm.value.theme.fonts).length && this.oldData.theme && this.oldData.theme.fonts && !Object.keys(this.oldData.theme.fonts).length) delete this.passEditForm.value.theme.fonts;
    // if (this.passEditForm.value.theme && this.passEditForm.value.theme.darkColors && !Object.keys(this.passEditForm.value.theme.darkColors).length && this.oldData.theme && this.oldData.theme.darkColors && !Object.keys(this.oldData.theme.darkColors).length) delete this.passEditForm.value.theme.darkColors;
    // if (this.passEditForm.value.theme && this.passEditForm.value.theme.lightColors && !Object.keys(this.passEditForm.value.theme.lightColors).length && this.oldData.theme && this.oldData.theme.lightColors && !Object.keys(this.oldData.theme.lightColors).length) delete this.passEditForm.value.theme.lightColors;
    // if (!Object.keys(this.passEditForm.value.theme.fonts).length) delete this.passEditForm.value.theme.fonts;

    const newFonts = this.uploadService.getFonts()
    //console.log(this.passEditForm.value)
    this.dialogRef.close([true,this.passEditForm.value])
  }



  triggerFontFileInput() {
    const fileInput = document.getElementById('myfile-pick-font') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      const fileType = event.target.files[0].type
      try{
        this.showSpinner = true;
        const newfont = this.uploadService.uploadFont(event.target.files[0], this.passData.id, this.contextId)
        //console.log(newfont)
        setTimeout(async () => {
          this.loadFonts()
        }, 200);
        this.showSpinner = false
      }catch(error){
        this.showSpinner = false
        const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED")
        console.log(error)
      }
    }
  }


  async fontsDropped(files: FileHandle[]): Promise<void> {
    this.files = files;
    const fileToUpload = files[0].file;
  
    if (fileToUpload) {
      this.drop = true;
      try {
        this.showSpinner = true
        const newfont = await this.uploadService.uploadFont(fileToUpload, this.passData.id, this.contextId);
        //console.log(newfont)
        setTimeout(async () => {
          this.loadFonts()
        }, 200);
        this.showSpinner = false
      } catch (error) {
        this.showSpinner = false
        const message = this.translate.instant("SnackBarConstants.UPLOAD_FONT_FAILED");
        console.error('Font upload failed', error);
      }
    }
  }

  /* 

  async fontsDropped(files: FileHandle[],type): Promise<void> {
    this.files = files;
    const fileToUpload = files[0].file
    
    const newUrl = files[0].url
    if(newUrl){
      this.drop=true

      try{
        this.commentsService.progressSpin.emit(true)
        const newfont = this.uploadService.uploadFont(fileToUpload, type, this.passData.id, this.contextId)
        setTimeout(async () => {
          const newUpfonts = this.uploadService.getFonts()
          const opentype = require('opentype.js');
          const buffer = fileToUpload.arrayBuffer();
          // if running in async context:
          const font = opentype.parse(await buffer);
          // if not running in async context:
          buffer.then(data => {
              const font = opentype.parse(data);
          })
          const fullName = font.names.fullName.en
          const newdata = {
            name:fullName,
            url: newfont.url
          }
          this.fontsForm.patchValue({[type]:newdata})
          this.commentsService.progressSpin.emit(false)
        }, 200);
      }catch(error){
        this.commentsService.progressSpin.emit(false)
        console.log(error)
      }
    }
  } */

  async deleteFont(font) {
    const filePath = font.url.replace('pass://', `passes/${this.passData.id}/`);
    try {
      this.showSpinner = true;
      await this.storageService.deleteFont(filePath); // Delete
      this.loadFonts(); // Refresh
    } catch (error) {
      console.error('Error deleting font', error);
      const message = this.translate.instant("SnackBarConstants.DELETE_FONT_FAILED");
      this.commentsService.addSnackBar.emit(message);
    } finally {
      this.showSpinner = false;
    }
  }


  editFont(type){
    switch(type){
      case 'title': {
        document.getElementById('myfile-title').click()
        break;
      }

      case 'subtitle': {
        document.getElementById('myfile-subtitle').click()
        break;
      }

      case 'plain': {
        document.getElementById('myfile-plain').click()
        break;
      }

      case 'bold': {
        document.getElementById('myfile-bold').click()
        break;
      }

      case 'italic': {
        document.getElementById('myfile-italic').click()
        break;
      }
    } 
  }

  addLanguageToPass(lang){
    if(this.passEditForm.value.languages){
      if(this.passEditForm.value.languages.length!=0){
        if(!this.passEditForm.value.languages.includes(lang)){
          const langform = this.passEditForm.value.languages
          langform.push(lang)
          this.languagesPass = langform
          this.passEditForm.patchValue({languages: langform})
        }
      }else{

        let languages = []
        languages.push(lang)
        this.languagesPass = languages
        this.passEditForm.patchValue({languages: languages})
      }
    }else{
      let languages = []
      languages.push(lang)
      this.languagesPass = languages
      this.passEditForm.patchValue({languages: languages})
    }
    
  }

  deleteLanguageOfPass(lang, index){
    const bdelete = this.languagesPass
    bdelete.splice(index,1)
    this.passEditForm.patchValue({languages: bdelete})
  }

  languagesIsAdded(lang){
    let exists = false
    if(this.passEditForm.value.languages){
      if(this.passEditForm.value.languages.includes(lang)){
        exists=true
      }
    }
    return exists
  }
  

  dropLanguage(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.languagesPass, event.previousIndex, event.currentIndex);
    this.passEditForm.patchValue({languages: this.languagesPass})

  }

  changeDarkAsLight(){
    this.templateService.changeDarkAsLight()
  }

  equalDarkColorsAsLightColors(){
    const lightColors = deepCopy(this.lightColorsForm.value)
    const properties = ['accent', 'background', 'title', 'subtitle', 'primary', 'secondary'];
    properties.forEach(property => {
      if(lightColors[property] && lightColors[property].toLowerCase().includes("rgb")){
        const clear1= lightColors[property].split("(")
        const clear2 = clear1[1].split(")")
        const values = clear2[0].split(",")

        if(values.length==3){
          const hex = this.RGBToHex(lightColors[property])
          lightColors[property] = hex
        }
        
        if(values.length==4){
          const hex2 = this.RGBAtohex(lightColors[property])
          lightColors[property] = hex2
        }
        // this.darkColorsForm.patchValue({[property] :  lightColors[property]})

      }else{
        // this.darkColorsForm.patchValue({[property] :  lightColors[property]})
      }
    });
      this.darkColorsForm.patchValue( lightColors)
  }
}
