import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { CommentsService } from '../service/comments.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { browserLocalPersistence, getAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register-confirm-email-dialog',
  templateUrl: './register-confirm-email-dialog.component.html',
  styleUrls: ['./register-confirm-email-dialog.component.css']
})
export class RegisterConfirmEmailDialogComponent {
  emailForm!: FormGroup
  linkAuthentication:any
  private auth:any

  constructor(private fb: FormBuilder,
    private fireauth: AngularFireAuth, 
    public dialogRef: MatDialogRef<RegisterConfirmEmailDialogComponent>,
    private authService: AuthService,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private commentsService: CommentsService,
    private translate:TranslateService
    ){
      dialogRef.disableClose = true;
    
  }


  ngOnInit():void{
    this.linkAuthentication = this.data.values
    this.emailForm = this.fb.group({
      email:[,Validators.required]
    })

    this.emailForm.valueChanges.subscribe(
      (value:any) => {
      }
    )
  }

  cancelEmailConfirmation(){
    this.dialogRef.close()
  }

  sendEmailConfirmation(){
    if(this.emailForm.value.email){
      const emailConfirmation= this.emailForm.value.email
      const emailLink = this.linkAuthentication

        this.auth = getAuth();
            
            this.commentsService.progressSpin.emit(true)
            if (this.fireauth.isSignInWithEmailLink(emailLink)) {
    
              var email = emailConfirmation
              if (!email) {
                email = window.prompt('Please provide your email for confirmation');
                this.commentsService.progressSpin.emit(false)
              }

                 this.auth.setPersistence(browserLocalPersistence)
                  .then(() => {
            
                    return this.fireauth.signInWithEmailLink(email, window.location.href)
                        .then((result) => {
                          window.localStorage.removeItem('emailForSignIn');
                          this.dialogRef.close([true, result])
                          this.router.navigate(
                                  ['setPassword', result.user.uid],
                                  { queryParams: { setPassword: 'active' } }
                                );
            
                          this.commentsService.progressSpin.emit(false)
                          return result
            
                        })
                        .catch((error) => {
                          console.log(error)
                          const message = this.translate.instant("SnackBarConstants.REGISTER_FAILED")
                          this.commentsService.addSnackBar.emit(message)
                          this.commentsService.progressSpin.emit(false)
                          const object = { data:"loged user"}
                          return object
            
                        });
                    })
                    .catch((error) => {
                      const message = this.translate.instant("SnackBarConstants.LOGIN_FAILED")
                      this.commentsService.addSnackBar.emit(message)
                      console.error('Error enabling local persistence:', error);
                      this.commentsService.progressSpin.emit(false)
                    });
            }
    }
  }
  
}
