import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateUserDialogComponent } from '../create-user-dialog/create-user-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  usersData: any
  usersUserRights_create:any

  constructor(private settingsService: SettingsService,
    private dialog: MatDialog){
  }

  ngOnInit(): void {
    if(this.settingsService.usersWithContextId$ && this.settingsService.usersWithContextId$.length!=0){
      this.usersData= this.settingsService.usersWithContextId$
    }else{
      this.usersData=this.settingsService.observeUsersWithcontextId(this.settingsService.contextId$)      
    }

    this.usersUserRights_create = this.settingsService.userRolesRights$['users']['C']
  }

  openDialogCreateUser(){

    let dialogRef= this.dialog.open(CreateUserDialogComponent, {

    });

    dialogRef.afterClosed().subscribe(async (result:any) => {
    });
  }
  

}
