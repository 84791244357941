<h2 mat-dialog-title>{{'set-password' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >

            <form [formGroup]="passwordFrom"  class="pe-1">
                <!-- <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'register.password' | translate}}</mat-label>
                    <input formControlName="password" type="password" matInput placeholder="{{'register.password' | translate}}">
                    <mat-hint>{{'register.info' | translate}}</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'register.password' | translate}}</mat-label>
                    <input formControlName="confirmPassword" type="password" matInput placeholder="{{'register.password' | translate}}">
                    <mat-hint>{{'register.info' | translate}}</mat-hint>
                </mat-form-field> -->
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'login.pass' | translate}}</mat-label>
                    <input matInput formControlName="password" placeholder="{{'login.pass' | translate}}" [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <!-- <mat-hint>{{'setPassword.info' | translate}}</mat-hint> -->
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'setPassword.confirmed_password' | translate}}</mat-label>
                    <input matInput formControlName="confirmPassword" placeholder="{{'login.pass' | translate}}" [type]="hide ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-hint>{{'setPassword.password_equals' | translate}}</mat-hint>
                </mat-form-field>
        
            </form> 

        </section>
    </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-button class="orange-button" 
    (click)="onSubmit();">{{'save' | translate}}</button>
</mat-dialog-actions>