<section>
<!-- <button *ngIf="editMode" class="edit-button-top" (click)="openDialogEdit()" >
        <span  class="material-symbols-outlined" >
            edit_square
        </span>
    </button> -->

    <div *ngIf="annotatedMapData.title" id="title" class="titleMap text-break text-wrap ">{{annotatedMapData.title}}</div>
    <div *ngIf="annotatedMapData.subtitle" id="subtitle" class="subtitleMap text-break text-wrap ">{{annotatedMapData.subtitle}}</div>

    <div *ngIf="annotatedMapData.layout && annotatedMapData.layout!='horizontal'">

        <!-- <div *ngIf="!newAnnotations" [app-map-marker]="[annotatedMapData,true]"></div>
        <div *ngIf="newAnnotations" [app-map-marker]="[annotatedMapData,true]"></div> -->
        <div [app-map-advanced-markers]="[annotatedMapData,mapWidgetId,widgetNumber]"></div>
        <!-- <div [app-location-picker]="[annotatedMapData,true,widgetNumber]"></div> -->
    </div>

    <div *ngIf="annotatedMapData.layout && annotatedMapData.layout=='horizontal'">

        <div class="d-flex align-items-center">
            <div class="p-2 prev rounded" style="padding: 10px;" (click)="previousMap(-1)"> &#10094;</div>

                <div *ngFor="let annotations of staticAnnotations; let index = index" class="myMaps-{{mapWidgetId}}" id="{{annotations.id}}" style="width:90%;">
                    <!-- <div [app-map-marker-horizontal]="[annotatedMapData,annotations, index]" ></div> -->
                    <div [app-map-advanced-markers-horizontal]="[annotatedMapData,annotations, index]" ></div>
                </div>

            <div class="p-2 next rounded" style="padding: 10px;" (click)="nextMap(1)">&#10095;</div>
        </div>

        <div style="text-align:center">
            <span class="dot" *ngFor="let annotations of staticAnnotations; let index = index" id="dot-{{annotations.id}}" (click)="showMapId(annotations.id)"></span>
        </div>
    </div>

</section>