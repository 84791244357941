
    <div class="row mt-4">

        <div class="col-12">
            <mat-card class="p-2">
                <!-- <mat-icon>filter_list</mat-icon> -->
                <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-start flex-wrap" *ngIf="availableFilters"> <!-- | async as filters-->
                        <div *ngFor="let filter of reportTemplate.filters ; let index=index"  >
                            <div *ngIf="filter.key">
                                
                                <button mat-button class="m-1 filter-button" [matMenuTriggerFor]="menu" #trigger="matMenuTrigger" [class]="{'inactive': !filterActive(filter.key)}" (menuOpened)="closeOtherMenus(trigger)">
                                    {{filter.displayName}} <mat-icon matSuffix>arrow_drop_down</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="p-3" [hasBackdrop]="false">
                
                                    <div class="mb-4">
                                        {{filter.displayName}}<br>
                                        <small class="text-muted">{{filter.description}}</small>
                                    </div>
                
                
                                    <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                        
                                        <form [formGroup]="filterForm">
                        
                                            <mat-form-field appearance="outline" class="w-100" *ngIf="filter.type!='boolean' && filter.type!='date' ">
                                                <mat-label>{{filter.displayName}}</mat-label>
                                                <!-- <input matInput placeholder="{{filter.displayName}}" formControlName="{{filter.key}}" type="{{filterTypeInput[filter.type]}}"> -->
                                                <input matInput *ngIf="filter.type=='string' " type="text" formControlName="{{filter.key}}">
                                                <input matInput *ngIf="filter.type=='media' " type="text" formControlName="{{filter.key}}">
                                                <input matInput *ngIf="filter.type=='number' " type="number" formControlName="{{filter.key}}">
                                                <input matInput *ngIf="filter.type=='decimal' " type="number" formControlName="{{filter.key}}" step="0.1">
                                                <!-- <input matInput *ngIf="filter.type=='date' " type="date" formControlName="{{filter.key}}"> -->
                                                <input matInput *ngIf="filter.type=='time' " type="time" formControlName="{{filter.key}}">
                                                <input matInput *ngIf="filter.type=='dateTime' " type="datetime-local" formControlName="{{filter.key}}" >
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" *ngIf="filter.type=='date'" class="mb-2"> 
                                                <mat-label> {{filter.displayName}}</mat-label>
                                                <input matInput #input [matDatepicker]="picker" formControlName="{{filter.key}}" placeholder="{{'dd.mm.yyyy' | translate}}">
                                                <mat-hint>{{'dd.mm.yyyy' | translate}}</mat-hint>
                                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker ></mat-datepicker>
                                            </mat-form-field>
                        
                                            <div class="form-control p-3 mb-3  d-flex justify-content-between" *ngIf="filter.type=='boolean' ">
                                                <label for="{{filter.key}}" >{{filter.displayName}} ({{filter.operator}})</label>
                                                <mat-slide-toggle  class="ms-5 example-margin" formControlName="{{filter.key}}" ></mat-slide-toggle>  
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <button mat-button (click)="clearFilterValue(filter.key, filter);trigger.closeMenu();" style="color:var(--accentGlobal);"> {{'reports.clear_filter'| translate}}</button>
                                                <button mat-button (click)="activeFilterValue(filter.key, filter);trigger.closeMenu();" class="orange-button" [disabled]="filterForm.value[filter.key]==undefined"> {{'apply' | translate}}</button>    
                                            </div>
                                        
                                        </form>
                        
                                    </div>
                        
                                </mat-menu>
                
                            </div>
                        </div>
                    </div>
                    <div>
                        <button mat-button class="m-1" (click)="resetResultsReportOpen();" style="color: var(--accentGlobal);">
                        <mat-icon>refresh</mat-icon> 
                            {{'filters.reset_results' | translate}}
                        </button>
                    </div>
                </div>
            </mat-card>
        </div>

    </div>