<h2 mat-dialog-title>{{'location' | translate}}</h2>

<!-- <div style="position:absolute;top:8px;right:10px;">
    <button mat-icon-button style="color:var(--warmOrange); " (click)="onLanguageEditor();" >
        <span class="material-symbols-outlined">
            language
        </span>
    </button>
</div> -->

<mat-dialog-content>
    <div >
        <!-- <google-map height="250px"
                        width="100%"
                        [center]="center"
                        [options]="{disableDefaultUI: true}"
                        [zoom]="zoom">
                    <map-marker *ngFor="let markerPosition of markerPositions"
                                [position]="markerPosition"
                                [options]="markerOptions"
                                [label]="markerPosition['badge']"></map-marker>

                            <ng-container *ngFor="let markerPosition of markerPositions; let i=index">
                                <div *ngIf="markerPosition['type'] && markerPosition['type']=='point'">
                                    <map-marker #marker="mapMarker"  
                                            [position]="markerPosition"
                                            [options]="markerOptions" 
                                            [label]="markerPosition['badge']" 
                                            (mapClick)="openInfoWindow(marker, infoWindow, markerPosition)">
                                    </map-marker>
                                    
                                    
                                    <map-info-window #infoWindow="mapInfoWindow"> 
                                        <div class="d-flex flex-column">
                                            <p *ngIf="markerPosition['title']" class="text-wrap text-break">{{ markerPosition['title']}}</p>
                                            <span *ngIf="markerPosition['subtitle']" class="text-wrap text-break">{{ markerPosition['subtitle'] }} </span>
                                            <a *ngIf="markerPosition['linkURL'] && linkURL" href="{{linkURL}}" target="_blank" style="color:var(--warmOrange);">
                                                <span class="material-symbols-outlined">
                                                    link
                                                </span>
                                            </a>
                                            <a *ngIf="markerPosition['directions']" href="{{openMapDirection(markerPosition)}}" target="_blank" class="mt-1">
                                                <button mat-button class="secondary-gray-button w-100">
                                                    <mat-icon>open_in_new</mat-icon>{{'pass.map.open_direction' | translate}}
                                                </button>
                                            </a>
                                            <span *ngIf="!markerPosition['title'] && !markerPosition['linkURL'] && !markerPosition['subtitle']">...</span>
                                        </div>
                                    </map-info-window>
                                </div>

                                <div *ngIf="markerPosition['type'] && markerPosition['type']=='line'">
                                    <map-polyline [options]="actualPolygonOptions">
                                    </map-polyline>
                                </div>
            
                                <div *ngIf="markerPosition['type'] && markerPosition['type']=='polygon'">
                                    <ng-container>
                                        <map-polygon #polygon [options]="actualPolygonOptions" >
                                        </map-polygon>
                                    </ng-container>
                                </div>

                                <div *ngIf="!markerPosition['type']">
                                    <map-marker 
                                        [position]="markerPosition"
                                        [options]="markerOptions"
                                        [label]="markerPosition['badge']">
                                    </map-marker>
                                </div>
                            </ng-container>
                        </google-map> -->

        <div id="map-annotation-dialog-{{annotationId}}" class="map"></div>

    </div>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
<!--        <label class="m-2">{{'pass.map.ANNOTATIONS' | translate}}</label> -->
            <form [formGroup]="newAnnotationForm">

                <div *ngIf="newAnnotationForm.value.type && newAnnotationForm.value.type=='point'">
                    <div class="text-muted pb-3">{{'pass.map.coordinate' | translate}}</div>

                    <div class="d-flex flex-row">
                        <div class="pe-2 flex-grow-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.map.latitude' | translate}}</mat-label>
                                <input matInput type="number" id="newLat" formControlName="lat" placeholder="{{'pass.map.latitude' | translate}}" >
                            </mat-form-field>
                        </div>
                        <div class="ps-2 flex-grow-1">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.map.longitude' | translate}}</mat-label>
                                <input matInput type="number" id="newLng" formControlName="lng"  placeholder="{{'pass.map.longitude' | translate}}">
                            </mat-form-field> 
                        </div>
                    </div>
                </div>

                <div *ngIf="newAnnotationForm.value.type && newAnnotationForm.value.type=='polygon'" class="pb-4">
                    <span class="text-muted text-uppercase">{{'polygon' | translate}}</span>
                    <div class="list-group">
                        <div class="list-group-item  p-2 ps-3 pe-3">
                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <span >{{'points' | translate}}</span>
                                <span class="text-muted" >{{actualPolygonOptions.paths.length}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="newAnnotationForm.value.type && newAnnotationForm.value.type=='line'" class="pb-4">
                    <span class="text-muted text-uppercase">{{'line' | translate}}</span>
                    <div class="list-group">
                        <div class="list-group-item  p-2 ps-3 pe-3">
                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <span >{{'points' | translate}}</span>
                                <span class="text-muted" >{{actualPolygonOptions.path.length}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <mat-form-field appearance="outline" class="w-100 mt-1">
                    <mat-label>{{'pass.map.title' | translate}}</mat-label>
                    <input matInput type="text" id="titleMap" formControlName="title" placeholder="{{'pass.title' | translate}}">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.map.subtitle' | translate}}</mat-label>
                    <input matInput type="text" id="subtitleMap" formControlName="subtitle" placeholder="{{'pass.subtitle' | translate}}">
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.map.badge' | translate}}</mat-label>
                    <input matInput type="text" id="bagdeMap" formControlName="badge" placeholder="{{'pass.map.badge' | translate}}">
                </mat-form-field>

                <div class="d-flex flex-row">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'pass.linkURL' | translate}}</mat-label>
                        <input matInput type="url" id="linkURLMap" formControlName="linkURL" placeholder="{{'pass.linkURL' | translate}}">
                        <mat-error>
                        {{'url_pattern_invalid' | translate}}  
                        </mat-error>
                        <mat-hint>
                            {{'url_pattern_detailed' | translate}}  
                        </mat-hint>
                    </mat-form-field>
                    <button mat-icon-button (click)="fileInput.click()" matTooltip="{{'pick' | translate}}">
                        <span class="material-symbols-outlined">folder_open</span>
                    </button>
                      <input type="file" id="myfile" name="myfile" style="display: none;" accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .txt, .csv, .vcf, .rtf, .webp" (change)="readDocument($event)" #fileInput>
                </div>

                <div class="form-control my-3 d-flex justify-content-between w-100 p-3">
                    <label for="value">{{'pass.map.show_directions' | translate}}</label>
                    <mat-slide-toggle class="ms-5 example-margin" formControlName="directions" ></mat-slide-toggle>  
                </div>

                <div>
                    <div class="text-muted text-uppercase py-3">{{'style' | translate}}</div>

                    <div class="list-group" *ngIf="newAnnotationForm.value.type && newAnnotationForm.value.type=='point'">
                        <div class="list-group-item p-2 ps-3 pe-3">
                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <span class=" w-50 bg-white" >{{'pinBackgroundColor' | translate}}</span>
                                <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="pinBackgroundColor" style="margin-left:10px;"></ngx-colors>
                                    <span *ngIf="invalid_pinBackgroundColor" id="invalid_pinBackgroundColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                </label>
                            </div>
                        </div>

                        <div class="list-group-item p-2 ps-3 pe-3">
                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <span class=" w-50 bg-white" >{{'pinForegroundColor' | translate}}</span>
                                <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="pinForegroundColor" style="margin-left:10px;"></ngx-colors>
                                    <span *ngIf="invalid_pinForegroundColor" id="invalid_pinForegroundColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                </label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="newAnnotationForm.value.type && newAnnotationForm.value.type=='polygon'">
                        <div class="list-group pb-3" >
                            <div class="list-group-item p-2 ps-3 pe-3">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <span class=" w-50 bg-white" >{{'lineColor' | translate}}</span>---strokeColor
                                    <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="strokeColor" style="margin-left:10px;"></ngx-colors>
                                        <span *ngIf="invalid_strokeColor" id="invalid_strokeColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                    </label>
                                </div>
                            </div>

                            <div class="list-group-item p-2 ps-3 pe-3">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <span class=" w-50 bg-white" >{{'fillColor' | translate}}</span>---fillColorColor
                                    <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="fillColor" style="margin-left:10px;"></ngx-colors>
                                        <span *ngIf="invalid_fillColor" id="invalid_fillColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                    </label>
                                </div>
                            </div>
                        </div>

                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'lineWidth' | translate}}</mat-label>
                            <input matInput type="number" formControlName="lineWidth" min="0"  placeholder="{{'lineWidth' | translate}}">
                        </mat-form-field> 
                    </div>

                    <div *ngIf="newAnnotationForm.value.type && newAnnotationForm.value.type=='line'">
                        <div class="list-group pb-3" >
                            <div class="list-group-item p-2 ps-3 pe-3">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <span class=" w-50 bg-white" >{{'lineColor' | translate}}</span>---strokeColor                           
                                    <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                        <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'"  formControlName="strokeColor" style="margin-left:10px;"></ngx-colors>
                                        <span *ngIf="invalid_strokeColor" id="invalid_strokeColor" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                    </label>
                                </div>
                            </div>
                        </div>

                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'lineWidth' | translate}}</mat-label>
                            <input matInput type="number" formControlName="lineWidth" min="0"  placeholder="{{'lineWidth' | translate}}">
                        </mat-form-field> 
                    </div>
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" [mat-dialog-close]="[false]" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="onSaveEdit();"  >{{'save' | translate}}</button>
</mat-dialog-actions>