<section>
<!--     <button *ngIf="editMode" class="edit-button-top" (click)="openDialogEdit()">
        <span class="material-symbols-outlined">
        edit_square
        </span>
    </button> -->

    <span *ngIf="dynamic" class="material-symbols-outlined" style="right: 15px; margin-top: 10px; z-index:100; position: absolute; filter: drop-shadow(2px 4px 6px black); color:white;">
        finance_chip
    </span>

<!-- twoHorizontal -->
<div *ngIf="layout=='twoHorizontal'" class="d-flex flex-row flex-nowrap justify-content-around align-items-center w-100 p-1">
    <div *ngFor="let im of images; let index=index" style="width:46%;position:relative; display:inline-block; text-align: center;">
        <a *ngIf="im.linkURL && linkURL[index]" href="{{linkURL[index]}}" target="_blank">
            <img  *ngIf="!url[index]" src="{{im.url}}" class="" alt="..." style=" align-items: center; width:100%; min-height:150px;border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            <img  *ngIf="url[index]" src="{{url[index]}}" class="" alt="..." style=" align-items: center; width:100%; border-radius: var(--cornerRadius); min-height:150px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
        </a>
        <a *ngIf="!im.linkURL && !linkURL[index]" >
            <img  *ngIf="!url[index]" src="{{im.url}}" class="" alt="..." style=" align-items: center; width:100%; min-height:150px;border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            <img  *ngIf="url[index]" src="{{url[index]}}" class="" alt="..." style=" align-items: center; width:100%; border-radius: var(--cornerRadius); min-height:150px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
        </a>
        <span *ngIf="im.title" id="plain" class="text-break text-truncate" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;
        filter: drop-shadow(2px 4px 6px black); width:90%;">{{im.title}}</span>
    </div>
    <div *ngIf="images.length==0">
        <img src='./../../assets/img/default.jpg' class="" alt="..." style=" align-items: center; width:100%; min-height:150px;border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
    
        <img src='./../../assets/img/default.jpg' class="" alt="..." style=" align-items: center; width:100%; min-height:150px;border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
    </div>
</div>

<!-- twoVertical -->
<div *ngIf="layout=='twoVertical'" class="d-flex flex-column flex-nowrap  align-stretch p-1">
    <div *ngFor="let im of images; let index=index;" style="position:relative; display:inline-block; text-align: center;">
        <a *ngIf="im.linkURL && linkURL[index]" href="{{linkURL[index]}}" target="_blank">
            <img *ngIf="url[index]" src="{{url[index]}}" class="" alt="..." style="width:98%; height: 180px; border-radius: var(--cornerRadius);  margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            <img *ngIf="!url[index]" src="{{im.url}}" class="" alt="..." style="width:98%; height: 180px; border-radius: var(--cornerRadius);  margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
        </a>
        <a *ngIf="!im.linkURL && !linkURL[index]">
            <img *ngIf="url[index]" src="{{url[index]}}" class="" alt="..." style="width:98%; height: 180px; border-radius: var(--cornerRadius);  margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            <img *ngIf="!url[index]" src="{{im.url}}" class="" alt="..." style="width:98%; height: 180px; border-radius: var(--cornerRadius);  margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
        </a>

        <span *ngIf="im.title" id="plain" class="text-break " style=" width:90%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{im.title}}</span>   
    </div>    
    <div *ngIf="images.length==0">
        <img src='./../../assets/img/default.jpg' alt="..." style="width:98%; height: 180px; border-radius: var(--cornerRadius);  margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
        <img  src='./../../assets/img/default.jpg' alt="..." style="width:98%; height: 180px; border-radius: var(--cornerRadius);  margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
    </div>
</div>

<!-- threeLayout2 -->
    <section *ngIf="layout=='threeLayout2'" class="d-flex flex-row flex-nowrap justify-space-around p-1">
        <div  class= "d-flex flex-column" style="width:49%;">
            <!-- image[0] --><!-- !urlData: url!= pass:// -->
            <div *ngIf="images[0].title && !url[0]" style="position:relative; display:inline-block; text-align: center;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" target="_blank">
                    <img *ngIf="images[0]" src="{{images[0].url}}" class="w-100 " alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;"
                    onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="images[0] && !linkURL[0]" src="{{images[0].url}}" class="w-100 " alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;"
                    onerror="this.src='./../../assets/img/default.jpg'"/>
                <span id="plain" class="text-break text-truncate" style="width:90%; position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[0].title}}</span>
            </div>
            <a *ngIf="linkURL[0] && !images[0].title && !url[0]" href="{{linkURL[0]}}" target="_blank">
                <img *ngIf="images[0] && !images[0].title && !url[0] " src="{{images[0].url}}" class="w-100 " alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            </a>
            <img *ngIf="images[0] && !images[0].title && !url[0] && !linkURL[0]" src="{{images[0].url}}" class="w-100 " alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>

                <!-- image[0] --><!-- url: url== pass:// -->
                <div *ngIf="images[0].title && url[0]" style="position:relative; display:inline-block; text-align: center;">
                    <a *ngIf="linkURL[0]" href="{{linkURL[0]}}" target="_blank">
                        <img *ngIf="images[0]" src="{{url[0]}}" class="w-100 " alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;"
                        onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    <img *ngIf="images[0] && !linkURL[0]" src="{{url[0]}}" class="w-100 " alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;"
                        onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[0].title}}</span>
                </div>
                <img *ngIf="images[0] && !images[0].title && url[0] && !linkURL[0]" src="{{url[0]}}" class="w-100 " alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                <a *ngIf="linkURL[0] && !images[0].title && url[0]" href="{{linkURL[0]}}" target="_blank">
                    <img *ngIf="images[0] && !images[0].title && url[0]" src="{{url[0]}}" class="w-100 " alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>                        

            <!-- images[2] --> <!-- url : url== pass://-->
            <div *ngIf="images[2].title && !url[2]" style="position:relative; display:inline-block; text-align: center;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                    <img *ngIf="images[2]" src="{{images[2].url}}"  class="w-100 "  alt="..." style="height: 170px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="images[2] && !linkURL[2]" src="{{images[2].url}}"  class="w-100 "  alt="..." style="height: 170px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                <span id="plain" class="text-break text-truncate " style="width:90%; position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[2].title}}</span>
            </div>     
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && !url[2]" href="{{linkURL[2]}}" target="_blank">
                <img *ngIf="images[2] && !images[2].title && !url[2] " src="{{images[2].url}}"  class="w-100 "  alt="..." style="height: 170px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            </a>
            <img *ngIf="images[2] && !images[2].title && !url[2] && !linkURL[2]" src="{{images[2].url}}"  class="w-100 "  alt="..." style="height: 170px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
        
                <!-- images[2] --> <!-- url : url== pass://-->
                <div *ngIf="images[2].title && url[2]" style="position:relative; display:inline-block; text-align: center;">
                    <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                        <img *ngIf="images[2]" src="{{url[2]}}"  class="w-100 "  alt="..." style="height: 170px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    
                    <img *ngIf="images[2] && !linkURL[2]" src="{{url[2]}}"  class="w-100 "  alt="..." style="height: 170px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span id="plain" class="text-break text-truncate" style="width:90%;position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[2].title}}</span>
                </div>        
                    <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && url[2]" href="{{linkURL[2]}}" target="_blank">
                        <img *ngIf="images[2] && !images[2].title && url[2] " src="{{url[2]}}"  class="w-100 "  alt="..." style="height: 170px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                <img *ngIf="images[2] && !images[2].title && url[2] && !linkURL[2]" src="{{url[2]}}"  class="w-100 "  alt="..." style="height: 170px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            
        
        </div>
        <!-- image[1] --><!---url: url!=pass:// -->
        <div *ngIf="images[1].title && !url[1]" style="width:45%;position:relative; display:inline-block; text-align: center;">
            <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" target="_blank">
                <img *ngIf="images[1]" src="{{images[1].url}}"  class="w-100 "  alt="..." style="width:47%; min-height:96%;border-radius: var(--cornerRadius); margin:5px;  margin-left:15px;  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            </a>
            <img *ngIf="images[1] && !linkURL[1]" src="{{images[1].url}}"  class="w-100 "  alt="..." style="width:47%; min-height:96%;border-radius: var(--cornerRadius); margin:5px;  margin-left:15px;  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            <span id="plain" class="text-break text-truncate" style="width:90%;     margin-left: 15px;position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[1].title}}</span>
        </div> 
        <a *ngIf="images[1].linkURL && linkURL[1] && !url[1] && !images[1].title" href="{{linkURL[1]}}" target="_blank">
            <img *ngIf="images[1] && !images[1].title && !url[1]" src="{{images[1].url}}"class="" alt="..." style=" width:47%; min-height:96%;border-radius: var(--cornerRadius); margin:5px;  margin-left:15px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
        </a>
        <img *ngIf="images[1] && !images[1].title && !url[1] && !linkURL[1]" src="{{images[1].url}}"class="" alt="..." style=" width:47%; min-height:96%; border-radius: var(--cornerRadius); margin:5px;  margin-left:15px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
    
                <!-- image[1] --><!---url: url==pass:// -->
                <div *ngIf="images[1].title && url[1]" style="width:45%;position:relative; display:inline-block; text-align: center; max-width: 190px;">
                    <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" target="_blank">
                        <img *ngIf="images[1]" src="{{url[1]}}"  class="w-100 "  alt="..." style="width:47%; min-height:96%;border-radius: var(--cornerRadius); margin:5px;  margin-left:15px;  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    <img *ngIf="images[1] && !linkURL[1]" src="{{url[1]}}"  class="w-100 "  alt="..." style="width:47%; min-height:96%;border-radius: var(--cornerRadius); margin:5px;  margin-left:15px;  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span id="plain" class="text-break text-truncate" style="    margin-left: 15px;width:90%;position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[1].title}}</span>
                </div> 
                <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && url[1]" href="{{linkURL[1]}}" target="_blank" style="max-width: 190px;width: 49%;">
                    <img *ngIf="images[1] && !images[1].title && url[1]" src="{{url[1]}}"class="" alt="..." style=" width:95%; min-height:96%;border-radius: var(--cornerRadius); margin:5px;  margin-left:15px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>

                <img *ngIf="images[1] && !images[1].title && url[1] && !linkURL[1]" src="{{url[1]}}"class="" alt="..." style=" width:47%; min-height:96%;border-radius: var(--cornerRadius); margin:5px;  margin-left:15px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                <img *ngIf="!images[1] " src='./../../assets/img/default.jpg'alt="..." style=" width:47%; border-radius: var(--cornerRadius); margin:5px;  margin-left:15px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
        
    </section>

<!-- threeLayout1 -->
    <section *ngIf="layout=='threeLayout1'" class="d-flex flex-row flex-nowrap justify-space-around ">
        <!-- image[0] --> <!---url : url!=pass://-->
        <div *ngIf="images[0].title && !url[0]" style="width:48%;position:relative; display:inline-block; text-align: center;">
            <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" target="_blank">
                <img src="{{images[0].url}}" class="" alt="..." style=" width:95%; height:96%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
            </a>

            <img *ngIf="!linkURL[0]" src="{{images[0].url}}" class="" alt="..." style=" width:95%; height:96%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
            <span id="plain" class="text-break text-truncate" style=" width:90%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[0].title}}</span>
        </div>
        <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && !url[0]" href="{{linkURL[0]}}" target="_blank" style="max-width: 190px;width: 49%;">
            <img *ngIf="!images[0].title && !url[0]" src="{{images[0].url}}" class="" alt="..." style=" width:95%; height:96%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
        </a>
        <img *ngIf="!images[0].title && !url[0] && !linkURL[0]" src="{{images[0].url}}" class="" alt="..." style=" width:47%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                  
            <!-- image[0] --> <!---url : url==pass://-->
            <div *ngIf="images[0].title && url[0]" style="width:48%;position:relative; display:inline-block; text-align: center;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" target="_blank">
                    <img src="{{url[0]}}" class="" alt="..." style=" width:95%; height:96%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                </a>
                <img *ngIf="!linkURL[0]" src="{{url[0]}}" class="" alt="..." style=" width:95%; height:96%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                <span id="plain" class="text-break text-truncate" style=" width:90%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[0].title}}</span>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && url[0]" href="{{linkURL[0]}}" target="_blank" style="max-width: 190px;width: 49%;">
                <img *ngIf="!images[0].title && url[0]" src="{{url[0]}}" class="" alt="..." style=" width:95%; height: 96%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />

            </a>
            <img *ngIf="!images[0].title && url[0] && !linkURL[0]" src="{{url[0]}}" class="" alt="..." style=" width:47%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
    

        <div class=" d-flex flex-column" style="width:48%; max-width: 190px;">
            <!-- image[1] --><!--url: url!=pas::// -->
            <div *ngIf="images[1].title && !url[1]" style="position:relative; display:inline-block; text-align: center;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" target="_blank">
                    <img  src="{{images[1].url}}" class="w-100 " alt="..."  style=" height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>

                </a>
                <img *ngIf="!linkURL[1]" src="{{images[1].url}}" class="w-100 " alt="..."  style=" height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                <span id="plain" class="text-break text-truncate" style=" width:90%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[1].title}}</span>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && !url[1]" href="{{linkURL[1]}}" target="_blank">
                <img *ngIf="!images[1].title && !url[1]" src="{{images[1].url}}" class="w-100 "  alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px;object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
            </a>
            <img *ngIf="!images[1].title && !url[1] && !linkURL[1]" src="{{images[1].url}}" class="w-100 "  alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px;object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>

                <!-- image[1] --><!--url: url==pas::// -->
                <div *ngIf="images[1].title && url[1]" style="position:relative; display:inline-block; text-align: center;">
                    <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" target="_blank">
                        <img  src="{{url[1]}}" class="w-100 " alt="..."  style=" height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    <img *ngIf="!linkURL[1]" src="{{url[1]}}" class="w-100 " alt="..."  style=" height: 80px; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span id="plain" class="text-break text-truncate" style=" width:90%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[1].title}}</span>
                </div>
                <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && url[1]" href="{{linkURL[1]}}" target="_blank">
                    <img *ngIf="!images[1].title && url[1]" src="{{url[1]}}" class="w-100 "  alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px;object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!images[1].title && url[1] && !linkURL[1]" src="{{url[1]}}" class="w-100 "  alt="..." style="height: 80px; border-radius: var(--cornerRadius); margin:5px;object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>

            <!-- image[2] --><!--url: url!= pass://-->
            <div *ngIf="images[2].title && !url[2]" style="position:relative; display:inline-block; text-align: center;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                    <img  src="{{images[2].url}}" class="w-100 " alt="..." style=" border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                </a>
                <img *ngIf="!linkURL[2]" src="{{images[2].url}}" class="w-100 " alt="..." style=" border-radius: var(--cornerRadius);margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                <span id="plain" class="text-break text-truncate" style=" width:90%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[2].title}}</span>
            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && !url[2]" href="{{linkURL[2]}}" target="_blank">
                <img *ngIf="!images[2].title && !url[2]" src="{{images[2].url}}" class="w-100 " alt="..." style="height: 170px; border-radius: var(--cornerRadius);margin:5px;object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
            </a>
            <img *ngIf="!images[2].title && !url[2] && !linkURL[2]" src="{{images[2].url}}" class="w-100 " alt="..." style="height: 170px; border-radius: var(--cornerRadius);margin:5px;object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
            
                <!-- image[2] --><!--url: url== pass://-->
                <div *ngIf="images[2].title && url[2]" style="position:relative; display:inline-block; text-align: center;">
                    <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                        <img  src="{{url[2]}}" class="w-100 " alt="..." style=" border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                    </a>
                    <img *ngIf="!linkURL[2]" src="{{url[2]}}" class="w-100 " alt="..." style="border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                    <span id="plain" class="text-break text-truncate" style=" width:90%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[2].title}}</span>
                </div>
                <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && url[2]" href="{{linkURL[2]}}" target="_blank">
                    <img *ngIf="!images[2].title && url[2]" src="{{url[2]}}" class="w-100 " alt="..." style="height: 170px; border-radius: var(--cornerRadius);margin:5px;object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!images[2].title && url[2] && !linkURL[2]" src="{{url[2]}}" class="w-100 " alt="..." style="height: 170px; border-radius: var(--cornerRadius);margin:5px;object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
            
                
        </div>
    </section>

<!-- fourLayout1 -->
    <section *ngIf="layout=='fourLayout1'" class="d-flex flex-row flex-nowrap justify-space-around">
        <div class=" d-flex flex-column justify-content-center ms-1 me-1" style="width: 48%;">
            <!-- images[0] --><!---url : url!= pass:// -->
            <div *ngIf="images[0].title && !url[0]" style="position:relative; display:inline-block; text-align: center;height:30%;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" target="_blank">
                    <img src="{{images[0].url}}"  class="" alt="..." style="width:95%; height: 95%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!linkURL[0]" src="{{images[0].url}}"  class="" alt="..." style="width:95%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                <span id="plain" class="text-break text-truncate" style="width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[0].title}}</span>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && !url[0]" href="{{linkURL[0]}}" target="_blank" style="width: 95%;height: 30%;">
                <img *ngIf="!images[0].title && !url[0]" src="{{images[0].url}}"  class="" alt="..." style="width:100%; height: 90%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            </a>
            <img *ngIf="!images[0].title && !url[0] && !linkURL[0]" src="{{images[0].url}}"  class="" alt="..." style="width:95%; height: 30%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            
                <!-- images[0] --><!---url : url== pass:// -->
                <div *ngIf="images[0].title && url[0]" style="position:relative; display:inline-block; text-align: center;height:30%;">
                    <a *ngIf="images[0].linkURL && linkURL[0] " href="{{linkURL[0]}}" target="_blank" >
                        <img src="{{url[0]}}"  class="" alt="..." style="width:95%; height: 95%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    <img *ngIf="!linkURL[0]" src="{{url[0]}}"  class="" alt="..." style="width:95%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span id="plain" class="text-break text-truncate" style="width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[0].title}}</span>
                </div>
                <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && url[0]" href="{{linkURL[0]}}" target="_blank" style="width: 95%;height: 30%;">
                    <img *ngIf="!images[0].title && url[0]" src="{{url[0]}}"  class="" alt="..." style="width: 100%;height: 90%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!images[0].title && url[0] && !linkURL[0]" src="{{url[0]}}"  class="" alt="..." style="width:95%; height: 30%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>

            <!-- images[2] --><!---url: url!= pass:://-->
            <div *ngIf="images[2].title && !url[2]" style="position:relative; display:inline-block; text-align: center;height:50%;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                    <img src="{{images[2].url}}" class="" alt="..." style="width:95%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!linkURL[2]" src="{{images[2].url}}" class="" alt="..." style="width:95%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[2].title}}</span>
            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && !url[2]" href="{{linkURL[2]}}" target="_blank" style="width:100%; height: 50%;">
                <img *ngIf="!images[2].title && !url[2]" src="{{images[2].url}}" class="" alt="..." style="width: 95%;height: 100%; border-radius: var(--cornerRadius); margin:5px;  margin-top: 0px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
            </a>
            <img *ngIf="!images[2].title && !url[2] && !linkURL[2]" src="{{images[2].url}}" class="" alt="..." style="width:95%; height:50%; border-radius: var(--cornerRadius); margin:5px;  margin-top: 0px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
        
                <!-- images[2] --><!---url: url== pass:://-->
                <div *ngIf="images[2].title && url[2] " style="position:relative; display:inline-block; text-align: center;height:50%; ">
                    <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                        <img src="{{url[2]}}" class="" alt="..." style="width:95%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    <img *ngIf="!linkURL[2]" src="{{url[2]}}" class="" alt="..." style="width:95%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[2].title}}</span>
                </div>
                <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && url[2]" href="{{linkURL[2]}}" target="_blank" style="width:100%; height: 50%;">
                    <img *ngIf="!images[2].title && url[2]" src="{{url[2]}}" class="" alt="..." style="width: 95%;height: 100%; border-radius: var(--cornerRadius); margin:5px; margin-top: 0px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!images[2].title && url[2] && !linkURL[2]" src="{{url[2]}}" class="" alt="..." style="width:95%; height: 50%; border-radius: var(--cornerRadius); margin:5px; margin-top: 0px; object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
        </div>  
       
        <div  class=" d-flex flex-column justify-content-center" style="width: 48%;">
            <!-- images[1] --><!--url: url!= pass:://-->
            <div *ngIf="images[1].title && !url[1]" style="position:relative; display:inline-block; text-align: center;height:50%; margin-bottom:5px;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" target="_blank">
                    <img src="{{images[1].url}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!linkURL[1]" src="{{images[1].url}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[1].title}}</span>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && !url[1]" href="{{linkURL[1]}}" target="_blank" style="height:50%; width: 100%;">
                <img *ngIf="!images[1].title && !url[1]" src="{{images[1].url}}" class="w-100 "  alt="..." style="height: 97%; border-radius: var(--cornerRadius);   margin:5px;margin-left:0px;margin-top:0px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
            </a>
            <img *ngIf="!images[1].title && !url[1] && !linkURL[1]" src="{{images[1].url}}" class="w-100 "  alt="..." style=" width: 95%;  height: 50%; border-radius: var(--cornerRadius);   margin:5px; margin-left:0px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
            
                <!-- images[1] --><!--url: url== pass:://-->
                <div *ngIf="images[1].title && url[1]" style="position:relative; display:inline-block; text-align: center;height:50%; margin-bottom:5px;">
                    <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" target="_blank">
                        <img src="{{url[1]}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    <img *ngIf="!linkURL[1]" src="{{url[1]}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span sid="plain" class="text-break text-truncate" style="  width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[1].title}}</span>
                </div>
                <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && url[1]" href="{{linkURL[1]}}" target="_blank" style="height:50%; width: 100%;">
                    <img *ngIf="!images[1].title && url[1]" src="{{url[1]}}" class="w-100 "  alt="..." style=" height: 97%; border-radius: var(--cornerRadius);   margin:5px;margin-left:0px;margin-top:0px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                </a>
                <img *ngIf="!images[1].title && url[1] && !linkURL[1]" src="{{url[1]}}" class="w-100 "  alt="..." style=" width: 95%;  height: 50%; border-radius: var(--cornerRadius);   margin:5px; margin-left:0px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />


            <!-- images[3] --><!--url: url != pass://-->
            <div *ngIf="images[3].title && !url[3]" style="position:relative; display:inline-block; text-align: center;height:30%; ">
                <a *ngIf="images[3].linkURL && linkURL[3]" href="{{linkURL[3]}}" target="_blank">
                    <img src="{{images[3].url}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!linkURL[3]" src="{{images[3].url}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                <span id="plain" class="text-break text-truncate" style="  width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[3].title}}</span>
            </div>
            <a *ngIf="images[3].linkURL && linkURL[3] && !images[3].title && !url[3]" href="{{linkURL[3]}}" target="_blank" style="height:30%;">
                <img *ngIf="!images[3].title && !url[3]" src="{{images[3].url}}" class="w-100" alt="..." style=" width: 95%; height: 30%; border-radius: var(--cornerRadius);/* margin:5px; */object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
            </a>
            <img *ngIf="!images[3].title && !url[3] && !linkURL[3]" src="{{images[3].url}}" class="w-100" alt="..." style=" width: 95%; height: 30%; border-radius: var(--cornerRadius);/* margin:5px; */object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
        
                <!-- images[3] --><!--url: url == pass://-->
                <div *ngIf="images[3].title && url[3]" style="position:relative; display:inline-block; text-align: center;height:30%; ">
                    <a *ngIf="images[3].linkURL && linkURL[3]" href="{{linkURL[3]}}" target="_blank">
                        <img src="{{url[3]}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    <img *ngIf="!linkURL[3]" src="{{url[3]}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;"onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);color: white;filter: drop-shadow(2px 4px 6px black);">{{images[3].title}}</span>
                </div>
                <a *ngIf="images[3].linkURL && linkURL[3] && !images[3].title && url[3]" href="{{linkURL[3]}}" target="_blank" style="height:30%;">
                    <img *ngIf="!images[3].title && url[3]" src="{{url[3]}}" class="w-100" alt="..." style=" width: 95%; height: 100%; border-radius: var(--cornerRadius);/* margin:5px; */object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                </a>
                <img *ngIf="!images[3].title && url[3] && !linkURL[3]" src="{{url[3]}}" class="w-100" alt="..." style=" width: 95%; height: 30%; border-radius: var(--cornerRadius);/* margin:5px; */object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
        </div>
    </section>

<!-- fourLayout2 -->
    <section *ngIf="layout=='fourLayout2'" class="d-flex flex-row flex-nowrap justify-space-around">
        <div class="w-50 d-flex flex-column justify-content-center">
            <!-- images[0] --> <!--uel : url!=pass::/-->
            <div *ngIf="images[0].title && !url[0]" style="position:relative; display:inline-block; text-align: center; height:45%; width:95%;margin-bottom:10px; margin-left:8px;">
                <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" target="_blank">
                    <img  src="{{images[0].url}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/> 
                </a>
                <img *ngIf="!linkURL[0]" src="{{images[0].url}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/> 
                <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[0].title}}</span>
            </div>
            <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && !url[0]" href="{{linkURL[0]}}" target="_blank" style="height:45%; width:95%;margin-bottom:10px; margin-left:8px;">
                <img *ngIf="!images[0].title && !url[0]" src="{{images[0].url}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            </a>
            <img *ngIf="!images[0].title && !url[0] && !linkURL[0]" src="{{images[0].url}}" class="" alt="..." style="width:95%; height: 45%; border-radius: var(--cornerRadius); margin-bottom:10px; margin-left:8px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
            
                <!-- images[0] --> <!--uel : url==pass::/-->
                <div *ngIf="images[0].title && url[0]" style="position:relative; display:inline-block; text-align: center; height:45%; width:95%;margin-bottom:10px; margin-left:8px;">
                    <a *ngIf="images[0].linkURL && linkURL[0]" href="{{linkURL[0]}}" target="_blank">
                        <img src="{{url[0]}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/> 
                    </a>
                    <img *ngIf="!linkURL[0]" src="{{url[0]}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/> 
                    <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[0].title}}</span>
                </div>
                <a *ngIf="images[0].linkURL && linkURL[0] && !images[0].title && url[0]" href="{{linkURL[0]}}" target="_blank" style="height:45%; width:95%;margin-bottom:10px; margin-left:8px;">
                    <img *ngIf="!images[0].title && url[0]" src="{{url[0]}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!images[0].title && url[0] && !linkURL[0]" src="{{url[0]}}" class="" alt="..." style="width:95%; height: 45%; border-radius: var(--cornerRadius); margin-bottom:10px; margin-left:8px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>

            <!-- images[2] --><!--url: url != pass:://-->
            <div *ngIf="images[2].title && !url[2]" style="position:relative; display:inline-block; text-align: center; height:25%; width: 95%;margin-left:8px;">
                <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                    <img  src="{{images[2].url}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!linkURL[2]" src="{{images[2].url}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[2].title}}</span>
            </div>
            <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && !url[2]" href="{{linkURL[2]}}" target="_blank" style="height: 25%; width: 95%; margin-left:8px;">
                <img *ngIf="!images[2].title && !url[2]" src="{{images[2].url}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius);object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
            </a>
            <img *ngIf="!images[2].title && !url[2] && !linkURL[2]" src="{{images[2].url}}" class="" alt="..." style="width:95%; height: 25%; border-radius: var(--cornerRadius); margin-left:8px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />

                <!-- images[2] --><!--url: url == pass:://-->
                <div *ngIf="images[2].title && url[2]" style="position:relative; display:inline-block; text-align: center; height:25%; width: 95%;margin-left:8px;">
                    <a *ngIf="images[2].linkURL && linkURL[2]" href="{{linkURL[2]}}" target="_blank">
                        <img  src="{{url[2]}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    <img *ngIf="!linkURL[2]" src="{{url[2]}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[2].title}}</span>
                </div>
                <a *ngIf="images[2].linkURL && linkURL[2] && !images[2].title && url[2]" href="{{linkURL[2]}}" target="_blank" style="height: 25%; width: 95%; margin-left:8px;">
                    <img *ngIf="!images[2].title && url[2]" src="{{url[2]}}" class="" alt="..." style="width:100%; height: 100%; border-radius: var(--cornerRadius);object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                </a>
                <img *ngIf="!images[2].title && url[2] && !linkURL[2]" src="{{url[2]}}" class="" alt="..." style="width:95%; height: 25%; border-radius: var(--cornerRadius); margin-left:8px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
            
        </div>

        <div  class=" w-50 d-flex flex-column justify-content-center">
            <!-- images[1] --><!--url: url!= pass://-->
            <div *ngIf="images[1].title && !url[1]" style="position:relative; display:inline-block; text-align: center; height:20%; width:95%;margin-left:0px; margin:5px;">
                <a *ngIf="images[1].linkURL && linkURL[1]" href="{{linkURL[1]}}" target="_blank">
                    <img  src="{{images[1].url}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!linkURL[1]" src="{{images[1].url}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[1].title}}</span>
            </div>
            <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && !url[1]" href="{{linkURL[1]}}" target="_blank" style="width: 95%;height: 20%; margin: 5px;">
                <img src="{{images[1].url}}" class="w-100 " alt="..." style="height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
            </a>
            <img *ngIf="!images[1].title && !url[1] && !linkURL[1]" src="{{images[1].url}}" class="w-100 " alt="..." style="max-width: 95%; height: 20%; border-radius: var(--cornerRadius); margin-left:0px; margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
            
                <!-- images[1] --><!--url: url== pass://-->
                <div *ngIf="images[1].title && url[1]" style="position:relative; display:inline-block; text-align: center; height:20%; width:95%; margin:5px;">
                    <a *ngIf="images[1].linkURL && linkURL[1] " href="{{linkURL[1]}}" target="_blank">
                        <img  src="{{url[1]}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    <img *ngIf="!linkURL[1]" src="{{url[1]}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[1].title}}</span>
                </div>
                <a *ngIf="images[1].linkURL && linkURL[1] && !images[1].title && url[1]" href="{{linkURL[1]}}" target="_blank" style="width: 95%;height: 20%; margin: 5px;">
                    <img *ngIf="!images[1].title && url[1]" src="{{url[1]}}" class="w-100 " alt="..." style=" height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
                </a>
                <img *ngIf="!images[1].title && url[1] && !linkURL[1]" src="{{url[1]}}" class="w-100 " alt="..." style="max-width: 95%; height: 20%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />

            <!-- images[3] --><!--url: url!=pass:://-->
            <div *ngIf="images[3].title && !url[3]" style="position:relative; display:inline-block; text-align: center; height: 50%; border-radius: var(--cornerRadius); margin:5px;">
                <a *ngIf="images[3].linkURL && linkURL[3]" href="{{linkURL[3]}}" target="_blank">
                    <img  src="{{images[3].url}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                </a>
                <img *ngIf="!linkURL[3]" src="{{images[3].url}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[3].title}}</span>
            </div>
            <a *ngIf="images[3].linkURL && linkURL[3] && !images[3].title && !url[3]" href="{{linkURL[3]}}" target="_blank" style="width: 100%;height: 50%;margin: 5px;">
                <img *ngIf="!images[3].title && !url[3]" src="{{images[3].url}}" class="w-100 " alt="..." style="max-width: 95%;height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
            </a>
            <img *ngIf="!images[3].title && !url[3] && !linkURL[3]" src="{{images[3].url}}" class="w-100 " alt="..." style=" max-width: 95%; height: 50%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
        
                <!-- images[3] --><!--url: url==pass:://-->
                <div *ngIf="images[3].title && url[3]" style="position:relative; display:inline-block; text-align: center; height: 50%; border-radius: var(--cornerRadius); margin:5px;">
                    <a *ngIf="images[3].linkURL && linkURL[3]" href="{{linkURL[3]}}" target="_blank">
                        <img src="{{url[3]}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    </a>
                    <img *ngIf="!linkURL[3]" src="{{url[3]}}" class="w-100 " alt="..."  style=" height: 100%; border-radius: var(--cornerRadius);  object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'"/>
                    <span id="plain" class="text-break text-truncate" style=" width:90%;position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: white;filter: drop-shadow(2px 4px 6px black);">{{images[3].title}}</span>
                </div>
                <a *ngIf="images[3].linkURL && linkURL[3] && !images[3].title && url[3]" href="{{linkURL[3]}}" target="_blank" style="width: 100%;height: 50%;margin: 5px;">
                    <img *ngIf="!images[3].title && url[3] " src="{{url[3]}}" class="w-100 " alt="..." style="max-width: 95%;height: 100%; border-radius: var(--cornerRadius); object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />  
                </a>
                <img *ngIf="!images[3].title && url[3] && !linkURL[3]" src="{{url[3]}}" class="w-100 " alt="..." style=" max-width: 95%; height: 50%; border-radius: var(--cornerRadius); margin:5px; object-fit: cover;" onerror="this.src='./../../assets/img/default.jpg'" />
        </div>
    </section>
</section>
