
<section class="dialog p-3" *ngIf="messageConfirmation">
    <div class="d-flex flex-row-reverse align-items-end">
        <button mat-icon-button style="color:var(--warmOrange);" (click)="closeInfoMessage();"  >
            <span class="material-symbols-outlined">
                close
            </span>
        </button>
    </div>
    <div class="p-2 pb-4 mb-3 d-flex flex-column justify-content-center align-items-center">
        <span class="p-1 text-wrap"> {{message_mail}} </span>

        <span class="p-1 fw-bolder fs-5 text-wrap text-break">{{email}}</span>

        <span class="p-1 text-wrap"> {{message_mail_2}}</span>
    </div>

</section>


<section class="dialog p-3" *ngIf="setMailToResetPassword">
    <div class="d-flex flex-row align-items-start">
        <button mat-button style="color:var(--warmOrange);" (click)="cancelResetPassword();"  >
            {{'cancel' | translate}}
        </button>
    </div>
    <div class="p-2  d-flex flex-column justify-content-center align-items-center">
        <p>{{'enter_mail_to_reset_password' | translate}}</p>
        <form [formGroup]="resetForm">

            <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'login.email' | translate}}</mat-label>
                <input matInput type="email" class="form-control" formControlName="email" placeholder="{{'login.email' | translate}}">
            </mat-form-field>

            <button mat-button class="w-100" style="background-color:var(--warmOrange);color:var(--pureWhite);" (click)="sendMailToResetPassword();"  >
                {{'resetPassword' | translate}}
            </button>
        </form>
    </div>
</section>