import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { deepCopy, openMapDirection, removeUndefined, removeUndefinedValuesFromObject, supportUrlStructure } from '../../../../../backend/utils/object';
import { TranslateService } from '@ngx-translate/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { validColorValidator } from 'ngx-colors';
import { LanguageEditorDialogComponent } from 'src/app/language-editor-dialog/language-editor-dialog.component';
import { UploadService } from 'src/app/service/upload.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-annotated-map-annotation-translation-dialog',
  templateUrl: './annotated-map-annotation-translation-dialog.component.html',
  styleUrls: ['./annotated-map-annotation-translation-dialog.component.css']
})
export class AnnotatedMapAnnotationTranslationDialogComponent {

  annotationData:any
  newAnnotationtranslationsForm!:FormGroup
  colorPalette:any  =[]
  center: google.maps.LatLngLiteral = {lat: 44, lng: 12};
  zoom = 4;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  actualPolygonOptions:any 

  invalid_pinColor = false
  invalid_lineColor = false
  invalid_fillColor = false
  invalid_strokeColor = false
  linkURL:any
  reg = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  translationForm!:FormGroup
  passTranslations:any
  passLanguagesList:any
  annotatedMapEditData:any
  annotationColumns:any
  oldTranslations:any
  linkUrlTransform:any
  newDocument:any
  passId:any
  widgetNumber:any
  annotationId:any
  prev_infowindow:any
  selectedLanguage:string
  widgetId:string
  OLdannotationData:any
  defaultLanguageWidgetData:any
  annotationIndex:any

  constructor(public dialogRef: MatDialogRef<AnnotatedMapAnnotationTranslationDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private commentService: CommentsService,
              private translate:TranslateService,
              private uploadService: UploadService){
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.selectedLanguage = this.data.selectedLanguage
    this.widgetId = this.data.widgetId

    this.annotationIndex = this.data.index
    if(this.data.encodedContent)
      this.actualPolygonOptions = this.data.encodedContent

    this.passId = this.data.passId
    this.widgetNumber= this.data.widgetNumber

    if(this.data.columns)
      this.annotationColumns = this.data.columns

    if(this.data.annotationMapData)
      this.annotatedMapEditData = deepCopy(this.data.annotationMapData)

    if(this.data.languages)
    this.passLanguagesList = this.data.languages

    if(this.data.translations){
      this.passTranslations = deepCopy(this.data.translations)
      this.oldTranslations = deepCopy(this.data.translations)
    }

    this.defaultLanguageWidgetData = this.data.defaultLanguageWidgetData

    this.newAnnotationtranslationsForm = this.fb.group({
      name:[{value:undefined, disabled:true}],
      lat:[{value:undefined, disabled:true}, Validators.required],
      lng:[{value:undefined, disabled:true}, Validators.required],
      directions:[{value:undefined, disabled:true}],
      badge:[{value:undefined, disabled:true}],
      coordinate:[{value:undefined, disabled:true}],
      id:[{value:undefined, disabled:true}],
      subtitle:[{value:undefined, disabled:false}],
      title:[{value:undefined, disabled:false}],
      linkURL:[{value:undefined, disabled:false},Validators.pattern(this.reg)],
      encodedContent:  [{value:undefined, disabled:true}],
        fillColor : [{value:undefined, disabled:true}],
        lineCap: [{value:undefined, disabled:true}],
        lineWidth: [{value:undefined, disabled:true}],
        strokeColor:  [{value:undefined, disabled:true}],
        type: [{value:undefined, disabled:true}] ,
        pinColor:[{value:undefined, disabled:true}, validColorValidator()],
        lineColor:[{value:undefined, disabled:true}, validColorValidator()],
    })

    this.newAnnotationtranslationsForm.get('linkURL').valueChanges.subscribe(value => {
      this.supportLinkUrl(value)
      let url;
  
      try {
        url = new URL(value)
        if(url.protocol === "http:" || url.protocol === "https:"){
          this.newAnnotationtranslationsForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.reg)]);
        }
        if(url.protocol === "pass:" ){
          this.newAnnotationtranslationsForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegexDoc)]);
        }
      } catch (error) {}
    })

    if(this.data.values){
      this.annotationData=deepCopy(this.data.values)
      this.OLdannotationData=deepCopy(this.data.values)
      if(this.annotationData.id)
        this.annotationId = this.annotationData.id

      const {name, lat, lng, badge, coordinate, id,encodedContent,directions } = this.annotationData
      const title = this.data.valuesTranslated[this.selectedLanguage +'.'+this.widgetId+ '_'+ id +'.title']
      const subtitle = this.data.valuesTranslated[this.selectedLanguage +'.'+this.widgetId+ '_'+ id +'.subtitle']
      const linkURL = this.data.valuesTranslated[this.selectedLanguage +'.'+this.widgetId + '_'+ id +'.linkURL']

      let {strokeColor,fillColor, lineCap, lineWidth, type, pinColor, lineColor } = this.annotationData
      
      if(!type)
        type='point'

      if(this.actualPolygonOptions){
        if(!strokeColor)
        strokeColor = this.actualPolygonOptions.strokeColor

        if(!fillColor)
        fillColor = this.actualPolygonOptions.fillColor

        if(!lineWidth)
        lineWidth = this.actualPolygonOptions.lineWidth
      }
      this.supportLinkUrl(linkURL)
      
      this.newAnnotationtranslationsForm.patchValue({
        name:name ? name : undefined,
        lat:lat ? lat : undefined,
        lng:lng ? lng : undefined,
  
        badge:badge ? badge : undefined,
        coordinate:coordinate ? coordinate : undefined,
        id:id ? id : undefined,
        subtitle:subtitle ? subtitle : undefined,
        title:title ? title :  undefined,
        linkURL: linkURL ? linkURL : undefined,

        directions: directions ? directions : undefined,
        encodedContent: encodedContent ? encodedContent : undefined,
        fillColor: fillColor ? fillColor : undefined,
        lineCap: lineCap ? lineCap : undefined,
        lineWidth: lineWidth ? lineWidth : undefined,
        strokeColor: strokeColor ? strokeColor : undefined,
        type: type ? type : undefined,

        pinColor: pinColor ? pinColor: undefined,
        lineColor: lineColor ? lineColor : undefined
      })
      this.loadMarkers()
    }

    this.newAnnotationtranslationsForm.valueChanges.subscribe( (value:any) => {
      this.loadMarkersAfterChanges()
    })

    this.translationForm = this.fb.group({translations:[]})
    this.translationForm.patchValue({
      translations: this.passTranslations ? this.passTranslations : undefined
    })
  }


  readDocument(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB size limit
  
      // Define allowed file types
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/webp',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'text/csv',
        'text/vcard',
        'application/rtf'
      ];
  
      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        const message = this.translate.instant("SnackBarConstants.INVALID_FILE_FORMAT");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      // Validate file size
      if (file.size > maxSizeInBytes) {
        const message = this.translate.instant("SnackBarConstants.FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      // Proceed with reading and uploading the file
      const reader = new FileReader();
      this.newDocument = file;
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        if (e.target) {
          const urlNew = e.target.result as string;
  
          try {
            const uploadURL = await this.uploadService.uploadDocument(this.passId, this.newDocument, this.widgetNumber);
  
            if (uploadURL) {
              this.newAnnotationtranslationsForm.get("linkURL")?.setValidators([Validators.required, Validators.pattern(this.urlRegexDoc)]);
              this.linkUrlTransform = uploadURL;
              this.newAnnotationtranslationsForm.patchValue({ linkURL: this.linkUrlTransform });
              this.supportLinkUrl(uploadURL);
            }
          } catch (error) {
            console.error(error);
            const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
            this.commentService.addSnackBar.emit(message);
          }
        }
      };
    }
  }
  

  onSaveEdit(){
    this.commentService.progressSpin.emit(true)

    let newvalues = deepCopy(this.annotationData)
    let {title, subtitle, linkURL} = this.newAnnotationtranslationsForm.value

    newvalues['title'] = title ? title : this.defaultLanguageWidgetData.annotations[this.annotationIndex]['title']
    newvalues['subtitle'] = subtitle ? subtitle : this.defaultLanguageWidgetData.annotations[this.annotationIndex]['subtitle']
    newvalues['linkURL'] = linkURL ? linkURL : this.defaultLanguageWidgetData.annotations[this.annotationIndex]['linkURL']

    let data = this.getWidgetFormatedData(this.newAnnotationtranslationsForm.value)
    this.dialogRef.close([true, data, newvalues])
    this.commentService.progressSpin.emit(false)
  }

  loadMarkers(){
    if(this.annotationData.type){
      if(this.annotationData.type=="point"){
        if(this.annotationData.coordinate){
          const lats = this.annotationData
          lats['lat'] = parseFloat(this.annotationData.coordinate[0])
          lats['lng'] = parseFloat(this.annotationData.coordinate[1])

          if(this.annotationData.name)
            lats['title']=this.annotationData.name
          
          this.markerPositions.push(lats);
          this.center=lats
        }else{
          const lats = this.annotationData
          lats['lat'] = parseFloat(this.annotationData.lat)
          lats['lng'] = parseFloat(this.annotationData.lng)
            
          if(this.annotationData.name)
            lats['title']=this.annotationData.name
          
          this.markerPositions.push(lats);
          this.center=lats
        }
      }
      
      if(this.annotationData.type=="polygon"){
        const newcenter =  Math.round( this.actualPolygonOptions.paths.length / 2)
        const center: google.maps.LatLngLiteral = {lat: this.actualPolygonOptions.paths[newcenter].lat , lng: this.actualPolygonOptions.paths[newcenter].lng}
        this.center = center
        this.zoom = 10
        this.markerPositions.push(this.annotationData)
      }

      if(this.annotationData.type=="line"){
        const newcenter =  Math.round( this.actualPolygonOptions.path.length / 2)
        const center: google.maps.LatLngLiteral = {lat: this.actualPolygonOptions.path[newcenter].lat , lng: this.actualPolygonOptions.path[newcenter].lng}
        this.center = center
        this.zoom = 10
        this.markerPositions.push(this.annotationData)
      }

    }else{
      if(this.annotationData.coordinate){
        const lats = this.annotationData
        lats['lat'] = parseFloat(this.annotationData.coordinate[0])
        lats['lng'] = parseFloat(this.annotationData.coordinate[1])

        if(this.annotationData.name){
          lats['title']=this.annotationData.name
        }
        this.markerPositions.push(lats);
        this.center=lats
      }else{
        const lats = this.annotationData
        lats['lat'] = parseFloat(this.annotationData.lat)
        lats['lng'] = parseFloat(this.annotationData.lng)
          
        if(this.annotationData.name){
          lats['title']=this.annotationData.name
        }
        this.markerPositions.push(lats);
        this.center=lats
      }
    }

  }

  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow, markerPositions) {
    if(this.prev_infowindow)
    this.prev_infowindow.close()

    this.prev_infowindow = infoWindow
    infoWindow.open(marker);
  }

  loadMarkersAfterChanges(){
    this.markerPositions=[]
    if(this.annotationData.type){
      if(this.annotationData.type=="point"){
        if(this.annotationData.lat && this.annotationData.lng){
          const lats = this.annotationData
          lats['title'] = this.newAnnotationtranslationsForm.value.title
          lats['subtitle'] = this.newAnnotationtranslationsForm.value.subtitle
          lats['linkURL'] = this.newAnnotationtranslationsForm.value.linkURL

          lats['lat'] = parseFloat(this.annotationData.lat)
          lats['lng'] = parseFloat(this.annotationData.lng)

          if(this.annotationData.name)
            lats['title']=this.annotationData.name
          
          this.markerPositions.push(lats);
          this.center=lats
        }
      }

      if(this.annotationData.type=="line"){
        const newcenter =  Math.round( this.actualPolygonOptions.path.length / 2)
        const center: google.maps.LatLngLiteral = {lat: this.actualPolygonOptions.path[newcenter].lat , lng: this.actualPolygonOptions.path[newcenter].lng}
        this.center = center
        this.zoom = 10
        this.markerPositions.push(this.annotationData)
        this.actualPolygonOptions.strokeColor = this.annotationData.strokeColor
        this.actualPolygonOptions.strokeWeight = this.annotationData.lineWidth
      }

      if(this.annotationData.type=="polygon"){
        const newcenter =  Math.round( this.actualPolygonOptions.paths.length / 2)
        const center: google.maps.LatLngLiteral = {lat: this.actualPolygonOptions.paths[newcenter].lat , lng: this.actualPolygonOptions.paths[newcenter].lng}
        this.center = center
        this.zoom = 10
        this.markerPositions.push(this.annotationData)
        this.actualPolygonOptions.fillColor = this.annotationData.fillColor
        this.actualPolygonOptions.strokeColor = this.annotationData.strokeColor
        this.actualPolygonOptions.strokeWeight = this.annotationData.lineWidth
      }
    }
  }

  supportLinkUrl(linkurl){
    this.linkURL = linkurl
    if(linkurl){
      let newlink = supportUrlStructure(linkurl, this.passId)
      if(newlink)
      this.linkURL = newlink
      // try{
      // const imgUrl = new URL(linkurl)
      // let hostname = environment.urlHostName
      
      // if(imgUrl.protocol=="pass:" ){
      //   const parts = linkurl.match(/pass:\/\/(.+)\/(.+)/)        
      //   if(parts){
      //     const passTemplateID = parts[1]
      //     const path = parts[2]
      //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
      //     this.linkURL=firebaseUrl
      //   }else{
      //     const newparts = linkurl.match(/pass:\/\/(.+)/)
      //     if(newparts){
      //       let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
      //       newparts.forEach( (part,index)=> {
      //         if(index>0){
      //           firebaseUrl += '/' + part 
      //         }
      //       })
      //       this.linkURL=firebaseUrl
      //     }
      //   }
      // } 

      // if(imgUrl.protocol =="tridy:"){
      //   const parts = linkurl.match(/tridy:\/\/(.+)\/(.+)/)
      //   let path =""
      //   parts.forEach((parte, index) =>{
      //     if(index>0 && index<parts.length){
      //       path += "/" + parte
      //     }
      //   })
      //   const firebaseUrl = `https://${hostname}/v1/pass${path}`

      //   this.linkURL=firebaseUrl
      // }
      // }catch(erro){
      // }
    }
  }

  openMapDirection(markerPosition){
    return openMapDirection(markerPosition)
  }

  getWidgetFormatedData(data){
    let {title, subtitle, linkURL} = data
    return {
      [this.selectedLanguage+'.'+ this.widgetId + '_'+ this.annotationId +'.title']: title ? title : undefined,
      [this.selectedLanguage+'.'+ this.widgetId +'_'+ this.annotationId +'.subtitle']: subtitle ? subtitle : undefined,
      [this.selectedLanguage+'.'+ this.widgetId +'_'+ this.annotationId +'.linkURL']: linkURL ? linkURL : undefined,
    }
    
  }
}
