import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../service/auth.service';
import { RegisterConfirmEmailDialogComponent } from '../register-confirm-email-dialog/register-confirm-email-dialog.component';
import { SettingsService } from '../service/settings.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  routerUrl:any
  idToShow:any
  currentRoute:any

  constructor(private router: ActivatedRoute,
    public dialog: MatDialog,
    private fb : FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private route: Router){
      this.routerUrl=this.router.url
      const currentRoute = window.location.href
      this.currentRoute=window.location.href
      this.idToShow=currentRoute.split('?')
      // this.currentRoute = 'https://account.narravero.dev/register?'+ this.idToShow[1]
  }

  ngOnInit(){
    if(this.currentRoute){

      if(this.idToShow.length>1){
        this.commentService.progressSpin.emit(true)
        this.openEmailConfirmation()
      }else{
        this.route.navigate(['home'])
      }
      

      
    }
  }

  openEmailConfirmation(){
    this.commentService.progressSpin.emit(false)

    let dialogRef= this.dialog.open(RegisterConfirmEmailDialogComponent, {
      data: {
        values: this.currentRoute
      }
    });

    dialogRef.afterClosed().subscribe(async (result:any) => {
    });
  }
}
