import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { ImageCompressionService } from 'src/app/service/image-compression.service';
import { UploadService } from 'src/app/service/upload.service';
import { MediaDetailsDialogComponent } from '../media-details-dialog/media-details-dialog.component';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { LanguageEditorDialogComponent } from 'src/app/language-editor-dialog/language-editor-dialog.component';
import { deepCopy, removeUndefined } from '../../../../../../backend/utils/object';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: 'app-image-edit',
  templateUrl: './image-edit.component.html',
  styleUrls: ['./image-edit.component.css']
})
export class ImageEditComponent implements OnInit{
  imageEditData:any
  oldData:any
  url:any
  changes=false
  imageEditForm!:FormGroup
  noUrl=false
  newImage:any
  widgetNumber:any
  passId:any
  files: FileHandle[] = []
  drop=false
  passTranslations:any
  translationForm!:FormGroup
  urlData:any
  linkUrlTransform:any
  passLanguagesList:any
  urlRegex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  oldTranslations:any
  addUrl = false
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  languagesList = languagesList
  defaultLanguage:string
  
  constructor(public dialogRef: MatDialogRef<ImageEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private uploadService: UploadService,
    private commentService: CommentsService,
    private fb: FormBuilder,
    private imageCompressionService: ImageCompressionService,
    private translate:TranslateService,
    private settingsService: SettingsService){
      dialogRef.disableClose=true
  }

  ngOnInit(): void {
    this.defaultLanguage = this.data.defaultLanguage

    this.imageEditData=this.data.values
    this.url=this.imageEditData.url
    this.oldData=this.data.values
    this.widgetNumber= this.data['widget']
    this.passId = this.data['passId']
    if(this.data['translations']){
      this.passTranslations = this.data['translations']
      this.oldTranslations = deepCopy(this.data['translations'])
    }else{
      this.passTranslations = {}
      this.oldTranslations = {}
    }

    this.passLanguagesList = this.data['languages']

    if(this.data['url']){
      this.urlData = this.data['url']
    }else{
      if(this.imageEditData.url ){
        this.supportUrl(this.imageEditData.url)
      }
    }

    this.imageEditForm = this.fb.group({
      id:[],
      type:[],
      url:[, Validators.required],
      linkURL:[,[Validators.pattern(this.urlRegex)]],
      source:[],
      copyright:[],
      subtitle:[],
      title:[],
      position:[],
      size:[],
      name:[]
    })

    const {id, type, url, linkURL, source, copyright, subtitle, title, position, size, name } = this.imageEditData
    this.imageEditForm.patchValue({
      id: id ? id : undefined , 
      type: type ? type : undefined, 
      url: url ? url : undefined, 
      linkURL: linkURL ? linkURL :'https://', 
      source: source ? source : undefined, 
      copyright: copyright ? copyright : undefined , 
      subtitle: subtitle ? subtitle : undefined, 
      title: title ? title : undefined, 
      position: position ? position : undefined, 
      size: size ? size : undefined,
      name: name ? name : undefined
    })

    this.imageEditForm.valueChanges.subscribe ((value:any) => {
    })

    this.translationForm = this.fb.group({translations:[]})
    this.translationForm.patchValue({
      translations: this.passTranslations ? this.passTranslations : undefined
    })


    this.imageEditForm.get("linkURL").valueChanges.subscribe( (newurl) => {
      let url;
  
      try {
        url = new URL(newurl);
        if(url.protocol === "http:" || url.protocol === "https:"){
          this.imageEditForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegex)]);
        }
        if(url.protocol === "pass:" ){
          this.imageEditForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegexDoc)]);
        }
      } catch (error) {}
    })

    this.imageEditForm.get("url").valueChanges.subscribe( (newurl) => {
      if(this.addUrl){
        this.url = newurl
        this.supportUrl(newurl)
      }
    })
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
      const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
      // Check for supported file types
      const supportedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      if (!supportedTypes.includes(file.type)) {
        // Unsupported file type
        const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_IMG_TYPE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      // Check file size
      if (file.size > maxAllowedSizeInBytes) {
        // File is too large for both upload and compression
        const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      if (file.size > maxSizeInBytes) {
        // File is between 2 MB and 20 MB, attempt compression
        this.imageCompressionService.compressImage(file, maxSizeInBytes).then(compressedImage => {
          if (!compressedImage) {
            // Compression failed
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          this.newImage = compressedImage;
          this.processFile(compressedImage);
        }).catch(error => {
          // Error during compression
          console.error("Compression error:", error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
        });
      } else {
        // File is less than or equal to 2 MB, no compression needed
        this.newImage = file;
        this.processFile(file);
      }
    }
  }  

    async filesDropped(files: FileHandle[]): Promise<void> {
      this.files = files;
      const fileToUpload = files[0].file;
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
      const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
    
      if (fileToUpload.size > maxAllowedSizeInBytes) {
        // File is too large for both upload and compression
        const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
    
      if (fileToUpload.size > maxSizeInBytes) {
        // File is between 2 MB and 20 MB, attempt compression
        try {
          const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
          if (!compressedImage) {
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          this.newImage = compressedImage;
        } catch (error) {
          console.error("Compression error:", error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
          return;
        }
      } else {
        // File is less than or equal to 2 MB, no compression needed
        this.newImage = fileToUpload;
      }
    
      const newUrl = files[0].url;
    
      if (fileToUpload.type === 'image/jpeg' || fileToUpload.type === 'image/png' || fileToUpload.type == 'image/webp') {
        if (newUrl) {
          try {
            const uploadURL = await this.uploadService.uploadImage(this.passId, this.newImage, this.widgetNumber);
            if (uploadURL) {
              this.url = uploadURL
              this.urlData = newUrl
              this.changes = true
              this.imageEditForm.patchValue({url: this.url})
              this.noUrl = false
            }
          } catch (error) {
            console.error(error);
            const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
            this.commentService.addSnackBar.emit(message);
          }
        }
      }
    }

    private processFile(file: File): void {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        if (e.target) {
          const urlNew = e.target.result as string;
    
          if (urlNew) {
            try {
              const uploadURL = await this.uploadService.uploadImage(this.passId, this.newImage, this.widgetNumber);
    
              if (uploadURL) {
                this.url = uploadURL;
                this.urlData = urlNew;
                this.changes = true;
                this.imageEditForm.patchValue({ url: this.url });
                this.noUrl = false;
              }
            } catch (error) {
              console.error(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        }
      };
    }

    readDocument(event: any): void {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB size limit
        
        // Define allowed file types
        const allowedTypes = [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'image/webp',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/plain',
          'text/csv',
          'text/vcard',
          'application/rtf'
        ];
    
        // Validate file type
        if (!allowedTypes.includes(file.type)) {
          const message = this.translate.instant("SnackBarConstants.INVALID_FILE_FORMAT");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        
        // Validate file size
        if (file.size > maxSizeInBytes) {
          const message = this.translate.instant("SnackBarConstants.FILE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        
        // Proceed with reading and uploading the file
        const reader = new FileReader();
        this.newImage = file;
        reader.readAsDataURL(file);
        reader.onload = async (e) => {
          if (e.target) {
            const urlNew = e.target.result as string;
            try {
              const uploadURL = await this.uploadService.uploadDocument(this.passId, this.newImage, this.widgetNumber);
              if (uploadURL) {
                this.imageEditForm.get("linkURL")?.setValidators([Validators.pattern(this.urlRegexDoc)]);
                this.linkUrlTransform = uploadURL;
                this.imageEditForm.patchValue({ linkURL: this.linkUrlTransform });
              }
            } catch (error) {
              console.error(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        };
      }
    }
    

  deleteImg(x:any){
    this.url=""
    this.imageEditForm.patchValue({
      url:this.url
    })
    this.urlData=""
  }

  editImg(url){
    const data = { 
      linkURL: this.imageEditForm.value.linkURL,
      url: this.url
    }
    let dialogRef= this.dialog.open(MediaDetailsDialogComponent, {
      data: { 
        values: data,
        type: this.imageEditForm.value.type,
        passId: this.passId

      },
    });

    dialogRef.afterClosed().subscribe(result => {
     
      if(result[1]){
        const {fallbackURL , url} = result[0]
        if(url){
          this.url=url
          this.imageEditForm.patchValue({url})
          if(url!=this.imageEditForm.value.url){
            this.urlData =""
          }
          if(this.url ){
            this.supportUrl(this.url)
          }
        }
      }
    });
  }

  async saveChanges(){
    this.commentService.progressSpin.emit(true)      
    const newUrl = this.uploadService.getImagesUrls()

    
    this.commentService.progressSpin.emit(false)
    if(this.url){
      this.commentService.progressSpin.emit(false)

      if(this.imageEditForm.value.linkURL == 'https://')
        this.imageEditForm.patchValue({linkURL : undefined})
        
      this.dialogRef.close([this.imageEditForm.value, true, this.newImage, this.translationForm.value.translations]);

    }else{
      this.noUrl=true
      const message = this.translate.instant("SnackBarConstants.COVER_IMAGE_NEEDED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  onLanguageEditor(){
    let alltranslationsWidget = {}
    let translationsWidget
    
    if(this.translationForm.value.translations){
      translationsWidget = Object.keys(this.translationForm.value.translations) 

    }else{
      translationsWidget = []
    }

    if(this.passLanguagesList){
      translationsWidget=this.passLanguagesList
    }

    if(translationsWidget.length!=0){
      translationsWidget.forEach(lang => {
        if(this.translationForm.value.translations){
          if(this.translationForm.value.translations[lang]){
            let entries = Object.entries(this.translationForm.value.translations[lang]);
            entries.forEach( ent => {
              if(ent[0].includes(this.imageEditForm.value.id)){
                const key = lang+ "." + ent[0]
                alltranslationsWidget[key] = ent[1]
              }
            })
          }
        }
     })
    }
    
    let dialogRef = this.dialog.open(LanguageEditorDialogComponent, {
      data:{
        translations: this.translationForm.value.translations,
        fields: this.imageEditData,
        columns: ['title', 'subtitle', 'url', 'linkURL'],
        values: this.imageEditForm.value,
        type: "image",
        valuesTranslated: alltranslationsWidget,
        languages: translationsWidget,
        passId:this.passId,
        widgetNumber: this.widgetNumber
      },
    });
    let newtranslations = {}

    if(this.translationForm.value.translations){
      newtranslations = this.translationForm.value.translations
    }
    
    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        const data = result[0]
        removeUndefined(data)
        const trans = Object.entries(data)
        trans.forEach( value => {
          const key = value[0]
          const keyName = key.split(".")
          const lang = keyName[0]
          const id = keyName[1]
          const field = keyName[2]
          const tranlationField = id +"."+field

          if(value[1]){
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = value[1]
            }else{
              newtranslations[lang]= {}
              newtranslations[lang][tranlationField] = value[1]
            }
          }else{
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = undefined

              //to delete old value
              if(this.oldTranslations[lang] && this.oldTranslations[lang][tranlationField])
                newtranslations[lang][tranlationField] = ''

            }else{
              newtranslations[lang] = {}
              newtranslations[lang][tranlationField] = undefined
            }
          }
          
        })
        this.translationForm.patchValue({translations: newtranslations})
      }
      
      
    })
  }

  supportUrl(url){
    this.urlData = url
    this.addUrl =false
    if(url){
      try{
        const imgUrl = new URL(url)
        let hostname = environment.urlHostName

        if(imgUrl.protocol=="pass:" ){
          const parts = url.match(/pass:\/\/(.+)\/(.+)/)
          if(parts){
            const passTemplateID = parts[1]
            const path = parts[2]
            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            this.urlData=firebaseUrl
          }else{
            const newparts = url.match(/pass:\/\/(.+)/)
            if(newparts){
              let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
              newparts.forEach( (part,index)=> {
                if(index>0){
                  firebaseUrl += '/' + part 
                }
              })
              this.urlData=firebaseUrl
            }
          }
        }

        if(imgUrl.protocol =="tridy:"){
          const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`
          this.urlData=firebaseUrl
        }

        if(imgUrl.protocol == "context:"){
          const parts = url.match(/context:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
              if(index>0 && index<parts.length){
                  path += "/" + parte
              }
          })
          const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}${path}`
          this.urlData=firebaseUrl
        }
      }catch(error){}

    }
  }

  checkNewUrl(){
    this.url = this.imageEditForm.value.url
    this.supportUrl(this.imageEditForm.value.url)
  }
  
  typeURL(){
    this.addUrl = true
    this.urlData = './../../assets/img/default.jpg';
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }
}
