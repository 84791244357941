<h2 *ngIf="type == 'CdSettings'" mat-dialog-title>{{'resetCdSettings' | translate}}</h2>
<h2 *ngIf="type !== 'CdSettings'" mat-dialog-title>{{'delete' | translate}} {{type}}</h2>
<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <div *ngIf="type == 'CdSettings'" class="p-3 delete-infos">
                <p><strong style="color:var(--warmOrange);" >{{'delete_ask_cd' | translate}}</strong></p> 
             </div>
             <div *ngIf="type != 'CdSettings'" class="p-3 delete-infos">
                <p>{{'delete_msg' | translate}} {{type}}  </p> 
                <p *ngIf="title"><i>{{title}}</i></p>
                <hr>
                <p><strong style="color:var(--warmOrange);" >{{'delete_ask' | translate}}</strong></p> 
             </div>
        </section>
    </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelDelete();">
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="onDelete();">
        <span *ngIf="type == 'CdSettings'">{{'reset' | translate}}</span>
        <span *ngIf="type != 'CdSettings'">{{'delete' | translate}}</span>
    </button>
</mat-dialog-actions>