<section class="addNavbar" >
    <h2>{{'tridys.title' | translate}}</h2>
    <div class="ps-3 d-flex flex-row flex-nowrap align-items-center ">
        <mat-form-field appearance="outline" >
            <mat-label>{{'filter' | translate}}</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
        </mat-form-field>
    </div>
</section>

<div *ngIf="tridysData.length === 0" class="p-2 empty-list" >{{'tridys.empty_title' | translate}}</div>
<div class="shadow-sm mat-elevation-z8 pb-4" id="table-container">
    <table mat-table [dataSource]="dataSource" matSort>
  
      <ng-container matColumnDef="contentName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'contentTypes.contentType' | translate}} </th>
        <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.contentName" class="text-wrap text-break">{{element.contentName}}</span> </td>
      </ng-container>
  
      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'creation_date' | translate}} </th>
        <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.creationDate" class="text-wrap text-break">{{element.creationDate.toDate() | date}}</span> 
        </td>
      </ng-container>
  
      <ng-container matColumnDef="timeStamp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'last_update' | translate}} </th>
        <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.timeStamp" class="text-wrap text-break">{{element.timeStamp.toDate() | date}} </span>        
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break border-bottom" routerLink="{{row.id}}"></tr>
    </table>

    <!-- <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell p-2" colspan="4">{{'no_matching_data' | translate}} "{{input.value}}"</td> -->
  
    <mat-paginator [pageSizeOptions]="paginatorSize"
                   showFirstLastButtons
                   aria-label="Select page of tridys" class="pb-2">
    </mat-paginator>
  </div>
  

