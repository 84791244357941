<section class=" vh-100 d-flex flex-column align-items-center justify-content-center" style="background-color: var(--pureWhite);margin-bottom:20vh;">        
    <div class="card mb-3 mt-3 shadow-lg" id="card" style="width:60vw; height:20vh; border-radius:20px;" >
        <div class="m-auto h-100 d-flex align-items-center">
            <span>this is a test for emailLink</span>
        </div>


    </div>
   
</section>    

