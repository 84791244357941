import { Component } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RegisterSetPasswordDialogComponent } from '../register-set-password-dialog/register-set-password-dialog.component';
import { CommentsService } from '../service/comments.service';
import { getAuth } from '@angular/fire/auth';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-register-set-password',
  templateUrl: './register-set-password.component.html',
  styleUrls: ['./register-set-password.component.css']
})
export class RegisterSetPasswordComponent {
  idToShow:any
  routerUrl:any

  constructor(private settingsService: SettingsService,
    public dialog: MatDialog,
    private router: Router,
    private translate:TranslateService,
    private route:ActivatedRoute,
    private commentsService: CommentsService,
    private authService:AuthService
    ){
      this.routerUrl=this.route.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path   
  }

  ngOnInit():void{
    this.openDialogSetPassword()  
  }
  openDialogSetPassword():void{

    let dialogRef= this.dialog.open(RegisterSetPasswordDialogComponent, {
      data: { 
        values: this.idToShow

      },
    });

    dialogRef.afterClosed().subscribe(async (result:any) => {

      try{
        this.commentsService.progressSpin.emit(true)
        let id = ""

        setTimeout(() => {
        this.router.navigate(['selectAccount'])
        this.commentsService.progressSpin.emit(false)
        }, 1000);
        this.observeData()
      }catch(error){
        console.log(error)
      }
      
    });

  }


  async observeData(){
    const a = await this.settingsService.observeContextsFirestore()
    const cu = await this.settingsService.observeContextsIdLoggedFirestore(this.settingsService.contextId$)
    const usersWithcontextId = this.settingsService.observeUsersWithcontextId(this.settingsService.contextId$)
    const pstpmId = this.settingsService.observeContextsPassDefinitionsFirestoreWithId(this.settingsService.contextId$)
    this.settingsService.observeContextsContentTypesFirestoreWithId(this.settingsService.contextId$)
  }
}
