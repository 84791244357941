<h2 mat-dialog-title>{{'register.signIn_emailLink' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="emailForm" class="m-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'register.email' | translate}}</mat-label>
                    <input formControlName="email" type="text" matInput placeholder="{{'register.email' | translate}}">
                    <mat-hint>{{'register.info' | translate}}</mat-hint>
                </mat-form-field>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelEmailConfirmation();" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="sendEmailConfirmation();" >{{'save' | translate}}</button>
</mat-dialog-actions>
