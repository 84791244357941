<h2 mat-dialog-title>
    <span *ngIf="!empty">{{'reports.editReportsTemplate' | translate}}</span>
    <span *ngIf="empty">{{'newReportsTemplate' | translate}}</span>
    <p class="text-muted" style="font-size:14px;">{{'reports.subtitle' | translate}}</p>
</h2>

<mat-dialog-content>
    <div class="pt-2 ">
        
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section">

            <form [formGroup]="reportForm">
                <!-- <input type="text" class="form-control" formControlName="value"> -->
                <mat-form-field appearance="outline" class="w-100 ">
                    <mat-label>{{'displayName' | translate}}</mat-label>
                    <input matInput type="text" class="form-control" placeholder=" {{'displayName' | translate}}" formControlName="displayName" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100 ">
                    <mat-label>{{'tridys.name' | translate}}</mat-label>
                    <input matInput type="text" class="form-control" placeholder="{{'tridys.name' | translate}}" formControlName="name">
                    <mat-hint>{{'taskTemplates.nameHint' | translate }}</mat-hint>
                </mat-form-field>
        
                <mat-form-field appearance="outline"  class="w-100 mt-3 p-0">
                    <mat-label>{{'contentTypes.contentType' | translate}}</mat-label>
                    <mat-select formControlName="contentTypeId" required>
                        <mat-option value="" > - </mat-option>
                        <mat-option *ngFor="let content of contentTypesList" value="{{content.id}}" >{{content.displayName}}</mat-option>
                    </mat-select>
                </mat-form-field>
        
                <div>
                    <span class="text-muted">{{'reports.columns' | translate}}</span>
                    <div>
                        <span class="custom-hint" style="color:var(--accent);">{{'reports.column_keys' | translate}}</span>
                        <p class="custom-hint">{{'reports.columnsHint' | translate}}</p>
                    </div>
                    <div class="list-group" cdkDropList (cdkDropListDropped)="dropResultFields($event)">
                        <div *ngFor="let field of reportForm.value.resultFields; let index = index;">
                            <mat-card class="mb-1" cdkDrag>
                                <mat-card-content class="py-0">
                                    <div class="d-flex align-items-center" style="justify-content: space-between;">
                                        <div>
                                            <div class=" d-flex flex-column justify-content-between align-items-start mt-1 mb-1 w-100" (click)="editResultField(field, index);">
                                                <span style="cursor: pointer;">{{field.displayName}}</span>
                                                <label class="text-muted custom-label"> {{field.key}}: {{field.type}}</label>
                                            </div>
                                        </div>
                                        <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteResultField(index);">
                                            <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                        </button>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div>
                        <button mat-button (click)="addField()" class="secondary-gray-button mb-3 mt-2" id="btn-add">
                            <mat-icon>add_circle</mat-icon> {{'reports.add_column' | translate}}
                        </button>
    
                        <button mat-button class="secondary-gray-button mb-3 mt-2 ms-3" id="btn-add" [matMenuTriggerFor]="wt_workTask">
                            <mat-icon>add_circle</mat-icon> {{'reports.import_from_workTask' | translate}}
                        </button>

                        <mat-menu #wt_workTask="matMenu">
                            <button *ngFor="let wt of workTaskTemplatesList; let index_wt = index" mat-menu-item (click)="importColumnFromWorkTask(wt, wt['id']);">{{wt.title}}</button>
                        </mat-menu>
                    </div>
                </div>

                <mat-expansion-panel class="mt-3">
                    <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'workTask.advanced_settings' | translate}} (Optional)
                    </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>
                        <span class="text-muted">{{'reports.sorting' | translate}}</span>
                        <p class="custom-hint">Definiere, nach welchem Payload Schlüssel die Daten sortiert werden sollen.</p>
                        <mat-form-field appearance="outline" class="w-100 mt-2">
                            <mat-label>{{'reports.key' | translate}}</mat-label>
                            <input matInput type="text" class="form-control" placeholder=" {{'reports.sorted_by' | translate}}" formControlName="sortedBy">
                        </mat-form-field>
                    </div>
    
                    <div class="mt-3 mb-2">
                        <span class="text-muted">{{'reports.fixedFilters' | translate}}</span>
                        <p class="custom-hint">{{'reports.fixedFiltersHint' | translate}}</p>
                        <div class="list-group" cdkDropList (cdkDropListDropped)="dropFixedFilters($event)">
                            <div *ngFor="let filter of reportForm.value.fixedFilters; let index = index;">
                                <mat-card class="mb-1" cdkDrag>
                                    <mat-card-content class="py-0">
                                        <div class="d-flex align-items-center" style="justify-content: space-between;">
                                            <div>
                                                <div class="d-flex flex-column justify-content-between align-items-start mt-1 mb-1 w-100"  (click)="editFixedFilter(filter, index);" >
                                                    <span style="cursor: pointer;"> {{filter.displayName}} </span>
                                                    <label class="text-muted custom-label">{{filter.key}}  ({{filter.operator}})  
                                                        <span *ngIf="filter.type!='date' && filter.type!='dateTime'">{{filter.value}}</span>
                                                        <span *ngIf="filter.type=='date'">{{isDate(filter.value)}}</span>
                                                        <span *ngIf="filter.type=='dateTime'">{{isDateTime(filter.value)}}</span>
                                                        <!-- <span *ngIf="filter.type=='time'">{{isTime(filter.value)}}</span> -->
                                                    </label>
                                                </div>
                                            </div>
                                            <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteFixedFilter(index);">
                                                <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                            </button>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                        <button mat-button 
                                (click)="addFixedFilter()"
                                class="secondary-gray-button mb-3 mt-2"
                                id="btn-add"
                        >
                            <mat-icon>add_circle</mat-icon> {{'add' | translate}}
                        </button>
                    </div>
                </mat-expansion-panel>



                <!-- <div class="mt-3 mb-2">
                    <span class="text-muted text-uppercase">{{'reports.ui_filters' | translate}}</span>
                    <p class="custom-hint">{{'reports.ui_filterHint' | translate}}</p>
                    <div class="list-group">
                        <div *ngFor="let filter of reportForm.value.filters; let index = index;">
                            <mat-card class="mb-1">
                                <mat-card-content class="py-0">
                                    <div class="d-flex align-items-center" style="justify-content: space-between;">
                                        <div>
                                            <div class="d-flex flex-column justify-content-between align-items-start mt-1 mb-1 w-100" (click)="editFilter(filter, index);" >
                                                <span style="cursor: pointer;"> {{filter.displayName}}</span>
                                                <label class="text-muted custom-label">{{filter.key}}  ({{filter.operator}})</label>
                                            </div>
                                        </div>
                                        <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteFilter(index);">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <button mat-button 
                            (click)="addFilter()"
                            class="secondary-gray-button mb-3 mt-2"
                            id="btn-add"
                    >
                        <mat-icon>add_circle</mat-icon> {{'reports.add_filter' | translate}}
                    </button>
                </div> -->
        
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button"
    (click)="onSave();">{{'save' | translate}}</button>
</mat-dialog-actions>