import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentTypeService } from '../service/content-type.service';
import { PassDefinitionsService } from '../service/pass-definitions.service';
import { CommentsService } from '../service/comments.service';
import { ProductsService } from '../service/products.service';
import { WorkTaskTemplatesService } from '../service/work-task-templates.service';
import { ContentService } from '../service/content.service';
import { TridysService } from '../service/tridys.service';
import { ReportService } from '../service/report.service';
import { AdvancedExperiencesService } from '../service/advanced-experiences.service';
import { FinishedTasksService } from '../service/finished-tasks.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkTaskTemplateGroupsService } from '../service/work-task-template-groups.service';
import { SettingsService } from '../service/settings.service';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.css']
})
export class DeleteConfirmationDialogComponent {
  type:any
  buttonText:any
  title:any
  dataToDelete:any
  message:string

  constructor(public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private fb: FormBuilder,
    private productService: ProductsService,
    private commentService: CommentsService,
    private contentService: ContentService,
    private tridyService: TridysService,
    private reportService: ReportService,
    private advancedExpService: AdvancedExperiencesService,
    private finishedTaskService: FinishedTasksService,
    private worktasktemplateService: WorkTaskTemplatesService,
    private workTaskGroupService : WorkTaskTemplateGroupsService,
    private settingService: SettingsService,
    private translate:TranslateService){
      dialogRef.disableClose = true;

  }

  ngOnInit(){
    this.type = this.data['type']

    this.dataToDelete = this.data.values
    this.title = this.dataToDelete.title
    if(this.type == "Content"){
      this.title = this.dataToDelete.key
    }

  }

  onDelete(){
    if(this.type=="Product"){
      try{
        this.commentService.progressSpin.emit(true)
        this.productService.deleteProduct(this.dataToDelete.id)
        this.commentService.progressSpin.emit(false)
        this.dialogRef.close([true])
        const message = this.translate.instant("SnackBarConstants.DELETE_OK")
        this.commentService.addSnackBar.emit(message)
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.DELETE_FAILED")
        this.commentService.addSnackBar.emit(message)

      }
    }

    if(this.type =="Task Template"){
      try{
        this.commentService.progressSpin.emit(true)
        this.worktasktemplateService.deleteworkTaskTemplate(this.dataToDelete.id)
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.DELETE_OK")
        this.commentService.addSnackBar.emit(message)
        this.dialogRef.close([true])
  
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.DELETE_FAILED")
        this.commentService.addSnackBar.emit(message)
  
      }
    }


    if(this.type =="TaskTemplateGroup"){
      try{
        this.commentService.progressSpin.emit(true)
        const deleted =  this.workTaskGroupService.deleteworkTaskTemplateGroup(this.dataToDelete.id, this.settingService.contextId$)
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.DELETE_OK")
        this.commentService.addSnackBar.emit(message)
        this.dialogRef.close([true])

      }catch(error){
        console.log(error)
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.DELETE_FAILED")
        this.commentService.addSnackBar.emit(message)
        }
    }

    if(this.type == "Content"){
      try{
        this.commentService.progressSpin.emit(true)
        this.contentService.deletecontent(this.dataToDelete.id)
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.DELETE_OK")
        this.commentService.addSnackBar.emit(message)
        this.dialogRef.close([true])
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.DELETE_FAILED")
        this.commentService.addSnackBar.emit(message)
  
      }
    }

    if(this.type == "Tridy"){
      try{
        this.commentService.progressSpin.emit(true)
        this.tridyService.deleteTridy(this.dataToDelete.id)
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.DELETE_OK")
        this.commentService.addSnackBar.emit(message)
        this.dialogRef.close([true])
  
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.DELETE_FAILED")
        this.commentService.addSnackBar.emit(message)
  
      }
    }

    if(this.type == "Report"){
      try{
        this.commentService.progressSpin.emit(true)
        this.reportService.deleteReportTemplate(this.dataToDelete.id)
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.DELETE_OK")
        this.commentService.addSnackBar.emit(message)
        this.dialogRef.close([true])
  
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.DELETE_FAILED")
        this.commentService.addSnackBar.emit(message)
  
      }
    }

    if(this.type == "CdSettings"){
      try{
        this.commentService.progressSpin.emit(true)
        //this.reportService.deleteReportTemplate(this.dataToDelete.id)
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.RESET_OK")
        this.commentService.addSnackBar.emit(message)
        this.dialogRef.close([true])
  
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.RESET_FAILED")
        this.commentService.addSnackBar.emit(message)
  
      }
    }


    if(this.type == "Advanced"){
      // try{
      //   this.commentService.progressSpin.emit(true)
      //   this.advancedExpService.deleteAdvancedExperience(this.dataToDelete.id)
      //   this.commentService.progressSpin.emit(false)
      //   const message = this.translate.instant("SnackBarConstants.DELETE_OK")
      //   this.commentService.addSnackBar.emit(message)
      //   this.dialogRef.close([true])
      // }catch(error){
      // const message = this.translate.instant("SnackBarConstants.DELTET_FAILED")
      //   this.commentService.addSnackBar.emit(message)
      // }
    }

    if(this.type == "Finished"){
      // try{
      //   this.commentService.progressSpin.emit(true)
      //   this.finishedTaskService.deleteFinishedTasks(this.dataToDelete.id)
      //   this.commentService.progressSpin.emit(false)
      //   const message = this.translate.instant("SnackBarConstants.DELETE_OK")
      //   this.commentService.addSnackBar.emit(message)
      //   this.dialogRef.close([true])
      // }catch(error){
      // const message = this.translate.instant("SnackBarConstants.DELTET_FAILED")
      //   this.commentService.addSnackBar.emit(message)
      // }
    }
  }
  cancelDelete(){
    this.dialogRef.close([false])
  }
}

