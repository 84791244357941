import { Component, Inject } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../service/auth.service';
import { CommentsService } from '../service/comments.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User, getAuth, updatePassword, user } from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Component({
  selector: 'app-register-set-password-dialog',
  templateUrl: './register-set-password-dialog.component.html',
  styleUrls: ['./register-set-password-dialog.component.css']
})
export class RegisterSetPasswordDialogComponent {
  hide = true

  passwordFrom!:FormGroup
  
  message={

  }

  constructor(
    public dialogRef: MatDialogRef<RegisterSetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private authService: AuthService,
    private router: Router,
    private functions: Functions,
    private commentService: CommentsService,
    private settingsService: SettingsService,
    private fb: FormBuilder,
    private translate:TranslateService
    ){

      dialogRef.disableClose = true;
    }

    ngOnInit(){

      this.passwordFrom = this.fb.group({
        password:[],
        confirmPassword:[]
      })
    }

    onCancel(){}

    onSubmit(){
      this.commentService.progressSpin.emit(true)
      if(this.passwordFrom.value.password && this.passwordFrom.value.confirmPassword 
        && this.passwordFrom.value.password == this.passwordFrom.value.confirmPassword ){
        const auth = getAuth()

        const currentUser = auth.currentUser
        const uid = auth.currentUser.uid
        const email = currentUser.email
        const password = this.passwordFrom.value.password
        const values = {"email": email, "password": password}
        const finishUserRegistration = httpsCallable(this.functions, 'admin-finishUserRegistration');
  
        finishUserRegistration(values).then((result) => {
              const data = result['data'];
              const sanitizedMessage = data;
              this.commentService.progressSpin.emit(false)
              this.dialogRef.close([true, this.passwordFrom.value, data])
              this.settingsService.getLoggedData()
            })
            .catch((error) => {
              this.commentService.progressSpin.emit(false)
              console.log(error)
              const message = this.translate.instant("SnackBarConstants.CREATE_FAILED")
              this.commentService.addSnackBar.emit(message)
              this.commentService.addSnackBar.emit(error)
            });


      }
    }
}
