<h2 mat-dialog-title>{{'pass.widgets.img' | translate}}</h2>
<!-- <div *ngIf="defaultLanguage" style="position:absolute;top:25px;right:10px;">
    <button mat-icon-button style="color:var(--warmOrange); " (click)="onLanguageEditor();" >
        <span class="material-symbols-outlined">language</span>
    </button>
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div> -->

<mat-dialog-content style="max-width: 600px;">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="imageEditForm">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>

                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.title' | translate}}</mat-label>
                                <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.subtitle' | translate}}</mat-label>
                                <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subtitle' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row">
                    <mat-form-field appearance="outline" class="w-100 mb-2">
                        <mat-label>{{'pass.link-URL' | translate}}</mat-label>
                        <input formControlName="linkURL" type="url" matInput placeholder="https://">
                        <mat-error>
                           {{'url_pattern_invalid' | translate}}  
                        </mat-error>
                        <mat-hint>
                            {{'url_pattern_detailed' | translate}}  
                        </mat-hint>
                    </mat-form-field> 
        
                    <button mat-icon-button matTooltip="{{'pick' | translate}}" (click)="documentFileInput.click()">
                        <span class="material-symbols-outlined">
                            folder_open
                        </span>
                    </button>
                    <input type="file" id="documentFile" name="documentFile" style="display: none;" accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .txt, .csv, .vcf, .rtf, .webp" (change)="readDocument($event)" #documentFileInput>
                </div>
        
                <mat-card class="mb-4">
                    <mat-card-content>
                            <div class="p-2">
                                <div *ngIf="urlData">
                                    <div class="d-flex align-items-center mb-3">
                                        <mat-icon class="material-symbols-outlined" >image</mat-icon>
                                        <span class="px-2">{{'pass.widgets.img' | translate}}</span>
                                    </div>
                                    <div class="d-flex justify-content-stretch flex-column">
<!--                                         <div *ngIf="urlData" class="custom-form-field" >
                                            <img [src]="urlData" height="150" style="width:100%; object-fit: cover; border-radius:5px;" onerror="this.src='./../../assets/img/default.jpg'" > 
                                        </div> -->
                                        <div *ngIf="urlData" class="custom-form-field" >
                                            <img [src]="urlData" style="width:100%; border-radius:5px;" onerror="this.src='./../../assets/img/default.jpg'" > 
                                        </div>
                                        <div *ngIf="!urlData" class="custom-form-field" [ngStyle]="{'background-image': 'url( ./../../assets/img/default.jpg)'}"></div>
                                        <div class="form-fields mt-3">
                                            <div class="d-flex align-items-center">
                                                <mat-form-field appearance="outline" class="flex-grow-1">
                                                    <mat-icon matIconPrefix class="material-symbols-outlined">link</mat-icon>
                                                    <mat-label>{{ 'pass.url' | translate }}</mat-label>
                                                    <input type="text" formControlName="url" matInput (change)="checkNewUrl()"><!---->
                                                </mat-form-field>
                                                <button mat-icon-button class="delete-button" style="margin-top:-20px;" (click)="deleteImg(url)" matTooltip="Delete Image">
                                                    <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!urlData" appDragAndDrop (files)="filesDropped($event)">
                                    <mat-card class="upload-section text-center justify-content-center">
                                        <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
                                            <div class="p-2 mb-4">
                                                <mat-icon class="material-symbols-outlined">add_photo_alternate</mat-icon>
                                                <h2 class="px-3">{{'pass.fileUploadMessage' | translate}}
                                                    <button type="button" class="labelImage" (click)="triggerFileInput()"><strong style="font-family: 'MyFont-Bold', sans-serif;">{{'pass.browse' | translate}}</strong></button>
                                                    {{'pass.or' | translate}}
                                                    <button type="button" class="labelImage" (click)="typeURL()"><strong style="font-family: 'MyFont-Bold', sans-serif;">{{'enterURL' | translate}}</strong></button>
                                                    <input #fileInput type="file" id="imageFile" name="imageFile" style="display: none;" accept=".jpg, .jpeg, .png, .webp" (change)="readURL($event)">
                                                </h2>    
                                            </div>
                                            <p class="py-2 mt-4">{{'pass.supportedFiles' | translate}}: .jpg, .jpeg, .png, .webp</p>
                                        </div>
                                    </mat-card>
                                </div>
                            </div>
                    </mat-card-content>
                </mat-card>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" [mat-dialog-close]="oldData" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="saveChanges();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>
