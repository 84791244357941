
<h2 mat-dialog-title>{{'pass.spacer.title' | translate}}</h2>

<!-- <div *ngIf="defaultLanguage" style=" position: absolute;top: 25px;right: 10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div> -->

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="spacerForm">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>
        
                <div class="d-flex flex-row">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'pass.spacer.spacing' | translate}}</mat-label>
                        <input formControlName="spacing" type="number" matInput placeholder="{{'pass.spacer.spacing' | translate}}" min="10">
                        <span matSuffix class="p-3"> px</span>
                    </mat-form-field> 
        
                </div>
        
            </form>
        </section>
    </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="dialogCancel();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="dialogSave();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>
