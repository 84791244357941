import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { ImageCompressionService } from 'src/app/service/image-compression.service';
import { UploadService } from 'src/app/service/upload.service';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { LanguageEditorDialogComponent } from 'src/app/language-editor-dialog/language-editor-dialog.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { deepCopy, supportUrlStructure, removeUndefined } from '../../../../../backend/utils/object';

@Component({
  selector: 'app-image-translation-edit',
  templateUrl: './image-translation-edit.component.html',
  styleUrls: ['./image-translation-edit.component.css']
})
export class ImageTranslationEditComponent {
  imageEditData:any
  oldData:any
  url:any
  changes=false
  imageTranslateEditForm!:FormGroup
  noUrl=false
  newImage:any
  widgetNumber:any
  passId:any
  files: FileHandle[] = []
  drop=false
  passTranslations:any
  translationForm!:FormGroup
  urlData:any
  linkUrlTransform:any
  passLanguagesList:any
  urlRegex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  oldTranslations:any
  addUrl = false
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  selectedLanguage:string
  widgetId:string

  constructor(public dialogRef: MatDialogRef<ImageTranslationEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private uploadService: UploadService,
    private commentService: CommentsService,
    private fb: FormBuilder,
    private imageCompressionService: ImageCompressionService,
    private translate:TranslateService){
      dialogRef.disableClose=true
  }

  ngOnInit(): void {
    console.log(this.data)
    this.imageEditData=this.data.values
    this.url=this.imageEditData.url
    this.oldData=this.data.values
    this.widgetId = this.data.values.id

    this.widgetNumber= this.data.widget
    this.passId = this.data.passId
    if(this.data.translations){
      this.passTranslations = this.data.translations
      this.oldTranslations = deepCopy(this.data.translations)
    }else{
      this.passTranslations = {}
      this.oldTranslations = {}
    }
    this.selectedLanguage = this.data.selectedLanguage
    this.passLanguagesList = this.data.languages

    if(this.data['url']){
      this.urlData = this.data['url']
    }else{
      if(this.imageEditData.url ){
        this.supportUrlStructure(this.imageEditData.url)
      }
    }

    this.imageTranslateEditForm = this.fb.group({
      id:[{value:undefined, disabled:true}],
      type:[{value:undefined, disabled:true}],
      url:[{value:undefined, disabled:false}, Validators.required],
      linkURL:[{value:undefined, disabled:false},[Validators.pattern(this.urlRegex)]],
      source:[{value:undefined, disabled:true}],
      copyright:[{value:undefined, disabled:true}],
      subtitle:[{value:undefined, disabled:false}],
      title:[{value:undefined, disabled:false}],
      position:[{value:undefined, disabled:true}],
      size:[{value:undefined, disabled:true}],
      name:[{value:undefined, disabled:true}]
    })

    const {id, type, source, copyright, position, size, name } = this.imageEditData
    
    const title = this.data.valuesTranslated[this.selectedLanguage +'.'+id+'.title']
    const subtitle = this.data.valuesTranslated[this.selectedLanguage +'.'+id+'.subtitle']
    const url = this.data.valuesTranslated[this.selectedLanguage +'.'+id+'.url']
    const linkURL = this.data.valuesTranslated[this.selectedLanguage +'.'+id+'.linkURL']

    this.imageTranslateEditForm.patchValue({
      id: id ? id : undefined , 
      type: type ? type : undefined, 
      url: url ? url : undefined, 
      linkURL: linkURL ? linkURL :'https://', 
      source: source ? source : undefined, 
      copyright: copyright ? copyright : undefined , 
      subtitle: subtitle ? subtitle : undefined, 
      title: title ? title : undefined, 
      position: position ? position : undefined, 
      size: size ? size : undefined,
      name: name ? name : undefined
    })

    this.imageTranslateEditForm.valueChanges.subscribe ((value:any) => {
    })

    this.translationForm = this.fb.group({translations:[]})
    this.translationForm.patchValue({
      translations: this.passTranslations ? this.passTranslations : undefined
    })


    this.imageTranslateEditForm.get("linkURL").valueChanges.subscribe( (newurl) => {
      let url;
  
      try {
        url = new URL(newurl);
        if(url.protocol === "http:" || url.protocol === "https:"){
          this.imageTranslateEditForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegex)]);
        }
        if(url.protocol === "pass:" ){
          this.imageTranslateEditForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegexDoc)]);
        }
      } catch (error) {}
    })

    this.imageTranslateEditForm.get("url").valueChanges.subscribe( (newurl) => {
      if(this.addUrl){
        this.url = newurl
        this.supportUrlStructure(newurl)
      }
    })
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
      const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
      // Check for supported file types
      const supportedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      if (!supportedTypes.includes(file.type)) {
        // Unsupported file type
        const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_IMG_TYPE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      // Check file size
      if (file.size > maxAllowedSizeInBytes) {
        // File is too large for both upload and compression
        const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      if (file.size > maxSizeInBytes) {
        // File is between 2 MB and 20 MB, attempt compression
        this.imageCompressionService.compressImage(file, maxSizeInBytes).then(compressedImage => {
          if (!compressedImage) {
            // Compression failed
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          this.newImage = compressedImage;
          this.processFile(compressedImage);
        }).catch(error => {
          // Error during compression
          console.error("Compression error:", error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
        });
      } else {
        // File is less than or equal to 2 MB, no compression needed
        this.newImage = file;
        this.processFile(file);
      }
    }
  }  

    async filesDropped(files: FileHandle[]): Promise<void> {
      this.files = files;
      const fileToUpload = files[0].file;
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
      const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
    
      if (fileToUpload.size > maxAllowedSizeInBytes) {
        // File is too large for both upload and compression
        const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
    
      if (fileToUpload.size > maxSizeInBytes) {
        // File is between 2 MB and 20 MB, attempt compression
        try {
          const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
          if (!compressedImage) {
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          this.newImage = compressedImage;
        } catch (error) {
          console.error("Compression error:", error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
          return;
        }
      } else {
        // File is less than or equal to 2 MB, no compression needed
        this.newImage = fileToUpload;
      }
    
      const newUrl = files[0].url;
    
      if (fileToUpload.type === 'image/jpeg' || fileToUpload.type === 'image/png' || fileToUpload.type == 'image/webp') {
        if (newUrl) {
          try {
            const uploadURL = await this.uploadService.uploadImage(this.passId, this.newImage, this.widgetNumber);
            if (uploadURL) {
              this.url = uploadURL
              this.urlData = newUrl
              this.changes = true
              this.imageTranslateEditForm.patchValue({url: this.url})
              this.noUrl = false
            }
          } catch (error) {
            console.error(error);
            const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
            this.commentService.addSnackBar.emit(message);
          }
        }
      }
    }

    private processFile(file: File): void {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        if (e.target) {
          const urlNew = e.target.result as string;
    
          if (urlNew) {
            try {
              const uploadURL = await this.uploadService.uploadImage(this.passId, this.newImage, this.widgetNumber);
    
              if (uploadURL) {
                this.url = uploadURL;
                this.urlData = urlNew;
                this.changes = true;
                this.imageTranslateEditForm.patchValue({ url: this.url });
                this.noUrl = false;
              }
            } catch (error) {
              console.error(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        }
      };
    }

    readDocument(event: any): void {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB size limit
        
        // Define allowed file types
        const allowedTypes = [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'image/webp',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/plain',
          'text/csv',
          'text/vcard',
          'application/rtf'
        ];
    
        // Validate file type
        if (!allowedTypes.includes(file.type)) {
          const message = this.translate.instant("SnackBarConstants.INVALID_FILE_FORMAT");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        
        // Validate file size
        if (file.size > maxSizeInBytes) {
          const message = this.translate.instant("SnackBarConstants.FILE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        
        // Proceed with reading and uploading the file
        const reader = new FileReader();
        this.newImage = file;
        reader.readAsDataURL(file);
        reader.onload = async (e) => {
          if (e.target) {
            const urlNew = e.target.result as string;
            try {
              const uploadURL = await this.uploadService.uploadDocument(this.passId, this.newImage, this.widgetNumber);
              if (uploadURL) {
                this.imageTranslateEditForm.get("linkURL")?.setValidators([Validators.pattern(this.urlRegexDoc)]);
                this.linkUrlTransform = uploadURL;
                this.imageTranslateEditForm.patchValue({ linkURL: this.linkUrlTransform });
              }
            } catch (error) {
              console.error(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        };
      }
    }
    

  deleteImg(x:any){
    this.url=""
    this.imageTranslateEditForm.patchValue({url:this.url})
    this.urlData=""
  }


  async saveChanges(){
    if(this.url){
      if(this.imageTranslateEditForm.value.linkURL == 'https://')
      this.imageTranslateEditForm.patchValue({linkURL : undefined})
      
      const data = this.getWidgetFormatedData(this.imageTranslateEditForm.value)
      this.commentService.progressSpin.emit(false)

      this.dialogRef.close([data, true]);
    }else{
      this.noUrl=true
      const message = this.translate.instant("SnackBarConstants.COVER_IMAGE_NEEDED")
      this.commentService.addSnackBar.emit(message)
    }
    
  }


  supportUrlStructure(url){
    this.urlData = url
    this.addUrl =false
    let newurl = supportUrlStructure(url, this.passId)
    if(newurl){
      this.urlData = newurl 
    }
  }

  checkNewUrl(){
    this.url = this.imageTranslateEditForm.value.url
    this.supportUrlStructure(this.imageTranslateEditForm.value.url)
  }
  
  typeURL(){
    this.addUrl = true
    this.urlData = './../../assets/img/default.jpg';
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  getWidgetFormatedData(data){
    let {title, subtitle, url, linkURL} = data    
    return {
      [this.selectedLanguage+'.'+this.widgetId+'.title']: title ? title : undefined,
      [this.selectedLanguage+'.'+this.widgetId+'.subtitle']: subtitle ? subtitle : undefined,
      [this.selectedLanguage+'.'+this.widgetId+'.url']: url ? url : undefined,
      [this.selectedLanguage+'.'+this.widgetId+'.linkURL']: linkURL ? linkURL : undefined,
    }
  }
}
