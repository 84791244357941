
<h2 mat-dialog-title>{{'pass.widgets.3dmodel' | translate}}</h2>
<!-- <div *ngIf="defaultLanguage" style=" position: absolute;top: 25px;right: 10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div> -->
<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="model3dEditForm" class="m-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>
                
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Url</mat-label>
                    <input formControlName="url" type="text" matInput placeholder="Url">
                </mat-form-field>
        
                <!-- <div class="form-group m-1">
                    <label for="url" >URL</label>
                    <input matInput formControlName="url" class="form-control" placeholder="" id="title" type="text" value="{{model3dEditForm.value.url}}">
                </div> -->
        
                <div class="insertImgContainer">
                    <label class="labelFile"> 
                        + 
                        <input type="file" id="myfile" name="myfile" style="display: none;" (change)="readURL($event);"> 
                    </label>
                               
                    <canvas #canvasPhone id="canvasPhone" style="margin-left:10px;height: 50%; width: 50%; border-radius:20px;"></canvas>
                    <canvas *ngIf="nweModelToRender" #canvasEdit id="canvasEdit" style="margin-left:10px;height: 50%; width: 50%; border-radius:20px;"></canvas>
                    <button mat-icon-button style="position: relative;  background-color: var(--warmOrange); color:var(--pureWhite);top:-35%; right:10%;" *ngIf="url" (click)="deleteModel()">
                        <span class="material-symbols-outlined">delete</span>
                    </button>
                </div>   
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onSaveEdit();" >{{'apply' | translate}}</button>
</mat-dialog-actions>
