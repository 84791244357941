<h2 mat-dialog-title>{{'pass.widgets.text' | translate}}</h2>

<!-- <div *ngIf="defaultLanguage" style="position:absolute;top:25px;right:10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
    <button mat-icon-button style="color:var(--warmOrange); " (click)="onLanguageEditor();" >
        <span class="material-symbols-outlined">language</span>
    </button>
</div> -->

<mat-dialog-content style="width:650px;">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section">

            <form [formGroup]="textEditForm" class="m-1">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>

                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.title' | translate}}</mat-label>
                                <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.subtitle' | translate}}</mat-label>
                                <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subtitle' | translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <mat-form-field appearance="outline" class="w-100 mb-3">
                    <mat-label>{{'pass.widgets.text' | translate}}</mat-label>
                    <textarea 
                      matInput
                      cdkTextareaAutosize
                      formControlName="text"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5" 
                      class="form-control" 
                      id="text">
                    </textarea>
                    <button 
                      *ngIf="payloadKeys.length > 0" 
                      matSuffix 
                      style="cursor: pointer;"
                      matTooltip="{{'pass.addPayloadKey' | translate}}"
                      mat-icon-button 
                      [matMenuTriggerFor]="menu">
                      <mat-icon>bolt</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <div class="p-3" style="font-size: 14px;color:#717171;">{{'pass.availablePayloadKeys' | translate}}</div>
                          <mat-divider></mat-divider>
                        <button 
                          mat-menu-item 
                          *ngFor="let key of payloadKeys"
                          (click)="addPayloadKeyAtCursor(key)">
                          {{ key }}
                        </button>
                      </mat-menu>
                    <mat-hint>
                      <span>{{'pass.markDown_supports' | translate}} <span style="color:var(--warmOrange);cursor: help;" matTooltip="{{'pass.markDown' | translate}}" matTooltipPosition="below">Markdown</span></span>
                    </mat-hint>
                  </mat-form-field>

                <div class="d-flex flex-row">
                    <div class="pe-2 flex-grow-1">
                        <div class="input-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{'pass.textAlignment' | translate}}</mat-label>
                                <mat-select formControlName="textAlignment" class="input-group">
                                  <mat-option value="right"><mat-icon>format_align_right</mat-icon>{{'pass.right' | translate}}</mat-option>
                                  <mat-option value="left"><mat-icon>format_align_left</mat-icon>{{'pass.left' | translate}}</mat-option>
                                  <mat-option *ngIf="!textEditForm.value.image || !textEditForm.value.imageUrl" value="center"><mat-icon>format_align_center</mat-icon>{{'pass.center' | translate}}</mat-option>
                                  <!-- <mat-option *ngIf="!textEditForm.value.image || !textEditForm.value.image.url" value="center">{{'pass.center' | translate}} </mat-option> -->
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ps-2 flex-grow-1">
                        <div class="input-group">
                            <mat-form-field  appearance="outline" class="w-100">
                                <mat-label>{{'pass.imagePosition' | translate}}</mat-label>
                                <mat-select formControlName="imagePosition" class="input-group">
                                    <mat-option value="left">
                                        <mat-icon class="material-symbols-outlined">format_image_left</mat-icon>
                                        {{'pass.left' | translate}}
                                    </mat-option>
                                    <mat-option value="right">
                                        <mat-icon class="material-symbols-outlined">format_image_right</mat-icon>
                                        {{'pass.right' | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>


                <mat-expansion-panel class="mt-1 mb-4" expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color:#595c5f;">
                            <mat-icon class="material-symbols-outlined">image</mat-icon> <span class="px-2">{{'pass.widgets.img' | translate}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    
                    <div class="px-2 py-3">
                        <div *ngIf="urlData" class="mt-2">
                            <div class="d-flex justify-content-stretch">
                                <div *ngIf="urlData" class="custom-form-field">
                                    <img [src]="urlData"  height="150" style="object-fit: cover; border-radius:10px; width:100%;" onerror="this.src='./../../assets/img/default.jpg'" > 
                                </div>
                                <div *ngIf="!urlData" class="custom-form-field" [ngStyle]="{'background-image': 'url( ./../../assets/img/default.jpg)'}"></div>

                                <div class="form-fields">
                                    <div class="d-flex align-items-center">
                                        <mat-form-field appearance="outline" class="flex-grow-1">
                                            <mat-label>{{ 'pass.url' | translate }}</mat-label>
                                            <input type="text" formControlName="imageUrl" matInput (change)="checkNewUrl()">
                                        </mat-form-field>
                                        <button mat-icon-button class="delete-button" style="margin-top:-20px;" (click)="deleteImg(url)" matTooltip="Delete Image">
                                            <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                        </button>
                                    </div>
                                    <div class="d-flex flex-row">
                                        <mat-form-field appearance="outline" class="w-100 mb-2">
                                            <mat-label>{{'pass.link-URL' | translate}}</mat-label>
                                            <input formControlName="imageLinkURL" type="url" matInput placeholder="https://" >
                                            <mat-error>{{'url_pattern_invalid' | translate}}  </mat-error>
                                            <mat-hint>{{'url_pattern_detailed' | translate}}  </mat-hint>
                                        </mat-form-field> 
                            
                                        <button mat-icon-button matTooltip="{{'pass.pick_doc' | translate}}" (click)="fileInput.click()">
                                            <span class="material-symbols-outlined">folder_open</span>
                                        </button>
                                        <input type="file" id="myfile" name="myfile" style="display: none;"(change)="readDocument($event)" #fileInput>

                                    </div>

                                    <!-- <mat-form-field appearance="outline" class="flex-grow-1">
                                        <mat-label>{{ 'pass.description' | translate }}</mat-label>
                                        <input type="text" formControlName="imageDescription" matInput>
                                      </mat-form-field> -->
                                  </div>
                            </div>
                        </div>
                        <div *ngIf="!urlData" appDragAndDrop (files)="filesDropped($event)">
                            <mat-card class="upload-section text-center justify-content-center">
                                <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
                                    <div class="py-2 mb-4">
                                        <mat-icon class="material-symbols-outlined">add_photo_alternate</mat-icon>
                                        <h2 class="px-4">{{'pass.fileUploadMessage' | translate}}
                                            <button type="button" class="labelImage" (click)="triggerFileInput()"><strong style="font-family: 'MyFont-Bold', sans-serif;">{{'pass.browse' | translate}}</strong></button>
                                            {{'pass.or' | translate}}
                                            <button type="button" class="labelImage" (click)="typeURL()"><strong style="font-family: 'MyFont-Bold', sans-serif;">{{'enterURL' | translate}}</strong></button>
                                            <input #fileInput type="file" id="myfile" name="myfile" style="display: none;" accept=".jpg, .jpeg, .png, .webp" (change)="readURL($event)">
                                        </h2>    
                                    </div>
                                    <p class="py-2 mt-4">{{'pass.supportedFiles' | translate}}: .jpg, .jpeg, .png, .webp</p>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </mat-expansion-panel>
                    
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onSaveEdit();" >{{'apply' | translate}}</button>
</mat-dialog-actions>