<section class="vh-100 d-flex flex-column align-items-center justify-content-center" style="background-color: var(--pureWhite);">
    <div class="container-fluid h-100">
        <div class="row h-100">

            <div class="col-lg-6 d-none d-lg-block h-100 p-0 login-image">
                <div class="orange-overlay"></div>
            </div>

            <div class="col-lg-6 d-flex align-items-center justify-content-between flex-column p-3 p-lg-0">

                <!-- LANGUAGE SWITCHER -->
                <div class="d-flex flex-row align-self-end justify-content-end flex-wrap m-lg-2">
                    <button mat-icon-button [matMenuTriggerFor]="language" style="color:var(--warmOrange);margin-bottom:10px;">
                        <span class="fi fi-{{languageFlag}} menu-image" style="font-size: 19px;"></span>
                        <span class="material-symbols-outlined">
                          arrow_drop_down
                        </span>
                    </button>
                    <mat-menu #language="matMenu">
                        <button mat-menu-item (click)="useLanguage('en')"><span class="fi fi-gb dd-menu-image"></span>  English </button>
                        <button mat-menu-item (click)="useLanguage('de')"><span class="fi fi-de dd-menu-image"></span>  Deutsch </button>
                        <button mat-menu-item (click)="useLanguage('es')"><span class="fi fi-es dd-menu-image"></span>  Español </button>
                    </mat-menu>
                </div>

                <!-- FORM SECTION -->
                <div class="d-flex flex-column align-items-center justify-content-center flex-wrap form-section">
                    <img src="./../../assets/img/narravero-logo_website.svg" id="logo" alt="image" class="mb-5">
                    <form class="" [formGroup]="loginForm" (keydown.enter)="logInAction();">

                        <mat-form-field appearance="outline" class="w-100 mt-2">
                            <mat-label>{{'login.email' | translate }}</mat-label>
                            <input matInput placeholder="{{'login.email' | translate }}" formControlName="email" type="email" id="emailAdress" required>
                            <mat-error *ngIf="loginForm.value.email.invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
        
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'login.pass' | translate}}</mat-label>
                            <input matInput formControlName="password" placeholder="{{'login.pass' | translate}}" id="passwordValue" [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                            </button>
                        </mat-form-field>

                        <div *ngIf="badLogin" style="padding: 10px; background-color: var(--pureWhite); margin-bottom:10px; color:var(--accentGlobal); border-color: var(--accentGlobal);text-align:center; font-weight: bold; border: 1px solid var(--accentGlobal); border-radius:5px;">    
                            {{'login.invalid' | translate}}
                        </div>

                        <div>
                            <button mat-button (click)="logInAction();" class="w-100 mb-2 orange-button">{{'login.title' | translate}}</button>
                            <button mat-button (click)="callForgotPassword();" class="w-100" style="color:var(--warmOrange)">{{ 'login.forgot' | translate}}?</button>
                        </div>
                        
                    </form>
                </div>
                <!-- VERSION NOTE -->
                <div class="p-0 p-lg-4 w-100">
                    <div class="d-flex flex-row justify-content-between flex-wrap">
                        <span class="dev-note mobile-text-small"> {{buildEnvironment}} - {{version}} ( {{buildNumber}} )</span> 
                        <div class="footer-links mobile-text-small">
                            <span ><a class="text-muted text-decoration-none" target="_blank" href="https://www.narravero.com/impressum">{{'imprint' | translate}}</a></span> 
                            <span class="text-muted divider">   |  </span> 
                            <span class="text-muted"> <a class="text-muted text-decoration-none" target="_blank" href ="https://www.narravero.com/datenschutz">{{'privacy_policy' | translate}}</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
